import { Box, Button, Stack, Typography } from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { colors } from 'theme';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga';

export const HistoryRecord: FunctionComponent = () => {
  const { formatMessage: t } = useIntl();
  const recordTypes = [
    {
      id: 'history-record.crisis',
      link: paths.CRISIS_HISTORY,
    },
    {
      id: 'history-record.ketones',
      link: paths.KETONES_HISTORY,
    },
    {
      id: 'history-record.sleep',
      link: paths.SLEEP_HISTORY,
    },
    {
      id: 'history-record.symptoms',
      link: paths.SYMPTOM_HISTORY,
    },
    {
      id: 'history-record.height-weight',
      link: paths.HEIGHT_WEIGHT_HISTORY,
    },
    {
      id: 'history-record.mood',
      link: paths.MOOD_HISTORY,
    },
  ];

  const navigate = useNavigate();

  const navigateHome = useCallback(() => {
    navigate(paths.HOME_MENU);
  }, [navigate]);

  return (
    <Box
      style={{
        display: 'flex',
        height: '95%',
        flexDirection: 'column',
      }}
    >
      <Typography
        align="center"
        fontSize="22px"
        fontFamily="Poppins Medium"
        style={{ marginBottom: '2vh', color: colors.darkPurple }}
      >
        <FormattedMessage id="history-record.title" />
      </Typography>
      <Box p={4}>
        <Stack spacing={2}>
          {recordTypes.map(recordType => (
            <Button
              sx={{ height: 48, color: colors.white, backgroundColor: colors.darkPurple }}
              key={recordType.id}
              variant="contained"
              component={Link}
              to={recordType.link}
              onClick={() => {
                ReactGA.event({
                  category: 'ButtonClick',
                  action: `View ${t({ id: recordType.id })} History`,
                });
              }}
            >
              <FormattedMessage id={recordType.id} />
            </Button>
          ))}
        </Stack>
      </Box>
      <Box>
        <Typography
          align="center"
          style={{
            fontFamily: 'Poppins Regular',
            textDecoration: 'underline',
            marginBottom: '2vh',
            cursor: 'pointer',
          }}
          onClick={navigateHome}
        >
          <FormattedMessage id="confirm-patient.back-to-home" />
        </Typography>
      </Box>
    </Box>
  );
};
