import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, IconButton, Slider, TextField, Typography } from '@mui/material';
import { useSleep } from 'const/sleepTypes';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useSelector from 'redux/useSelector';
import client from 'services/networking/client';
import { Sleep } from 'types/types';
import CancelIcon from '@mui/icons-material/Cancel';
import { colors } from 'theme';

interface IProps {
  sleepData: Sleep;
  closeEdit: () => void;
}

export const EditSleepForm: FunctionComponent<IProps> = ({ closeEdit, sleepData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      sleepQuality: sleepData.quality,
      date: sleepData.date,
      comments: sleepData.comments,
    },
  });

  const intl = useIntl();

  const userId = useSelector(state => state.user.id);

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await client.patch(`/sleep/${sleepData.id}`, {
          quality: data.sleepQuality,
          comments: data.comments,
          userId,
          date: data.date,
        });
        sleepData.comments = data.comments;
        sleepData.date = data.date;
        sleepData.quality = data.sleepQuality;
        closeEdit();
      } catch (error) {
        console.log('Error saving crisis: ', error);
      }
    },
    [closeEdit, sleepData, userId],
  );

  const onDateChange = useCallback(
    (newdate: Date | null) => {
      newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
    },
    [setValue],
  );

  const sleepMap = useSleep();

  const onSliderChange = useCallback(
    (e: any, selectedValue: any) => {
      typeof selectedValue === 'number' &&
        setValue('sleepQuality', sleepMap[selectedValue].dbName, {
          shouldValidate: true,
          shouldDirty: true,
        });
    },
    [setValue, sleepMap],
  );

  const sliderMarks = [
    { value: 0, label: sleepMap[0].localizedName, dbName: 'bad' },
    { value: 1, label: sleepMap[1].localizedName, dbName: 'normal' },
    { value: 2, label: sleepMap[2].localizedName, dbName: 'good' },
  ];

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <IconButton onClick={closeEdit}>
                  <CancelIcon />
                </IconButton>
              </Box>
              <Box>
                <FormattedMessage id="record-sleep.title">
                  {(text: string) => (
                    <Typography
                      align="center"
                      fontSize="22px"
                      fontFamily="Poppins Medium"
                      style={{ marginBottom: '2vh' }}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={onDateChange}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box style={{ width: '80vw' }}>
                <Slider
                  min={0}
                  max={2}
                  marks={sliderMarks}
                  defaultValue={
                    sliderMarks.find(element => element.dbName === sleepData.quality)?.value
                  }
                  onChange={onSliderChange}
                ></Slider>
              </Box>
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('comments', { required: false })}
                hiddenLabel
                id="outlined-multiline-static"
                multiline
                rows={6}
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-sleep.add-comments' })}
              />
            </Box>
          </Box>
          <FormattedMessage id="record-sleep.save-sleep">
            {(text: string) => (
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '45px',
                  width: '100%',
                  color: colors.white,
                  backgroundColor: colors.darkPurple,
                }}
                type="submit"
              >
                {text}
              </Button>
            )}
          </FormattedMessage>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
