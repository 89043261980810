import { useIntl } from 'react-intl';
export function useSleep() {
  const intl = useIntl();
  return [
    {
      dbName: 'bad',
      localizedName: intl.formatMessage({ id: 'sleep-ratings.bad' }),
    },
    {
      dbName: 'normal',
      localizedName: intl.formatMessage({ id: 'sleep-ratings.normal' }),
    },

    {
      dbName: 'good',
      localizedName: intl.formatMessage({ id: 'sleep-ratings.good' }),
    },
  ];
}
