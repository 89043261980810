export enum MealTypes {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  MERIENDA = 'merienda',
  DINNER = 'dinner',
  SNACK = 'snack',
  ALL = 'all',
}

export interface MealType {
  id: string;
  value: string;
  type: string;
}

export const mealTypes = [
  {
    id: 'diet.breakfast',
    value: 'desayuno', // TODO: Confirm this will be the value from endpoint
    type: MealTypes.BREAKFAST,
  },
  {
    id: 'diet.lunch',
    value: 'almuerzo', // TODO: Confirm this will be the value from endpoint
    type: MealTypes.LUNCH,
  },
  {
    id: 'diet.merienda',
    value: 'merienda', // TODO: Confirm this will be the value from endpoint
    type: MealTypes.MERIENDA,
  },
  {
    id: 'diet.dinner',
    value: 'cena', // TODO: Confirm this will be the value from endpoint
    type: MealTypes.DINNER,
  },
  {
    id: 'diet.snack',
    value: 'snack',
    type: MealTypes.SNACK,
  },
];
