import { Box, Button, Stack, Typography } from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { colors, fontSize } from 'theme';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga';

export const AddRecord: FunctionComponent = () => {
  const { formatMessage: t } = useIntl();
  const recordTypes = [
    { id: 'add-record.crisis', link: paths.RECORD_CRISIS },
    { id: 'add-record.ketones', link: paths.RECORD_KETONES },
    { id: 'add-record.sleep', link: paths.RECORD_SLEEP },
    { id: 'add-record.symptoms', link: paths.RECORD_SYMPTOMS },
    { id: 'add-record.height-weight', link: paths.RECORD_HEIGHT_WEIGHT },
    { id: 'add-record.mood', link: paths.RECORD_MOOD },
  ];
  const navigate = useNavigate();

  const navigateHome = useCallback(() => {
    navigate(paths.HOME_MENU);
  }, [navigate]);

  return (
    <Box
      style={{
        display: 'flex',
        height: '95%',
        flexDirection: 'column',
      }}
    >
      <Typography
        align="center"
        fontSize={fontSize.large}
        fontFamily="Poppins Medium"
        style={{ marginBottom: '2vh', color: colors.darkPurple }}
      >
        <FormattedMessage id="add-record.title" />
      </Typography>
      <Box p={4}>
        <Stack spacing={2}>
          {recordTypes.map(recordType => (
            <Button
              sx={{ height: 48, color: colors.white, backgroundColor: colors.darkPurple }}
              key={recordType.id}
              variant="contained"
              component={Link}
              to={recordType.link}
              onClick={() => {
                ReactGA.event({
                  category: 'ButtonClick',
                  action: `Add ${t({ id: recordType.id })} Record`,
                });
              }}
            >
              <FormattedMessage id={recordType.id} />
            </Button>
          ))}
        </Stack>
      </Box>
      <Box>
        <FormattedMessage id="confirm-patient.back-to-home">
          {(text: string) => (
            <Typography
              align="center"
              style={{
                fontFamily: 'Poppins Regular',
                textDecoration: 'underline',
                marginBottom: '2vh',
              }}
              onClick={navigateHome}
            >
              {text}
            </Typography>
          )}
        </FormattedMessage>
      </Box>
    </Box>
  );
};
