import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, IconButton, TextField } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { localeMap } from 'const/calendar';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentDate, updateCurrentDate } from 'redux/Recipe';
import { useDispatch } from 'react-redux';
import { colors } from 'theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
const CalendarHeader: FunctionComponent = () => {
  const intl = useIntl();
  const locale = intl.formatMessage({ id: 'locale.abbreviation' });
  const date = useSelector(getCurrentDate);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateHome = useCallback(() => {
    navigate(paths.HOME_MENU);
  }, [navigate]);
  return (
    <Box display="flex" justifyContent="start" flexDirection="row">
      <Box style={{ flex: 1, color: colors.darkPurple }}>
        <IconButton onClick={navigateHome}>
          <ArrowBackIcon style={{ color: colors.darkPurple }} />
        </IconButton>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
          >
            <MobileDatePicker
              label={<FormattedMessage id="data.date" />}
              inputFormat="MMM dd, yyyy"
              value={date}
              onChange={newDate => {
                dispatch(updateCurrentDate(newDate ?? new Date(Date.now())));
              }}
              renderInput={params => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box style={{ flex: 1 }}></Box>
    </Box>
  );
};

export default CalendarHeader;
