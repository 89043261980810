import React, { FunctionComponent } from 'react';
import SquareIcon from '@mui/icons-material/Square';
import { Radio, RadioProps } from '@mui/material';

export const ketoColors = {
  noneSelected: '#ffffff',
  negative: '#fcf7d9',
  trace: '#fcf0d6',
  small: '#feddd6',
  moderate: '#f8c2e6',
  large: '#f391da',
  largePlus: '#b85f7d',
};

interface IProps extends RadioProps {
  colorLevel: keyof typeof ketoColors;
  radio: boolean;
}

export const KetonesIcon: FunctionComponent<IProps> = props => {
  return props.radio ? (
    <Radio
      icon={<SquareIcon sx={{ color: ketoColors[props.colorLevel] }} />}
      checkedIcon={
        <SquareIcon
          sx={{ color: ketoColors[props.colorLevel], border: '1px solid', borderRadius: '5px' }}
        />
      }
      {...props}
    />
  ) : (
    <SquareIcon sx={{ color: ketoColors[props.colorLevel], border: '1px solid gray' }} />
  );
};
