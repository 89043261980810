import { FetchQueryOptions, useMutation, useQueryClient } from 'react-query';
import { Ketone } from 'types/types';

export const getKetonesForPatient = async (patientId: string): Promise<Ketone[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/ketones/user/${patientId}`).then(async response =>
    response.json(),
  );
};

export const getKetonesForPatientQuery = (patientId: string): FetchQueryOptions<Ketone[]> => ({
  queryKey: ['ketones', patientId],
  queryFn: async () => await getKetonesForPatient(patientId),
});

export const getKetonesForPatientByDate = async (
  patientId: string,
  startDate: Date,
  endDate: Date,
): Promise<Ketone[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/ketones/by-date`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: patientId, startDate, endDate }),
  }).then(async response => response.json());
};

export const getKetonesForPatientByDateQuery = (
  patientId: string,
  startDate: Date,
  endDate: Date,
): FetchQueryOptions<Ketone[]> => ({
  queryKey: ['ketonesByDate', patientId, startDate, endDate],
  queryFn: async () => await getKetonesForPatientByDate(patientId, startDate, endDate),
});

export const DeleteKetoneByIdMutation = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    (ketonesId: string) => {
      const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
      return fetch(`${backendBaseUrl}/ketones/${ketonesId}`, {
        method: 'DELETE',
      });
    },
    {
      onMutate: async (ketonesId: string) => {
        await queryClient.cancelQueries('ketones');
        const previousKetones = queryClient.getQueryData<Ketone[]>('ketones');
        if (previousKetones) {
          const newKetones = previousKetones.filter(ketone => ketone.id !== ketonesId);
          queryClient.setQueryData<Ketone[]>('ketones', { ...newKetones });
        }
        return { previousKetones };
      },
      onSettled: () => {
        queryClient.invalidateQueries('ketones');
      },
    },
  );
  return deleteMutation;
};
