import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { FormattedMessage as T } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';

export const UnregisteredUser: FunctionComponent = () => (
  <Box display="flex" height="90%" justifyContent="space-between" flexDirection="column" p={2}>
    <Box>
      <Box style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row' }}>
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box style={{ paddingTop: '5vh' }}>
        <Typography
          align="center"
          fontSize="22px"
          fontFamily="Poppins Medium"
          style={{ marginBottom: '2vh' }}
        >
          <T id="unregistered-user.title" />
        </Typography>
      </Box>

      <Typography align="center" fontSize="18px" fontFamily="Poppins Regular">
        <T id="unregistered-user.message" />
      </Typography>
    </Box>
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography
        style={{
          fontFamily: 'Poppins Regular',
          textDecoration: 'underline',
          marginBottom: '2vh',
        }}
        component={Link}
        to={paths.PATIENT_SUPPORT_PROGRAM}
      >
        <T id="unregistered-user.need-contact" />
      </Typography>

      <Button
        variant="contained"
        color="primary"
        style={{ height: '45px', width: '100%' }}
        component={Link}
        to={paths.HOME_MENU}
      >
        <T id="unregistered-user.go-home" />
      </Button>
    </Box>
  </Box>
);
