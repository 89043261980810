import { FetchQueryOptions, QueryClient } from 'react-query';
import client from 'services/networking/client';
import { KetoCalculatorRecipe, KetoCalculatorRecipeReference, Macros, Recipe } from 'types/types';

export const getRecipesForPatientQuery = (
  qc: QueryClient,
  patientId: number,
  token: string,
): FetchQueryOptions<Recipe[]> => ({
  queryKey: ['recipes', patientId],
  queryFn: async () => {
    const patientRecipesResponse = await getRecipeForPatient(patientId, token);
    const recipes = await Promise.all(
      patientRecipesResponse.map((recipeResponse: KetoCalculatorRecipe) => {
        return qc.fetchQuery(getRecipeQuery(qc, recipeResponse.id, token));
      }),
    );
    console.log(JSON.stringify(recipes));
    return recipes;
  },
});

const getRecipeForPatient = async (
  patientId: number,
  token: string,
): Promise<KetoCalculatorRecipe[]> => {
  const kcPatientRecipesEndpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/getRecipeForPatient/${patientId}/${token}`;
  return fetch(kcPatientRecipesEndpoint, {
    headers: { Authorization: `Bearer ${token}` },
  }).then(async response => {
    const responseJSON = await response.json();
    return responseJSON.recetas;
  });
};

export const getRecipeQuery = (
  qc: QueryClient,
  recipeId: string,
  token: string,
): FetchQueryOptions<Recipe> => ({
  queryKey: ['recipes', recipeId],
  queryFn: async () => {
    const recipeResponse = await getRecipe(parseInt(recipeId), token);
    const recipe = recipeBackToFront(recipeResponse);
    return recipe;
  },
});

const getRecipe = (recipeId: number, token: string): Promise<KetoCalculatorRecipe> => {
  const kcPatientRecipesEndpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/getRecipe/${recipeId}/${token}`;
  return fetch(kcPatientRecipesEndpoint, {
    headers: { Authorization: `Bearer ${token}` },
  }).then(async response => {
    const responseJSON = await response.json();
    return responseJSON.data;
  });
};

const recipeBackToFront = async (ketoCalculatorRecipe: KetoCalculatorRecipe): Promise<Recipe> => {
  const ingredients = ketoCalculatorRecipe.ingredientes.map(ketoCalculatorIngredient => ({
    calories: ketoCalculatorIngredient.calorias,
    carbs: ketoCalculatorIngredient.hdc,
    fats: ketoCalculatorIngredient.grasas,
    global: ketoCalculatorIngredient.data.global,
    grams: ketoCalculatorIngredient.gramos,
    id: String(ketoCalculatorIngredient.data.id),
    protein: ketoCalculatorIngredient.proteinas,
    ratio: ketoCalculatorIngredient.data.ratio,
    title: ketoCalculatorIngredient.data.title,
  }));
  const mealType =
    ketoCalculatorRecipe.tipo_comida.length > 0 ? ketoCalculatorRecipe.tipo_comida[0].slug : '';
  return {
    recipeId: ketoCalculatorRecipe.id,
    carbs: parseInt(ketoCalculatorRecipe.hdc),
    fats: parseInt(ketoCalculatorRecipe.grasas),
    favorited: false,
    ingredients,
    tipo_comida: ketoCalculatorRecipe.tipo_comida,
    instructions: ketoCalculatorRecipe.instrucciones,
    mealType,
    photo: ketoCalculatorRecipe.foto ?? { url: '/temporary/test-food.jpg' },
    protein: parseInt(ketoCalculatorRecipe.proteinas),
    title: ketoCalculatorRecipe.title,
  };
};

export const getRecipesForDateQuery = (
  patientId: string,
  date: string,
): FetchQueryOptions<KetoCalculatorRecipeReference[]> => ({
  queryKey: ['recipesForDate', patientId, date],
  queryFn: async () => {
    return await client.get('/recipes', {
      patientId,
      date,
    });
  },
});

export const calculateTotalMacros = (recipes: Recipe[]): Macros => {
  let [fats, carbs, protein] = Array(3).fill(0);
  recipes.forEach(recipe => {
    fats += recipe.fats;
    carbs += recipe.carbs;
    protein += recipe.protein;
  });
  return { fats, carbs, protein };
};
