import { Box, Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { fontSize, colors } from 'theme';

export const MoodLogged: FunctionComponent = () => {
  return (
    <Box
      style={{
        display: 'flex',
        height: '90%',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Box style={{ flex: 1 }}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '5vh' }}>
          <Typography
            align="center"
            fontSize={fontSize.large}
            fontFamily="Poppins Medium"
            style={{ marginBottom: '2vh', color: colors.darkPurple }}
          >
            <FormattedMessage id="mood-logged.title" />
          </Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        style={{
          height: '45px',
          width: '100%',
          margin: '1vh 0',
          color: colors.white,
          backgroundColor: colors.darkPurple,
        }}
        component={Link}
        to={paths.RECORD_MOOD}
      >
        <FormattedMessage id="mood-logged.another" />
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          height: '45px',
          width: '100%',
          margin: '1vh 0',
          color: colors.white,
          backgroundColor: colors.darkPurple,
        }}
        component={Link}
        to={paths.RECORD}
      >
        <FormattedMessage id="record-logged.different" />
      </Button>
      <Box style={{ flex: 1 }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '45px',
            width: '100%',
            margin: '1vh 0',
            color: colors.white,
            backgroundColor: colors.darkPurple,
          }}
          component={Link}
          to={paths.MOOD_HISTORY}
        >
          <FormattedMessage id="mood-logged.history" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '45px',
            width: '100%',
            margin: '1vh 0',
            color: colors.white,
            backgroundColor: colors.darkPurple,
          }}
          component={Link}
          to={paths.HOME_MENU}
        >
          <FormattedMessage id="mood-logged.menu" />
        </Button>
      </Box>
      <Box style={{ flex: 1 }}></Box>
    </Box>
  );
};
