import { Box, Button } from '@mui/material';
import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { HomeMenuCard } from 'components/HomeMenuCard/HomeMenuCard';
import { FormattedMessage, useIntl } from 'react-intl';
import { colors, fontSize } from 'theme';

export const HomeMenu: FunctionComponent = () => {
  const { formatMessage: t } = useIntl();

  const homeMenuCards = [
    {
      id: 0,
      image: 'home-menu-assets/1HomeMenuMyDiet.jpg',
      link: paths.DIET,
      text: t({ id: 'menu.my-diet' }),
    },
    {
      id: 1,
      image: 'home-menu-assets/2HomeMenuAddRecord.jpg',
      link: paths.RECORD,
      text: t({ id: 'menu.add-record' }),
    },
    {
      id: 2,
      image: 'home-menu-assets/3HomeMenuRecordHistory.jpg',
      link: paths.HISTORY,
      text: t({ id: 'menu.record-history' }),
    },
    {
      id: 3,
      image: 'home-menu-assets/4HomeMenuMyData.jpg',
      link: paths.DATA,
      text: t({ id: 'menu.my-data' }),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          margin: '0 auto',
        }}
      >
        {homeMenuCards.map(card => (
          <HomeMenuCard key={card.id} image={card.image} link={card.link} text={card.text} />
        ))}
        <Button
          variant="contained"
          style={{
            marginBottom: '12px',
            padding: 3,
            width: '100%',
            color: colors.white,
            backgroundColor: colors.mediumPurple,
            fontSize: fontSize.small,
            textAlign: 'center',
          }}
          component={Link}
          to={paths.PATIENT_SUPPORT_PROGRAM}
        >
          <FormattedMessage id="patient-support-program.title" />
        </Button>
        <a
          href="https://www.terapiaparaepilepsia.com.ar/ketoapp/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="contained"
            style={{
              padding: 3,
              width: '100%',
              color: colors.white,
              backgroundColor: colors.mediumPurple,
              fontSize: fontSize.small,
              textAlign: 'center',
            }}
          >
            <FormattedMessage id="menu.news" />
          </Button>
        </a>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 6,
        }}
      ></Box>
    </Box>
  );
};
