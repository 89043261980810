import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { MealTypes, mealTypes } from 'const/mealTypes';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCurrentMealType } from 'redux/Recipe';
import { paths } from 'routes';
import { colors } from 'theme';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga';

interface IProps {
  mealTypeId: string;
}

export const AddMealCard: FunctionComponent<IProps> = ({ mealTypeId }) => {
  const { formatMessage: t } = useIntl();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    ReactGA.event({
      category: 'ButtonClick',
      action: `Add Meal: ${t({ id: mealTypeId })}`,
    });
    navigate(paths.RECIPE_SELECTION, { state: { mealTypeId } });
    dispatch(
      updateCurrentMealType(
        mealTypes.find(mealType => mealType.id === mealTypeId)?.type ?? MealTypes.ALL,
      ),
    );
    navigate(paths.RECIPE_SELECTION, { state: { mealTypeId } });
  }, [dispatch, mealTypeId, navigate, t]);

  return (
    <Button fullWidth style={{ border: '1px solid gray' }} onClick={onClick}>
      <AddIcon />
      <Typography
        style={{ textTransform: 'none', padding: '0 2vw', color: colors.darkPurple }}
        fontFamily="Poppins Light"
      >
        <FormattedMessage id="diet.add-meal" />
      </Typography>
    </Button>
  );
};
