import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Mood } from 'types/types';
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory';
import { colors } from 'theme';
import { Box, Typography } from '@mui/material';

interface IProps {
  moodList: Mood[];
  numberOfDays: number;
}

export const MoodOverTime: FunctionComponent<IProps> = ({ moodList, numberOfDays }) => {
  const intl = useIntl();
  console.log('moodList' + JSON.stringify(moodList));
  const MoodWords = [
    intl.formatMessage({ id: 'data.mood.terrible' }),
    intl.formatMessage({ id: 'data.mood.bad' }),
    intl.formatMessage({ id: 'data.mood.okay' }),
    intl.formatMessage({ id: 'data.mood.good' }),
    intl.formatMessage({ id: 'data.mood.great' }),
  ];

  const getDateTickFormat = (date: string, index: number, length: number) => {
    return new Date(date).toLocaleDateString('es-AR').slice(0, -5);
    if (length > 5) {
      return !(index % Math.round(numberOfDays / 10))
        ? new Date(date).toLocaleDateString().slice(0, -5)
        : '';
    } else {
      return new Date(date).toLocaleDateString().slice(0, -5);
    }
  };

  const sortedMoodList = moodList.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  return (
    <Box className="graph-height-width">
      {sortedMoodList.length > 0 && (
        <Box id="box-mood-time">
          <Typography>
            <FormattedMessage id="data.mood.title" />
          </Typography>
          <VictoryChart domainPadding={7} height={341} width={574}>
            <VictoryLine
              data={sortedMoodList}
              key="mood"
              x="date"
              y={'mood'}
              style={{ labels: { padding: 40 } }}
            />
            <VictoryAxis
              key="mood"
              dependentAxis
              style={{
                axisLabel: { padding: -30 },
                grid: { stroke: colors.mediumGrey, strokeDasharray: '2' },
                tickLabels: { fontSize: 10, angle: -50 },
              }}
              tickFormat={x =>
                x <= MoodWords.length ? MoodWords[x - 1] : MoodWords[MoodWords.length - 1]
              }
            />
            <VictoryAxis
              style={{
                tickLabels: { fontSize: 10 },
                ticks: { stroke: 'grey', size: 5 },
                axisLabel: { padding: 0 },
                parent: { overflow: 'visible' },
              }}
              tickFormat={(x, i, ticks) => getDateTickFormat(x, i, ticks.length)}
            />
          </VictoryChart>
        </Box>
      )}
    </Box>
  );
};
