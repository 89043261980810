import { Box, CircularProgress, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from 'theme';

interface IProps {
  current: number;
  target: number;
  abbreviation: string;
}

export const NutrientRatio: FunctionComponent<IProps> = ({ current, target, abbreviation }) => {
  return (
    <Box mx={1} position="relative">
      <CircularProgress
        variant="determinate"
        value={current / target > 1 ? 100 : (current / target) * 100}
        size={72}
        sx={{ color: current / target > 1 ? colors.mediumRed : colors.mediumPurple }}
      />
      <Box
        top={4}
        left={4}
        position="absolute"
        display="flex"
        height={64}
        width={64}
        bgcolor={current / target > 1 ? colors.lightRed : colors.lightPurple}
        alignItems="center"
        justifyContent="center"
        borderRadius={64 / 2}
        flexDirection="column"
      >
        <Typography align="center" fontFamily="Poppins Regular" fontSize="12px">
          <FormattedMessage id={abbreviation} />
        </Typography>
        <Typography align="center" fontFamily="Poppins Medium" fontSize="12px">
          {current} / {target}
        </Typography>
        <Typography align="center" fontFamily="Poppins Regular" fontSize="12px">
          gr.
        </Typography>
      </Box>
    </Box>
  );
};
