import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, TextField, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import client from 'services/networking/client';
import { fontSize, colors } from 'theme';
import ReactGA from 'react-ga';

export const RecordSymptoms: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: { date: new Date(Date.now()), comments: '', symptomDescription: '' },
  });

  const navigate = useNavigate();
  const intl = useIntl();

  const userId = useSelector(state => state.user.id);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: any) => {
      const { symptomDescription, comments, date } = data;
      setButtonDisabled(true);

      ReactGA.event({
        category: 'ButtonClick',
        action: 'Record Symptoms',
      });

      try {
        await client.post('/symptoms', {
          symptomDescription: symptomDescription,
          comments: comments,
          userId,
          date: date.toJSON(),
        });
        navigate(paths.LOGGED_SYMPTOMS);
      } catch (error) {
        console.log('Error saving symptoms: ', error);
        setButtonDisabled(false);
      }
    },
    [navigate, userId],
  );

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',

          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <BackArrow />
              </Box>
              <Box>
                <Typography
                  align="center"
                  fontSize={fontSize.large}
                  fontFamily="Poppins Medium"
                  style={{ marginBottom: '2vh', color: colors.darkPurple }}
                >
                  <FormattedMessage id="record-symptoms.title" />
                </Typography>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <FormattedMessage id="record-symptoms.describe-symptoms">
              {(placeholder: string) => (
                <Box style={{ margin: '1vh 0' }}>
                  <TextField
                    {...register('symptomDescription', { required: true })}
                    hiddenLabel
                    fullWidth
                    placeholder={placeholder}
                  />
                </Box>
              )}
            </FormattedMessage>
            <FormattedMessage id="record-symptoms.comments">
              {(placeholder: string) => (
                <Box style={{ margin: '1vh 0' }}>
                  <TextField
                    {...register('comments', { required: true })}
                    hiddenLabel
                    id="outlined-multiline-static"
                    multiline
                    rows={6}
                    fullWidth
                    placeholder={placeholder}
                  />
                </Box>
              )}
            </FormattedMessage>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
            disabled={buttonDisabled}
          >
            <FormattedMessage id="record-symptoms.save-symptoms" />
          </Button>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
