import styled from 'styled-components';
import { getSpacing } from 'stylesheet';

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 ${getSpacing(26)};
`;
RootContainer.displayName = 'RootContainer';

export const PageContent = styled.main`
  height: 100%;
  max-width: 100%;
  width: 500px;
  margin: 0 auto;
`;
PageContent.displayName = 'PageContent';
