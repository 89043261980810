import { createTheme, Theme } from '@mui/material';

export const colors = {
  darkPurple: '#3F126B',
  mediumPurple: '#9A59DC',
  darkLightPurple: '#C285FF',
  lightPurple: '#EBD4FD',
  white: '#FFFFFF',
  darkGrey: '#5e5e5e',
  mediumGrey: '#959595',
  darkLightGrey: '#A9A9A9',
  lightGrey: '#e0e0e0',
  mediumRed: '#fc5f67',
  lightRed: '#ffb2b3',
};

export const fontSize = {
  large: '22px',
  medium: '18px',
  small: '14px',
  XSmall: '12px',
  XXSmall: '10px',
};

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#242424',
    },
  },
  typography: {
    fontFamily: 'Poppins Regular',
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderWidth: '0px',
          padding: '8px',
          backgroundColor: 'transparent',
          margin: '0px',
          '&selected': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: colors.darkPurple,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.darkPurple,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: colors.darkPurple,
        },
      },
    },
  },
});
