/* eslint-disable max-lines */
import CancelIcon from '@mui/icons-material/Cancel';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { MoodData, moodIcons } from 'pages/RecordMood';
import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useSelector from 'redux/useSelector';
import client from 'services/networking/client';
import { colors, fontSize } from 'theme';
import { Mood } from 'types/types';

interface IProps {
  moodData: Mood;
  closeEdit: () => void;
}

export const EditMoodForm: FunctionComponent<IProps> = ({ closeEdit, moodData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      date: moodData.date,
      mood: moodData.mood,
      comments: moodData.comments,
    },
  });

  const { formatMessage: t } = useIntl();

  const userId = useSelector(state => state.user.id);

  const onSubmitMood = useCallback(
    index => {
      setValue('mood', index + 1, { shouldValidate: true, shouldDirty: true });
    },
    [setValue],
  );

  const onSubmit = useCallback(
    async (data: MoodData) => {
      try {
        await client.patch(`/mood/${moodData.id}`, {
          mood: data.mood,
          comments: data.comments,
          userId,
          date: data.date,
        });
        moodData.comments = data.comments;
        moodData.date = data.date;
        moodData.mood = data.mood;
        closeEdit();
      } catch (error) {
        console.log('Error saving crisis: ', error);
      }
    },
    [closeEdit, moodData, userId],
  );

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = t({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ margin: 1 }}>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton onClick={closeEdit}>
                <CancelIcon />
              </IconButton>
              <Box>
                <Typography
                  align="center"
                  fontSize={fontSize.large}
                  fontFamily="Poppins Medium"
                  style={{ marginBottom: '2vh', color: colors.darkPurple }}
                >
                  <FormattedMessage id="record-mood.title" />
                </Typography>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '1vh 2px',
                }}
              >
                <RadioGroup
                  aria-label="Record mood buttons"
                  defaultValue=""
                  name="mood"
                  row
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {moodIcons.map((moodIcon, index) => (
                    <Radio
                      key={`mood-${index + 1}`} // eslint-disable-line react/no-array-index-key
                      value={index + 1}
                      checked={getValues().mood === index + 1}
                      required={true}
                      onClick={() => onSubmitMood(index)}
                      icon={
                        <SvgIcon
                          component={moodIcon}
                          title={`mood-${index}`}
                          viewBox="0 0 477.14 885"
                          aria-label={`mood-${index + 1}`}
                          style={{
                            height: '100%',
                          }}
                        />
                      }
                      checkedIcon={
                        <SvgIcon
                          component={moodIcon}
                          title={`mood-${index}`}
                          viewBox="0 0 477.14 885"
                          aria-label={`mood-${index + 1}`}
                          style={{
                            height: '100%',
                            filter: `drop-shadow(2px 0 2px ${colors.lightGrey}) drop-shadow(-2px 0 2px ${colors.lightGrey}) drop-shadow(0 2px 2px ${colors.lightGrey}) drop-shadow(0 -2px 2px ${colors.lightGrey}) `,
                          }}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('comments', { required: false })}
                hiddenLabel
                id="outlined-multiline-static"
                multiline
                rows={6}
                fullWidth
                placeholder={t({ id: 'record-mood.add-comments' })}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              style={{
                height: '45px',
                width: '100%',
                color: colors.white,
                backgroundColor: colors.darkPurple,
              }}
              type="submit"
            >
              <FormattedMessage id="record-mood.save-mood" />
            </Button>
          </Box>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
