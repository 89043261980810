/* eslint-disable max-lines */
import { Box, Button, Typography } from '@mui/material';
import { AddMealModal } from 'components/AddMealModal/AddMealModal';
import { BackArrow } from 'components/BackArrow';
import Loader from 'components/Loader';
import { getRecipeQuery } from 'dataLayer/recipes';
import DOMPurify from 'dompurify';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getKetoCalcToken } from 'redux/Login';
import { colors, fontSize } from 'theme';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga';

export const RecipeDetails: FunctionComponent = () => {
  const { formatMessage: t } = useIntl();
  const ketoCalcToken = useSelector(getKetoCalcToken);
  const { recipeId } = useParams();
  const qc = useQueryClient();
  const { data: recipe, isLoading } = useQuery(getRecipeQuery(qc, recipeId ?? '', ketoCalcToken));
  const [isOpen, setIsOpen] = useState(false);

  const [appWidth, setAppWidth] = useState(468);

  const recipeDetailsPageWrapper = useRef<HTMLDivElement>(null);

  const getAppWidth = () => {
    if (recipeDetailsPageWrapper.current)
      setAppWidth(recipeDetailsPageWrapper?.current?.offsetWidth);
  };

  useEffect(() => {
    getAppWidth();
    window.addEventListener('resize', getAppWidth);
  }, []);

  const nutritionButton = {
    backgroundColor: colors.darkLightPurple,
    borderRadius: '25px',
    display: 'table',
    padding: '5px 10px',
    margin: '5px 5px 5px 0px',
  };

  const subheadingBar = {
    backgroundColor: colors.lightPurple,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '2px 5px',
  };

  const subheadingBarText = {
    fontFamily: 'Poppins Regular',
    fontSize: fontSize.XSmall,
    color: colors.darkPurple,
    letterSpacing: '.px',
  };

  if (!isLoading && recipe !== undefined) {
    const imageUrl =
      recipe.photo.sizes !== undefined ? recipe.photo.sizes.medium : recipe.photo.url;
    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ mx: '10px', mb: 8 }}
          ref={recipeDetailsPageWrapper}
        >
          <Box style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
            <BackArrow />
          </Box>
          <Box
            component="img"
            src={imageUrl}
            sx={{
              borderRadius: '5px',
              height: '20vh',
              width: '100%',
              maxWidth: '550px',
              objectFit: 'cover',
              overflow: 'hidden',
              display: 'flex',
              alignSelf: 'center',
              resize: 'horizontal',
              mx: '5px',
            }}
          ></Box>
          <Box sx={{ margin: 1 }}>
            <Box sx={{ margin: '10px 0px' }}>
              <Box display="flex" justifyContent="space-between" flexDirection="row">
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {recipe.prepTime && (
                    <Typography
                      sx={{ fontFamily: 'Poppins Light', color: colors.darkLightGrey }}
                      fontSize={fontSize.small}
                    >
                      {`${t({ id: 'recipe.prepTime' })}.`}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Typography
                sx={{
                  color: colors.darkPurple,
                  fontFamily: 'Poppins Medium',
                  fontSize: fontSize.medium,
                  mb: 1,
                }}
              >
                {recipe.title}
              </Typography>
              <Box display="flex" flexDirection="row" flexWrap="wrap" width="100%">
                <Box style={nutritionButton}>
                  <Typography fontFamily="Poppins Medium" fontSize="14px" color="white">
                    <FormattedMessage id="meal-details.fat" />: {recipe.fats}g
                  </Typography>
                </Box>
                <Box style={nutritionButton}>
                  <Typography fontFamily="Poppins Medium" fontSize="14px" color="white">
                    <FormattedMessage id="meal-details.carbs" />: {recipe.carbs}g
                  </Typography>
                </Box>
                <Box style={nutritionButton}>
                  <Typography fontFamily="Poppins Medium" fontSize="14px" color="white">
                    <FormattedMessage id="meal-details.protein" />: {recipe.protein}g
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={subheadingBar}>
              <Typography sx={subheadingBarText}>
                <FormattedMessage id="meal-details.ingredients">
                  {(text: string) => <>{text.toUpperCase()}</>}
                </FormattedMessage>
              </Typography>
            </Box>
            <Box sx={{ margin: '10px 10px 20px 10px' }}>
              {recipe.ingredients?.map(ingredient => (
                <Typography
                  fontFamily="Poppins Regular"
                  fontSize={fontSize.XSmall}
                  key={ingredient.id}
                >
                  - {ingredient.title}: {parseInt(ingredient.grams ?? '', 10)}g
                </Typography>
              ))}
            </Box>
            <Box style={subheadingBar}>
              <Typography style={subheadingBarText}>
                <FormattedMessage id="meal-details.steps">
                  {(text: string) => <>{text.toUpperCase()}</>}
                </FormattedMessage>
              </Typography>
            </Box>
            <Box sx={{ mx: 1, mt: 1, mb: 6 }}>
              <Typography
                fontFamily="Poppins Regular"
                fontSize={fontSize.XSmall}
                // disabling TS warning because we are sanitizing with DOMPurify
                // eslint-disable-next-line risxss/catch-potential-xss-react
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(recipe.instructions ?? '') }}
              />
            </Box>
            <Box
              position="fixed"
              height={48}
              bottom="0px"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                className="btnrecipe"
                onClick={() => {
                  ReactGA.event({
                    category: 'ButtonClick',
                    action: `Add Recipe: ${t({ id: recipe.title })}`,
                  });
                  setIsOpen(!isOpen);
                }}
                style={{
                  color: colors.white,
                  backgroundColor: colors.darkPurple,
                }}
              >
                <FormattedMessage id="meal-details.add-recipe" />
              </Button>
            </Box>
          </Box>
        </Box>
        <AddMealModal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} />
      </>
    );
  }
  return <Loader />;
};
