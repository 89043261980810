import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { Box, Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { FormattedMessage as T } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';

export const ResetPasswordConfirmation: FunctionComponent = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="90%" p={2}>
      <Box>
        <Box style={{ marginTop: '5vh' }}>
          <Typography
            align="center"
            fontSize="22px"
            fontFamily="Poppins Medium"
            style={{ marginBottom: '2vh' }}
          >
            <T id="reset-password-confirmation.reset" />
          </Typography>
        </Box>

        <Typography align="center" fontSize="18px" fontFamily="Poppins Regular">
          <T id="reset-password-confirmation.message" />
        </Typography>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <MarkEmailReadOutlinedIcon sx={{ fontSize: '50px' }} />
      </Box>

      <Button
        variant="contained"
        color="primary"
        style={{ height: '45px', width: '100%' }}
        component={Link}
        to={paths.HOME_MENU}
      >
        <T id="reset-password-confirmation.go-home" />
      </Button>
    </Box>
  );
};
