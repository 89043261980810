/* eslint-disable max-lines */
import CancelIcon from '@mui/icons-material/Cancel';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { localeMap } from 'const/calendar';
import { useCrises } from 'const/crisisTypes';
import React, { FunctionComponent, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useSelector from 'redux/useSelector';
import client from 'services/networking/client';
import { colors } from 'theme';
import { Crisis } from 'types/types';

interface IProps {
  crisisData: Crisis;
  closeEdit: () => void;
}

export const EditCrisisForm: FunctionComponent<IProps> = ({ crisisData, closeEdit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      seizureType: crisisData.type,
      date: crisisData.date,
      hasRescueMedication: crisisData.hasRescueMedication,
      comments: crisisData.comments,
      crisisNumber: crisisData.crisisNumber,
      drugsQuantity: crisisData.drugsQuantity,
    },
  });

  const userId = useSelector(state => state.user.id);
  const intl = useIntl();

  const onSubmit = useCallback(
    async (data: any) => {
      const {
        seizureType,
        comments,
        date,
        hasRescueMedication,
        drugsQuantity,
        crisisNumber,
      } = data;
      try {
        await client
          .patch(`/crisis/${crisisData.id}`, {
            type: seizureType,
            comments: comments,
            userId,
            hasRescueMedication: Boolean(hasRescueMedication),
            date,
            drugsQuantity,
            crisisNumber,
          })
          .catch(error => console.log('Error saving crisis: ', error));
        crisisData.type = seizureType;
        crisisData.comments = comments;
        crisisData.date = date;
        crisisData.crisisNumber = crisisNumber;
        crisisData.drugsQuantity = drugsQuantity;
        crisisData.hasRescueMedication = hasRescueMedication;
        closeEdit();
      } catch (error) {
        console.log('Error saving crisis: ', error);
      }
    },
    [closeEdit, crisisData, userId],
  );

  const crisisTypes = useCrises();

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <IconButton onClick={closeEdit}>
                  <CancelIcon />
                </IconButton>
              </Box>
              <Box>
                <FormattedMessage id="record-crisis.title">
                  {(text: string) => (
                    <Typography
                      align="center"
                      fontSize="22px"
                      fontFamily="Poppins Medium"
                      style={{ marginBottom: '2vh' }}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <Box>
                <InputLabel id="label">
                  {intl.formatMessage({ id: 'record-crisis.crisis-placeholder' })}
                </InputLabel>
                <Select
                  onChange={event => {
                    setValue('seizureType', event.target.value as string, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  style={{ width: '100%' }}
                  labelId="label"
                  value={getValues().seizureType}
                >
                  {crisisTypes.map(crisisType => (
                    <MenuItem key={crisisType.dbName} value={crisisType.dbName}>
                      {crisisType.localizedName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <InputLabel id="label">
              {intl.formatMessage({ id: 'record-crisis.crisis-number' })}
            </InputLabel>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('crisisNumber', {
                  required: true,
                  pattern: {
                    value: /^(0|[1-9][0-9]*)$/,
                    message: intl.formatMessage({ id: 'record-crisis.error-number' }),
                  },
                })}
                hiddenLabel
                id="outlined-multiline-static"
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-crisis.crisis-number' })}
              />
            </Box>
            <InputLabel id="label">
              {intl.formatMessage({ id: 'record-crisis.drugs-amount' })}
            </InputLabel>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('drugsQuantity', {
                  pattern: {
                    value: /^(0|[1-9][0-9]*)$/,
                    message: intl.formatMessage({ id: 'record-crisis.error-number' }),
                  },
                })}
                id="standard-number"
                hiddenLabel
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-crisis.drugs-amount' })}
              />
            </Box>
            <FormControlLabel
              control={
                <Controller
                  name="hasRescueMedication"
                  control={control}
                  render={() => <Checkbox checked={getValues().hasRescueMedication} />}
                />
              }
              onClick={() => {
                setValue('hasRescueMedication', !getValues().hasRescueMedication);
              }}
              label={
                <Typography style={{ fontFamily: 'Poppins Regular' }}>
                  <FormattedMessage id="record-crisis.has-rescue-medication" />
                </Typography>
              }
              style={{ fontFamily: 'Poppins Light' }}
            />

            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('comments', { required: false })}
                hiddenLabel
                id="outlined-multiline-static"
                multiline
                rows={6}
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-crisis.comments' })}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
          >
            {intl.formatMessage({ id: 'record-crisis.save-crisis' })}
          </Button>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
