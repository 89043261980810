import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
/* Language polyfills needed for IE11, Edge, Safari 12 & 13 support
https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
*/
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import { ThemeProvider } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { flattenMessages } from 'services/i18n/intl';
import { theme } from 'theme';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';
import spMessages from 'translations/sp.json';
/* End of language polyfills */
import { PageContent } from './Root.style';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
  sp: flattenMessages(spMessages),
};

interface Props {
  children: ReactNode;
}

const Root: React.FunctionComponent<Props> = ({ children }) => {
  const queryClient = new QueryClient();

  const location = useLocation();
  const [gaInitialized, setGaInitialized] = useState(false);
  const localLanguage = navigator.language;
  const locale = localLanguage.startsWith('sp') ? locales.en : locales.sp;

  useEffect(() => {
    if (!gaInitialized) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!);
      setGaInitialized(true);
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [gaInitialized, location]);

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={localLanguage} messages={locale}>
        <ThemeProvider theme={theme}>
          <PageContent>{children}</PageContent>
        </ThemeProvider>
      </IntlProvider>
    </QueryClientProvider>
  );
};

export default Root;
