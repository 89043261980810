import { Box, Typography } from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from 'theme';
import { Crisis } from 'types/types';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

interface IProps {
  crisisList: Crisis[];
  numberOfDays: number;
  startDate: Date;
  endDate: Date;
}
export const CrisisOverTime: FunctionComponent<IProps> = ({
  crisisList,
  numberOfDays,
  startDate,
  endDate,
}) => {
  const convertCrisisDataForGraph = useCallback((data: Crisis[]) => {
    const summedValues: { [date: string]: number } = {};
    data.forEach(datum => {
      const d = new Date(datum.date);
      const mm = d.getMonth() + 1;
      const dd = d.getDate();
      const yy = d.getFullYear();
      const dateForDatum = mm + '/' + dd + '/' + yy;
      if (summedValues[dateForDatum] === undefined) {
        summedValues[dateForDatum] = datum.crisisNumber;
      } else {
        summedValues[dateForDatum] += datum.crisisNumber;
      }
    });
    const simplifiedData = [];
    for (const key in summedValues) {
      simplifiedData.push({ date: new Date(key), crisisNumber: summedValues[key] });
    }
    return simplifiedData;
  }, []);

  const getCrisisMaxima = useCallback((data: { date: Date; crisisNumber: number }[]) => {
    return Math.max(
      ...data.map(crisis => {
        return crisis.crisisNumber;
      }),
    );
  }, []);

  return (
    <Box>
      {crisisList.length > 0 && (
        <Box>
          <Typography>
            <FormattedMessage id="data.crisis.time" />
          </Typography>
          <VictoryChart domainPadding={10}>
            <VictoryBar
              data={convertCrisisDataForGraph(crisisList)}
              x="date"
              y="crisisNumber"
              domain={{ x: [startDate, endDate] }}
              standalone={false}
              style={{ data: { fill: colors.mediumPurple } }}
              barRatio={0.2}
            />
            <VictoryBar
              data={convertCrisisDataForGraph(
                crisisList.filter(crisis => crisis.type === 'status'),
              )}
              x="date"
              y="crisisNumber"
              domain={{ x: [startDate, endDate] }}
              standalone={false}
              style={{ data: { fill: colors.mediumRed } }}
              barRatio={0.2}
            />
            <VictoryAxis
              label="Date"
              tickFormat={(x, i) =>
                !(i % Math.round(numberOfDays / 10))
                  ? new Date(x).toLocaleDateString('es-AR').slice(0, -5)
                  : ''
              }
              tickCount={numberOfDays}
              style={{
                tickLabels: { fontSize: '10' },
                ticks: { stroke: 'grey', size: 5 },
                axisLabel: { padding: 60 },
              }}
              width={20}
            />
            <VictoryAxis
              dependentAxis
              crossAxis={false}
              domain={[0, getCrisisMaxima(convertCrisisDataForGraph(crisisList)) + 45]}
              style={{
                grid: { stroke: colors.mediumGrey, strokeDasharray: '2' },
              }}
              tickFormat={t => Math.ceil(t)}
              height={400}
            />
          </VictoryChart>
        </Box>
      )}
    </Box>
  );
};
