import { FetchQueryOptions } from 'react-query';
import { UserState } from 'redux/User';
import { Macros, PatientInfoResponse, User } from 'types/types';

export const convertUserToState = (cognitoUser: any, databaseUser: User): UserState => {
  return {
    id: databaseUser.id,
    firstName: cognitoUser.attributes.given_name,
    lastName: cognitoUser.attributes.family_name,
    email: cognitoUser.attributes.email,
    patientId: cognitoUser.username,
    cognitoId: cognitoUser.attributes.sub,
    dateOfBirth: databaseUser.dateOfBirth.toString(),
    userType: databaseUser.userType,
    roles: databaseUser.roles,
    groups: databaseUser.groups,
    dietType: databaseUser.dietType,
    newDietNotifications: databaseUser.newDietNotifications,
    symptomFollowUpNotifications: databaseUser.symptomFollowUpNotifications,
    newsNotifications: databaseUser.newsNotifications,
    profilePicture: databaseUser.profilePicture,
  };
};

export const getUserDataByCognitoId = (cognitoId: string): Promise<User> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/users/cognito/${cognitoId}`).then(async response =>
    response.json(),
  );
};

export const getPatientMacroGoalsQuery = (
  patientId: number,
  token: string,
): FetchQueryOptions<Macros> => ({
  queryKey: ['macroGoals', patientId],
  queryFn: async () => {
    const patientInfoResponse = await getPatientInfo(patientId, token);
    const patientMacroGoals = patientMacroGoalsB2F(patientInfoResponse);
    return patientMacroGoals;
  },
});

const getPatientInfo = (patientId: number, token: string) => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/auth/getPatientInfo/${patientId}/${token}`).then(
    async response => {
      const responseJSON = await response.json();
      return responseJSON.data;
    },
  );
};

const patientMacroGoalsB2F = (patientInfoResponse: PatientInfoResponse) => ({
  carbs: parseInt(patientInfoResponse.requerimientos.requerimientos_hdc),
  fats: parseInt(patientInfoResponse.requerimientos.requerimientos_grasas),
  protein: parseInt(patientInfoResponse.requerimientos.requerimientos_proteinas),
});
