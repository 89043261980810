import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, MenuItem, Modal, Stack, TextField, Typography } from '@mui/material';
import { DailyMacros } from 'components/DailyMacros';
import { localeMap } from 'const/calendar';
import { mealTypes } from 'const/mealTypes';
import { getRecipeQuery } from 'dataLayer/recipes';
import { getPatientMacroGoalsQuery } from 'dataLayer/user';
import { format } from 'date-fns';
import { useFetchRecipesForDate } from 'pages/Diet/hooks';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getKetoCalcToken } from 'redux/Login';
import { getCurrentDate, getCurrentMealType } from 'redux/Recipe';
import { getPatientId } from 'redux/User/selectors';
import { paths } from 'routes';
import client from 'services/networking/client';
import { colors, fontSize } from 'theme';

interface AddMealModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface AddMealFormData {
  date: Date;
  mealType: string;
}

export const AddMealModal: FunctionComponent<AddMealModalProps> = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  const navigate = useNavigate();

  const { recipeId } = useParams();
  const patientId = useSelector(getPatientId);
  const ketoCalcToken = useSelector(getKetoCalcToken);

  const qc = useQueryClient();
  const { data: recipe, isLoading: isRecipeLoading } = useQuery(
    getRecipeQuery(qc, recipeId ?? '', ketoCalcToken),
  );
  const { data: macroGoals, isLoading: areMacroGoalsLoading } = useQuery(
    getPatientMacroGoalsQuery(
      // TODO: change patientId to actual patientId from user
      10,
      ketoCalcToken,
    ),
  );
  const { currentMacros } = useFetchRecipesForDate();
  const [areMacrosOverLimit, setAreMacrosOverLimit] = useState(false);

  useEffect(() => {
    if (recipe && macroGoals && !isRecipeLoading && !areMacroGoalsLoading) {
      if (
        currentMacros.fats + recipe.fats > macroGoals.fats ||
        currentMacros.carbs + recipe.carbs > macroGoals.carbs ||
        currentMacros.protein + recipe.protein > macroGoals.protein
      ) {
        setAreMacrosOverLimit(true);
      }
    }
  }, [
    areMacroGoalsLoading,
    areMacrosOverLimit,
    currentMacros,
    isRecipeLoading,
    macroGoals,
    recipe,
  ]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<AddMealFormData>({
    defaultValues: {
      date: useSelector(getCurrentDate),
      mealType: useSelector(getCurrentMealType),
    },
  });

  const onSubmit = useCallback(
    async (data: AddMealFormData) => {
      try {
        await client.post('/recipes', {
          date: format(data.date, 'yyyy-MM-dd'),
          mealType: data.mealType,
          patientId,
          recipeId,
        });
        navigate(paths.DIET);
      } catch (error) {
        console.log('Error saving crisis: ', error);
      }
    },
    [navigate, patientId, recipeId],
  );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: '65%',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} pb={2}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Controller
              control={control}
              name="mealType"
              rules={{ required: true }}
              render={({ field: { value } }) => (
                <TextField
                  value={value}
                  onChange={e => setValue('mealType', e.target.value)}
                  select
                  label={<FormattedMessage id="add-to-meal.meal-type" />}
                  error={Boolean(errors.mealType)}
                  helperText={
                    Boolean(errors.mealType) && (
                      <FormattedMessage id="add-to-meal.error.meal-type-required" />
                    )
                  }
                >
                  {mealTypes.map(mealType => (
                    <MenuItem key={mealType.id} value={mealType.type}>
                      <FormattedMessage id={mealType.id} />
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <DailyMacros
              additionalFats={recipe?.fats}
              additionalCarbs={recipe?.carbs}
              additionalProtein={recipe?.protein}
            />
          </Stack>
          <Stack spacing={1}>
            {areMacrosOverLimit && (
              <Box display="flex" justifyContent={'center'}>
                <Typography fontFamily={'Poppins Light'} fontSize={fontSize.XSmall} color={'red'}>
                  <FormattedMessage id="add-to-meal.macros-over-limit" />
                </Typography>
              </Box>
            )}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ backgroundColor: colors.darkPurple }}
            >
              <Typography>
                <FormattedMessage
                  id={areMacrosOverLimit ? 'add-to-meal.confirm-anyway' : 'add-to-meal.confirm'}
                />
              </Typography>
            </Button>
            <Button variant="outlined" onClick={onClose} fullWidth>
              <Typography>
                <FormattedMessage id="add-to-meal.cancel" />
              </Typography>
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};
