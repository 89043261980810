import React, { FunctionComponent, useCallback, useState } from 'react';
import { Crisis } from 'types/types';
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory';
import { colors } from 'theme';
import { useCrises } from 'const/crisisTypes';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface IProps {
  crisisList: Crisis[];
}
export const CrisisTypes: FunctionComponent<IProps> = ({ crisisList }) => {
  const crisisTypes = useCrises();
  const getTotalsForCrisisTypes = useCallback(
    (data: Crisis[]) => {
      const crisisTypeTotals = crisisTypes.map(crisis => {
        return { dbName: crisis.dbName, amount: 0, displayName: crisis.localizedName };
      });
      data.forEach(crisis => {
        const matchingCrisisTypeTotal = crisisTypeTotals.find(type => type.dbName === crisis.type);
        if (matchingCrisisTypeTotal) {
          matchingCrisisTypeTotal.amount += crisis.crisisNumber;
        }
      });
      return crisisTypeTotals;
    },
    [crisisTypes],
  );

  const getCrisisMaxima = useCallback((data: Crisis[]) => {
    const crisisTypeTotals = crisisTypes.map(crisis => {
      return { dbName: crisis.dbName, amount: 0, displayName: crisis.localizedName };
    });
    data.forEach(crisis => {
      const matchingCrisisTypeTotal = crisisTypeTotals.find(type => type.dbName === crisis.type);
      if (matchingCrisisTypeTotal) {
        matchingCrisisTypeTotal.amount += crisis.crisisNumber;
      }
    });
    const ddd = crisisTypeTotals;
    return Math.max(
      ...crisisTypeTotals.map(crisis => {
        return crisis.amount;
      }),
    );
  }, []);

  return (
    <Box>
      {crisisList.length > 0 && (
        <Box>
          <Typography>
            <FormattedMessage id="data.crisis.types" />
          </Typography>
          <VictoryChart domainPadding={7} height={341} width={574}>
            <VictoryBar
              data={getTotalsForCrisisTypes(crisisList)}
              x="displayName"
              y="amount"
              style={{ data: { fill: colors.mediumPurple }, labels: { padding: 40 } }}
              domain={{ y: [1, getCrisisMaxima(crisisList) + 45] }}
            />
            <VictoryAxis
              dependentAxis
              style={{
                axisLabel: { padding: 40 },
                grid: { stroke: colors.mediumGrey, strokeDasharray: '2' },
              }}
            />

            <VictoryAxis
              style={{
                tickLabels: { fontSize: 11 },
                ticks: { stroke: 'grey', size: 5 },
                axisLabel: { padding: 60 },
                parent: { overflow: 'visible' },
              }}
              tickFormat={crisisTypes.map(type => type.localizedName)}
            />
          </VictoryChart>
        </Box>
      )}
    </Box>
  );
};
