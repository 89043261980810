import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Card, CardActionArea, CardMedia, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { colors, fontSize } from 'theme';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga';

interface HomeMenuProps {
  image: string;
  link: string;
  text: string;
}

export const HomeMenuCard: FunctionComponent<HomeMenuProps> = ({ image, link, text }) => {
  const { formatMessage: t } = useIntl();
  const cardHeight = 'calc(18vh - 12px)';
  return (
    <Box
      style={{
        margin: '0 auto 12px auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Card
        style={{
          margin: '0 auto',
          width: '100%',
        }}
      >
        <CardActionArea
          component={Link}
          to={link}
          onClick={() => {
            ReactGA.event({
              category: 'ButtonClick',
              action: `${t({ id: text })} from Homepage`,
            });
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              maxHeight: `${cardHeight}`,
              top: '0px',
              zIndex: 0,
            }}
            component="img"
            image={image}
            alt={text}
            loading="lazy"
          />

          <Box
            style={{
              width: 'calc(100% - 30px)',
              backgroundColor: colors.mediumPurple,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
              padding: '6px 15px',
              position: 'absolute',
              bottom: '0px',
              zIndex: 1,
              fontSize: fontSize.XXSmall,
            }}
          >
            <Typography
              align="center"
              fontSize="15px"
              fontFamily="Poppins Medium"
              style={{
                color: colors.white,
              }}
            >
              {text}
            </Typography>
            <ArrowForwardIcon style={{ color: colors.white }} />
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
};
