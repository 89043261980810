import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateSettingsAction } from 'types/types';
import { DietTypes, UserState, UserTypes } from './types';

const initialState: UserState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  patientId: '',
  cognitoId: '',
  dateOfBirth: '',
  userType: UserTypes.PATIENT,
  roles: [],
  dietType: DietTypes.CLASSIC,
  newDietNotifications: true,
  symptomFollowUpNotifications: true,
  newsNotifications: true,
  profilePicture: '',
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userConnect: (state, action: PayloadAction<UserState>) => {
      return { ...action.payload };
    },
    userChangedSettings: (state, action: PayloadAction<UpdateSettingsAction>) => {
      return { ...state, ...action.payload };
    },
    userDisconnect: () => initialState,
  },
});

export const { userConnect, userDisconnect, userChangedSettings } = UserSlice.actions;
export default UserSlice.reducer;
