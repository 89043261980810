import { useIntl } from 'react-intl';
export function useCrises() {
  const intl = useIntl();
  return [
    {
      dbName: 'myoclonic',
      localizedName: intl.formatMessage({ id: 'crisis-types.myoclonic' }),
    },
    {
      dbName: 'atonic',
      localizedName: intl.formatMessage({ id: 'crisis-types.atonic' }),
    },

    {
      dbName: 'tonicClonic',
      localizedName: intl.formatMessage({ id: 'crisis-types.tonicClonic' }),
    },

    {
      dbName: 'absence',
      localizedName: intl.formatMessage({ id: 'crisis-types.absence' }),
    },

    {
      dbName: 'status',
      localizedName: intl.formatMessage({ id: 'crisis-types.status' }),
    },

    {
      dbName: 'spasm',
      localizedName: intl.formatMessage({ id: 'crisis-types.spasm' }),
    },

    {
      dbName: 'tonic',
      localizedName: intl.formatMessage({ id: 'crisis-types.tonic' }),
    },
    {
      dbName: 'focal',
      localizedName: intl.formatMessage({ id: 'crisis-types.focal' }),
    },
  ];
}
