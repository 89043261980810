/* eslint-disable max-lines */
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { localeMap } from 'const/calendar';
import { useCrises } from 'const/crisisTypes';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import client from 'services/networking/client';
import { fontSize, colors } from 'theme';
import ReactGA from 'react-ga';

export const RecordCrisis: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      seizureType: 'atonic',
      date: new Date(Date.now()),
      hasRescueMedication: false,
      comments: '',
      crisisNumber: '',
      drugsQuantity: null,
    },
  });

  const userId = useSelector(state => state.user.id);

  const navigate = useNavigate();
  const intl = useIntl();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: any) => {
      const {
        seizureType,
        comments,
        date,
        crisisNumber,
        drugsQuantity,
        hasRescueMedication,
      } = data;
      setButtonDisabled(true);
      ReactGA.event({
        category: 'ButtonClick',
        action: 'Record Crisis',
      });

      try {
        await client
          .post('/crisis', {
            type: seizureType,
            comments: comments,
            userId,
            crisisNumber,
            hasRescueMedication,
            date: date.toJSON(),
            drugsQuantity,
          })
          .catch(error => console.log('Error saving crisis: ', error));
        navigate(paths.LOGGED_CRISIS);
      } catch (error) {
        console.log('Error saving crisis: ', error);
        setButtonDisabled(false);
      }
    },
    [navigate, userId],
  );

  const crisisTypes = useCrises();

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',

          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <BackArrow />
              </Box>
              <Box>
                <FormattedMessage id="record-crisis.title">
                  {(text: string) => (
                    <Typography
                      align="center"
                      fontSize={fontSize.large}
                      fontFamily="Poppins Medium"
                      style={{ marginBottom: '2vh', color: colors.darkPurple }}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <FormattedMessage id="record-crisis.crisis-placeholder">
                {(placeholder: string) => (
                  <Box>
                    <InputLabel id="label">{placeholder}</InputLabel>
                    <Select
                      onChange={event => {
                        setValue('seizureType', event.target.value as string, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}
                      style={{ width: '100%' }}
                      labelId="label"
                      value={getValues().seizureType}
                    >
                      {crisisTypes.map(crisisType => (
                        <MenuItem key={crisisType.dbName} value={crisisType.dbName}>
                          {crisisType.localizedName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </FormattedMessage>
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('crisisNumber', {
                  required: true,

                  pattern: {
                    value: /^(0|[1-9][0-9]*)$/,
                    message: intl.formatMessage({ id: 'record-crisis.error-number' }),
                  },
                })}
                id="standard-number"
                hiddenLabel
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-crisis.crisis-number' })}
              />
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('drugsQuantity', {
                  pattern: {
                    value: /^(0|[1-9][0-9]*)$/,
                    message: intl.formatMessage({ id: 'record-crisis.error-number' }),
                  },
                })}
                id="standard-number"
                hiddenLabel
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-crisis.drugs-amount' })}
                helperText={
                  errors.drugsQuantity
                    ? intl.formatMessage({ id: 'record-crisis.error-number' })
                    : ''
                }
              />
            </Box>
            <FormControlLabel
              {...register('hasRescueMedication')}
              control={<Checkbox />}
              label={
                <Typography style={{ fontFamily: 'Poppins Regular' }}>
                  <FormattedMessage id="record-crisis.has-rescue-medication" />
                </Typography>
              }
              style={{ fontFamily: 'Poppins Light' }}
            />
            <FormattedMessage id="record-crisis.comments">
              {(placeholder: string) => (
                <Box style={{ margin: '1vh 0' }}>
                  <TextField
                    {...register('comments', { required: false })}
                    hiddenLabel
                    id="outlined-multiline-static"
                    multiline
                    rows={6}
                    fullWidth
                    placeholder={placeholder}
                  />
                </Box>
              )}
            </FormattedMessage>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
            disabled={buttonDisabled}
          >
            <FormattedMessage id="record-crisis.save-crisis" />
          </Button>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
