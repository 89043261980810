import { Box, Button, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { HistoryCard } from 'components/HistoryCard';
import { getSymptomsForPatientQuery } from 'dataLayer/symptoms';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import { colors } from 'theme';
import { Symptom } from 'types/types';
import ReactGA from 'react-ga';

export const SymptomHistory: FunctionComponent = () => {
  const userId = useSelector(state => state.user.id);
  const navigate = useNavigate();
  const { data: symptomList } = useQuery(getSymptomsForPatientQuery(userId));

  const symptomPageWrapper = useRef<HTMLDivElement>(null);

  const [appWidth, setAppWidth] = useState(0);

  const getAppWidth = () => {
    if (symptomPageWrapper.current) setAppWidth(symptomPageWrapper.current.offsetWidth);
  };

  useEffect(() => {
    getAppWidth();
    window.addEventListener('resize', getAppWidth);
  }, []);

  const navigateToRecord = useCallback(() => {
    ReactGA.event({
      category: 'ButtonClick',
      action: 'Record Symptom From History Page',
    });
    navigate(paths.RECORD_SYMPTOMS);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        mb: 6,
      }}
      ref={symptomPageWrapper}
    >
      <Box style={{ display: 'flex' }}>
        <Box style={{ flex: 1 }}>
          <BackArrow />
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography
            fontFamily="Poppins Medium"
            fontSize="18px"
            style={{ color: colors.darkPurple }}
          >
            <FormattedMessage id="symptom-history.title" />
          </Typography>
        </Box>
        <Box style={{ flex: 1 }}></Box>
      </Box>
      {symptomList &&
        symptomList.map((symptom: Symptom) => (
          <HistoryCard key={symptom.id} historyType="symptom" data={symptom} />
        ))}
      <Box
        position="fixed"
        height={48}
        bottom="0px"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={navigateToRecord}
          style={{
            color: colors.white,
            backgroundColor: colors.darkPurple,
            width: `${appWidth}px`,
          }}
        >
          <FormattedMessage id="symptom-history.add-symptom-record" />
        </Button>
      </Box>
    </Box>
  );
};
