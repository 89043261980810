import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { Box, IconButton, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { MenuOption } from 'components/MenuOption';
import { DownloadAppOption } from 'components/DownloadAppOption';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLoggedOut } from 'redux/Login';
import { userDisconnect } from 'redux/User';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ClearIcon from '@mui/icons-material/Clear';
import { paths } from 'routes';

interface SlideMenuProps {
  onClose: () => void;
}

export const SlideMenu: FunctionComponent<SlideMenuProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const iconStyling = { fontSize: '35px', fill: '#787878' };
  const menuOptions = [
    {
      textId: 'menu.my-profile',
      linkPath: '/profile-settings',
      icon: <PersonOutlineOutlinedIcon sx={iconStyling} />,
    },
    {
      textId: 'menu.my-diet',
      linkPath: '/diet',
      icon: <ListAltIcon sx={iconStyling} />,
    },
    {
      textId: 'menu.record-history',
      linkPath: '/history',
      icon: <DateRangeOutlinedIcon sx={iconStyling} />,
    },
    {
      textId: 'menu.add-record',
      linkPath: '/record',
      icon: <BarChartOutlinedIcon sx={iconStyling} />,
    },
    {
      textId: 'menu.my-data',
      linkPath: '/data',
      icon: <ShowChartIcon sx={iconStyling} />,
    },
    {
      textId: 'menu.news',
      linkPath: 'https://www.terapiaparaepilepsia.com.ar/ketoapp/',
      icon: <NewspaperIcon sx={iconStyling} />,
      newTab: true,
    },
  ];

  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [displayDownloadButton, setDisplayDownloadButton] = useState(true);

  useEffect(() => {
    console.log('running useeffect');
    window.addEventListener('beforeinstallprompt', (event: Event) => {
      // Prevent the mini-infobar from appearing on mobile.
      console.log('adding listener');
      event.preventDefault();
      console.log('----> beforeinstallprompt', event);
      setDeferredPrompt(event);
    });

    window.addEventListener('appinstalled', (event: Event) => {
      console.log('----> appinstalled', event);
      // Clear the deferredPrompt so it can be garbage collected
      setDeferredPrompt(null);
      setDisplayDownloadButton(false);
    });
  }, []);

  const handleLogOut = useCallback(async () => {
    try {
      await Auth.signOut();
      await dispatch(userDisconnect());
      await dispatch(userLoggedOut());
      navigate(paths.LOGIN);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }, [dispatch, navigate]);

  const downloadApp = async () => {
    console.log('Clicked Download App');
    if (deferredPrompt === null) {
      console.log('deferred prompt not available');
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    deferredPrompt.prompt();
    // window.prompt('Prompt');

    const result = await deferredPrompt.userChoice;
    console.log('userChoice', result);

    setDeferredPrompt(null);
    setDisplayDownloadButton(false);
  };

  return (
    <>
      <Box display="flex" justifyContent={'flex-end'} px={2} pt={2}>
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box display="flex" height="90%" flexDirection="column" px={4} pb={4}>
        {menuOptions.map(menuOption => (
          <MenuOption
            key={menuOption.textId}
            textId={menuOption.textId}
            icon={menuOption.icon}
            linkPath={menuOption.linkPath}
            newTab={menuOption?.newTab}
            onClose={onClose}
          />
        ))}
        {/* {displayDownloadButton && (
          <DownloadAppOption
            textId="menu.download-app"
            icon={<DownloadingIcon sx={iconStyling} />}
            onClick={downloadApp}
          />
        )} */}
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Typography
            fontSize="18px"
            color="GrayText"
            style={{ fontFamily: 'Poppins Regular', textDecoration: 'underline' }}
            onClick={handleLogOut}
          >
            <FormattedMessage id="menu.log-out" />
          </Typography>
        </Box>
      </Box>
    </>
  );
};
