/*eslint complexity: ["error", 20]*/
/* eslint-disable max-lines */

import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, FormattedMessage as T, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { signupFormSubmit } from 'redux/Signup';
import { paths } from 'routes';
import client from 'services/networking/client';
import { TermsModal } from './TermsModal';

interface IProps {
  goForwardOneStep: () => void;
}

export const SignupForm: FunctionComponent<IProps> = ({ goForwardOneStep }) => {
  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = t({ id: 'locale.abbreviation' });

  const onSubmit = useCallback(
    async data => {
      try {
        const dniResponse = await client
          .post('/auth/dni-check', {
            patientDni: data.patientId,
          })
          .catch(error => {
            throw new Error(error);
          });

        if (Boolean(dniResponse.hasFound)) {
          await dispatch(signupFormSubmit(data));
          goForwardOneStep();
        } else {
          navigate(paths.UNREGISTERED_USER);
        }
      } catch (error) {
        console.log('Error checking DNI: ', error);
      }
    },
    [dispatch, goForwardOneStep, navigate],
  );

  const goBackOneStep = useCallback(() => {
    history.push('/home-menu');
  }, [history]);

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row' }}
              onClick={goBackOneStep}
            >
              <BackArrow />
            </Box>
            <Box style={{ marginBottom: '5vh' }}>
              <Typography
                align="center"
                fontSize="22px"
                fontFamily="Poppins Medium"
                style={{ marginBottom: '2vh' }}
              >
                <T id="signup.signup-top" />
              </Typography>
              <Typography align="center" fontSize="18px" fontFamily="Poppins Regular">
                <T id="signup.top-message" />
              </Typography>
            </Box>

            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('firstName', { required: true })}
                placeholder={t({ id: 'signup.firstname' })}
                fullWidth
                id="fullWidth"
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            {Boolean(errors.firstName) && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                <T id="signup.error-firstname-required" />
              </Typography>
            )}

            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('lastName', { required: true })}
                placeholder={t({ id: 'signup.lastname' })}
                fullWidth
                id="fullWidth"
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            {Boolean(errors.lastName) && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                <T id="signup.error-lastname-required" />
              </Typography>
            )}

            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('patientId', { required: true })}
                placeholder={t({ id: 'signup.patientid' })}
                fullWidth
                id="fullWidth"
                type="number"
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            {Boolean(errors.patientId) && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                <T id="signup.error-patientid-required" />
              </Typography>
            )}

            <Box style={{ margin: '1vh 0' }}>
              <MobileDatePicker
                {...register('dateOfBirth', { required: true })}
                label={t({ id: 'signup.date-of-birth' })}
                inputFormat="dd/MMM/yyyy"
                value={getValues().dateOfBirth}
                onChange={newdate => {
                  Boolean(newdate) &&
                    setValue('dateOfBirth', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            {Boolean(errors.dateOfBirth) && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                <Typography id="signup.error-dob-required" />
              </Typography>
            )}

            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('email', {
                  required: t({ id: 'signup.error-email-required' }),
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t({ id: 'signup.error-valid-email' }),
                  },
                })}
                placeholder={t({ id: 'signup.email' })}
                fullWidth
                id="fullWidth"
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            {Boolean(errors.email) && errors.email.type && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                {errors.email?.message}
              </Typography>
            )}

            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('confirmEmail', {
                  validate: {
                    emailEqual: (value: string) =>
                      value === getValues().email || t({ id: 'signup.error-email-match' }),
                  },
                })}
                placeholder={t({ id: 'signup.confirm-email' })}
                fullWidth
                id="fullWidth"
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            {Boolean(errors.confirmEmail) && errors.confirmEmail.type && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                {errors.confirmEmail.message}
              </Typography>
            )}

            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('password', {
                  required: t({ id: 'signup.error-password-required' }),
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
                    message: t({ id: 'signup.error-valid-password' }),
                  },
                })}
                type="password"
                placeholder={t({ id: 'signup.password' })}
                fullWidth
                id="fullWidth"
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
              />
            </Box>
            {Boolean(errors.password) && errors.password.type && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                {errors.password?.message}
              </Typography>
            )}

            <Typography
              mt={1}
              fontFamily="Poppins Regular"
              onClick={() => setIsOpen(!isOpen)}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              <FormattedMessage id="signup.view-terms" />
            </Typography>
            <FormControlLabel
              {...register('acceptTerms', { required: true })}
              control={<Checkbox />}
              label={
                <Typography style={{ fontFamily: 'Poppins Regular' }}>
                  <T id="signup.accept-terms" />
                </Typography>
              }
              style={{ fontFamily: 'Poppins Light' }}
            />
            {Boolean(errors.acceptTerms) && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                <T id="signup.error-accept-terms" />
              </Typography>
            )}
          </Box>

          <Box marginBottom="3vh">
            <Typography
              align="center"
              style={{
                fontFamily: 'Poppins Regular',
                textDecoration: 'underline',
                marginBottom: '2vh',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/');
              }}
            >
              <T id="signup.back-to-home" />
            </Typography>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ height: '45px', width: '100%' }}
            >
              <T id="signup.next" />
            </Button>
          </Box>
        </form>
      </LocalizationProvider>
      <TermsModal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} />
    </>
  );
};
