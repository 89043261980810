import { Box, Button, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { HistoryCard } from 'components/HistoryCard/HistoryCard';
import { getHeightWeightForPatientQuery } from 'dataLayer/heightweight';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import { colors } from 'theme';
import { HeightWeight } from 'types/types';
import ReactGA from 'react-ga';

export const HeightWeightHistory: FunctionComponent = () => {
  const userId = useSelector(state => state.user.id);
  const navigate = useNavigate();
  const { data: heightWeightList, isLoading } = useQuery(getHeightWeightForPatientQuery(userId));

  const [appWidth, setAppWidth] = useState(0);

  const heightWeightPageWrapper = useRef<HTMLDivElement>(null);

  const getAppWidth = () => {
    if (heightWeightPageWrapper.current) setAppWidth(heightWeightPageWrapper?.current?.offsetWidth);
  };

  useEffect(() => {
    getAppWidth();
    window.addEventListener('resize', getAppWidth);
  }, []);

  const navigateToRecord = useCallback(() => {
    ReactGA.event({
      category: 'ButtonClick',
      action: 'Record Height/Weight From History Page',
    });
    navigate(paths.RECORD_HEIGHT_WEIGHT);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        mb: 6,
      }}
      ref={heightWeightPageWrapper}
    >
      <Box style={{ display: 'flex' }}>
        <Box style={{ flex: 1 }}>
          <BackArrow />
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography
            fontFamily="Poppins Medium"
            fontSize="18px"
            style={{ color: colors.darkPurple }}
          >
            <FormattedMessage id="height-weight-history.title" />
          </Typography>
        </Box>

        <Box style={{ flex: 1 }}></Box>
      </Box>
      {heightWeightList &&
        heightWeightList.map((heightWeight: HeightWeight) => (
          <HistoryCard key={heightWeight.id} historyType="heightWeight" data={heightWeight} />
        ))}
      <Box
        position="fixed"
        height={48}
        bottom="0px"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={navigateToRecord}
          style={{
            color: colors.white,
            backgroundColor: colors.darkPurple,
            width: `${appWidth}px`,
          }}
        >
          <FormattedMessage id="height-weight-history.add-height-weight-record" />
        </Button>
      </Box>
    </Box>
  );
};
