/* eslint-disable max-lines */
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { ketoColors, KetonesIcon } from 'components/KetonesIcon';
import { localeMap } from 'const/calendar';
import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useSelector from 'redux/useSelector';
import client from 'services/networking/client';
import { Ketone } from 'types/types';
import CancelIcon from '@mui/icons-material/Cancel';
import { colors, fontSize } from 'theme';
import { useKetones } from 'const/ketoneTypes';

interface IProps {
  ketoneData: Ketone;
  closeEdit: () => void;
}

export const EditKetoneForm: FunctionComponent<IProps> = ({ ketoneData, closeEdit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      date: ketoneData.date,
      comments: ketoneData.comments,
      ketonesLevel: ketoneData.ketonesLevel,
      ketonesNumber: ketoneData.ketonesNumber,
    },
  });

  const userId = useSelector(state => state.user.id);

  const intl = useIntl();

  const ketoneTypes = useKetones();

  const onSubmit = useCallback(
    async (data: any) => {
      const { ketonesLevel, comments, ketonesNumber, date } = data;
      try {
        await client.patch(`/ketones/${ketoneData.id}`, {
          ketonesLevel: ketonesLevel,
          comments: comments,
          userId,
          ketonesNumber: ketonesNumber === '' ? null : ketonesNumber,
          date,
        });
        ketoneData.ketonesLevel = ketonesLevel;
        ketoneData.ketonesNumber = ketonesNumber;
        ketoneData.date = date;
        ketoneData.comments = comments;
        closeEdit();
      } catch (error) {
        console.log('Error saving ketones: ', error);
      }
    },
    [closeEdit, ketoneData, userId],
  );

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <IconButton onClick={closeEdit}>
                  <CancelIcon />
                </IconButton>
              </Box>
              <Box>
                <FormattedMessage id="record-ketones.edit-title">
                  {(text: string) => (
                    <Typography
                      align="center"
                      fontSize="22px"
                      fontFamily="Poppins Medium"
                      style={{ marginBottom: '2vh' }}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {intl.formatMessage({ id: 'record-ketones.ketones-level' })}
                </FormLabel>
                <RadioGroup
                  aria-label="ketone-level"
                  name="row-radio-buttons-group"
                  onChange={newValue => {
                    setValue('ketonesLevel', newValue.target.value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                >
                  {ketoneTypes.map(ketoneType => (
                    <FormControlLabel
                      key={ketoneType.dbName}
                      value={ketoneType.dbName}
                      control={
                        <KetonesIcon
                          colorLevel={ketoneType.dbName as keyof typeof ketoColors}
                          radio={true}
                        />
                      }
                      label={ketoneType.localizedName}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Typography
              align="left"
              fontSize="18px"
              fontFamily="Poppins Medium"
              style={{ marginBottom: '2vh' }}
            >
              <FormattedMessage id="record-ketones.or" />
            </Typography>
            <Typography
              align="left"
              fontSize={fontSize.medium}
              fontFamily="Poppins Regular"
              style={{ marginBottom: '2vh', color: colors.mediumGrey }}
            >
              <FormattedMessage id="record-ketones.cetonemia" />
            </Typography>
            <FormattedMessage id="record-ketones.alternate-input">
              {(placeholder: string) => (
                <Box style={{ margin: '1vh 0' }}>
                  <TextField
                    {...register('ketonesNumber', {
                      required: false,
                      pattern: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: intl.formatMessage({ id: 'record-ketones.error-number' }),
                      },
                    })}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    id="standard-number"
                    hiddenLabel
                    fullWidth
                    placeholder={placeholder}
                  />
                </Box>
              )}
            </FormattedMessage>
            {Boolean(errors.ketonesNumber) && (
              <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
                {errors.ketonesNumber?.message}
              </Typography>
            )}
            <FormattedMessage id="record-ketones.comments">
              {(placeholder: string) => (
                <Box style={{ margin: '1vh 0' }}>
                  <TextField
                    {...register('comments', { required: false })}
                    hiddenLabel
                    id="outlined-multiline-static"
                    multiline
                    rows={6}
                    fullWidth
                    placeholder={placeholder}
                  />
                </Box>
              )}
            </FormattedMessage>
          </Box>
          <FormattedMessage id="record-ketones.save-ketones">
            {(text: string) => (
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: '45px',
                  width: '100%',
                  color: colors.white,
                  backgroundColor: colors.darkPurple,
                }}
                type="submit"
              >
                {text}
              </Button>
            )}
          </FormattedMessage>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
