import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { CrisisOverTime } from 'components/DataVisualization/CrisisOverTime';
import { CrisisTypes } from 'components/DataVisualization/CrisisTypes';
import { CrisisVsKetonesOverTime } from 'components/DataVisualization/CrisisVsKetonesOverTime';
import { HeightOverTime } from 'components/DataVisualization/HeightOverTime';
import { MoodOverTime } from 'components/DataVisualization/MoodOverTime/MoodOverTime';
import { WeightOverTime } from 'components/DataVisualization/WeightOverTime';
import { localeMap } from 'const/calendar';
import { getCrisesForPatientByDateQuery } from 'dataLayer/crises';
import { getHeightWeightForPatientByDateQuery } from 'dataLayer/heightweight';
import { getKetonesForPatientByDateQuery } from 'dataLayer/ketones';
import { getMoodForPatientByDateQuery } from 'dataLayer/mood';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage, FormattedMessage as T, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import { BackArrow } from 'components/BackArrow';
import { fontSize, colors } from 'theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export const Data: FunctionComponent = () => {
  const userId = useSelector(state => state.user.id);
  const navigate = useNavigate();
  const navigateHome = useCallback(() => {
    navigate(paths.HOME_MENU);
  }, [navigate]);
  const currentDate = new Date();
  const lastMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    currentDate.getDate(),
  );

  const [startDate, setStartDate] = useState(lastMonth);
  const [endDate, setEndDate] = useState(currentDate);

  const { data: crisisList } = useQuery(getCrisesForPatientByDateQuery(userId, startDate, endDate));

  const { data: heightWeightList } = useQuery(
    getHeightWeightForPatientByDateQuery(userId, startDate, endDate),
  );

  const { data: ketonesList } = useQuery(
    getKetonesForPatientByDateQuery(userId, startDate, endDate),
  );

  const { data: moodList } = useQuery(getMoodForPatientByDateQuery(userId, startDate, endDate));

  const getNumberOfDays = useCallback(() => {
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();
    if (diffInMilliseconds < 0) {
      return 0;
    }
    return Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }, [endDate, startDate]);

  const intl = useIntl();

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  const navigateToPdf = useCallback(() => {
    navigate(paths.DATA_PDF, { state: { startDate: startDate, endDate: endDate } });
  }, [endDate, navigate, startDate]);

  return (
    <Box
      style={{
        display: 'flex',
        height: '90%',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box style={{ flex: 1 }}>
          <IconButton onClick={navigateHome}>
            <ArrowBackIcon style={{ color: colors.darkPurple }} />
          </IconButton>
        </Box>
        <Box>
          <Typography
            align="center"
            fontFamily="Poppins Regular"
            style={{
              fontFamily: 'Poppins Regular',
              textDecoration: 'underline',
              marginBottom: '2vh',
              cursor: 'pointer',
            }}
            onClick={navigateHome}
          >
            <FormattedMessage id="data.title" />
          </Typography>
        </Box>
        <Box style={{ flex: 1 }}></Box>
      </Box>
      <Box sx={{ display: 'flex', mx: 3, mb: 3, justifyContent: 'center' }}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
        >
          <MobileDatePicker
            label="Start Date"
            inputFormat="dd/MMM/yyyy"
            value={startDate}
            onChange={newdate => {
              newdate && setStartDate(newdate);
            }}
            renderInput={params => <TextField {...params} />}
          />
          <MobileDatePicker
            label="End Date"
            inputFormat="dd/MMM/yyyy"
            value={endDate}
            onChange={newdate => {
              newdate && setEndDate(newdate);
            }}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
      <Button
        variant="contained"
        color="primary"
        style={{ height: '45px', width: '100%' }}
        onClick={navigateToPdf}
      >
        <T id="data.generate" />
      </Button>
      {crisisList && ketonesList && heightWeightList && moodList && (
        <Box>
          <CrisisOverTime
            crisisList={crisisList}
            numberOfDays={getNumberOfDays()}
            startDate={startDate}
            endDate={endDate}
          />

          <CrisisTypes crisisList={crisisList} />

          <CrisisVsKetonesOverTime
            ketonesList={ketonesList}
            crisisList={crisisList}
            numberOfDays={getNumberOfDays()}
            startDate={startDate}
            endDate={endDate}
          />

          <HeightOverTime heightWeightList={heightWeightList} />

          <WeightOverTime heightWeightList={heightWeightList} />

          <MoodOverTime moodList={moodList} numberOfDays={getNumberOfDays()} />
        </Box>
      )}
    </Box>
  );
};
