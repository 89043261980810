import { Box, Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { colors } from 'theme';

export const SleepLogged: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <Box
      style={{
        display: 'flex',
        height: '90%',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Box style={{ flex: 1 }}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '5vh' }}>
          <Typography
            align="center"
            fontSize="22px"
            fontFamily="Poppins Medium"
            style={{ marginBottom: '2vh' }}
          >
            {intl.formatMessage({ id: 'sleep-logged.title' })}
          </Typography>
        </Box>
      </Box>
      <Box style={{ flex: 1 }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '45px',
            width: '100%',
            margin: '1vh 0',
            color: colors.white,
            backgroundColor: colors.darkPurple,
          }}
          component={Link}
          to={paths.RECORD_SLEEP}
        >
          {intl.formatMessage({ id: 'sleep-logged.another' })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '45px',
            width: '100%',
            margin: '1vh 0',
            color: colors.white,
            backgroundColor: colors.darkPurple,
          }}
          component={Link}
          to={paths.RECORD}
        >
          {intl.formatMessage({ id: 'record-logged.different' })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '45px',
            width: '100%',
            margin: '1vh 0',
            color: colors.white,
            backgroundColor: colors.darkPurple,
          }}
          component={Link}
          to={paths.SLEEP_HISTORY}
        >
          {intl.formatMessage({ id: 'sleep-logged.history' })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '45px',
            width: '100%',
            margin: '1vh 0',
            color: colors.white,
            backgroundColor: colors.darkPurple,
          }}
          component={Link}
          to={paths.HOME_MENU}
        >
          {intl.formatMessage({ id: 'sleep-logged.menu' })}
        </Button>
      </Box>
      <Box style={{ flex: 1 }}></Box>
    </Box>
  );
};
