import { LineAxisOutlined } from '@mui/icons-material';
import { FetchQueryOptions, useMutation, useQueryClient } from 'react-query';
import { Symptom } from 'types/types';

export const getSymptomsForPatient = async (patientId: string): Promise<Symptom[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/symptoms/user/${patientId}`).then(async response =>
    response.json(),
  );
};

export const getSymptomsForPatientQuery = (patientId: string): FetchQueryOptions<Symptom[]> => ({
  queryKey: ['symptoms', patientId],
  queryFn: async () => await getSymptomsForPatient(patientId),
});

export const DeleteSymptomByIdMutation = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    (symptomId: string) => {
      const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
      return fetch(`${backendBaseUrl}/symptoms/${symptomId}`, {
        method: 'DELETE',
      });
    },
    {
      onMutate: async (symptomId: string) => {
        await queryClient.cancelQueries('symptoms');
        const previousSymptoms = queryClient.getQueryData<Symptom[]>('symptoms');
        if (previousSymptoms) {
          const newSymptoms = previousSymptoms.filter(symptom => symptom.id !== symptomId);
          queryClient.setQueryData<Symptom[]>('symptoms', { ...newSymptoms });
        }
        return { previousSymptoms };
      },
      onSettled: () => {
        queryClient.invalidateQueries('symptoms');
      },
    },
  );
  return deleteMutation;
};
