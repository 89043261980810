import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { paths } from 'routes';
import { Recipe } from 'types/types';
import { colors, fontSize, theme } from '../../theme';
import { useDeleteRecipeForDateByIdMutation } from './hooks';

interface IProps {
  recipe: Recipe;
}

export const SmallMealCard: FunctionComponent<IProps> = ({ recipe }) => {
  const urlParams = useParams();
  const recipeDetailsPath = generatePath(paths.RECIPE, {
    ...urlParams,
    recipeId: recipe.recipeId,
  });

  const location = useLocation();
  const path = location.pathname;

  const imageUrl = recipe.photo.sizes !== undefined ? recipe.photo.sizes.medium : recipe.photo.url;

  const macros = [
    { id: 'diet.fat', value: recipe.fats },
    { id: 'diet.carbs', value: recipe.carbs },
    { id: 'diet.protein', value: recipe.protein },
  ];

  const deleteRecipeForDateByRecipeId = useDeleteRecipeForDateByIdMutation();
  const onDelete = useCallback(() => {
    if (recipe.uuid !== undefined) {
      deleteRecipeForDateByRecipeId.mutate({ id: recipe.uuid });
    }
  }, [deleteRecipeForDateByRecipeId, recipe.uuid]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        boxShadow: 2,
        mb: 1,
      }}
    >
      <CardActionArea component={Link} to={recipeDetailsPath} sx={{ p: 1 }}>
        <Box display="flex" mb={2} pr={path === paths.DIET ? 3 : 0}>
          <CardMedia
            sx={{
              borderRadius: '5px',
              maxWidth: '80px',
              mr: 1,
              backgroundColor: colors.lightGrey,
              fontFamily: theme.typography,
              fontSize: fontSize.XSmall,
              lineHeight: '15px',
              textAlign: 'center',
            }}
            component="img"
            height="80"
            image={imageUrl}
            alt={recipe.title}
            loading="lazy"
          />
          <Typography
            variant="body2"
            fontFamily="Poppins Medium"
            fontSize="18px"
            style={{ color: colors.darkPurple }}
          >
            {recipe.title}
          </Typography>
        </Box>
        <Box style={{ display: 'flex' }}>
          {macros.map((macro, index) => (
            <Box key={macro.id} display="flex">
              {index !== 0 && (
                <Divider
                  orientation="vertical"
                  sx={{ mx: 1 }}
                  style={{ backgroundColor: colors.mediumGrey }}
                />
              )}
              <Typography
                style={{ color: colors.darkLightGrey }}
                fontFamily="Poppins Light"
                fontSize="12px"
              >
                {macro.value} gr. <FormattedMessage id={macro.id} />
              </Typography>
            </Box>
          ))}
        </Box>
      </CardActionArea>
      {path === paths.DIET && (
        <Box position="absolute" top={8} right={8}>
          <IconButton sx={{ alignSelf: 'flex-start', p: 0 }} onClick={onDelete}>
            <ClearIcon />
          </IconButton>
        </Box>
      )}
    </Card>
  );
};
