import { Box, Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export const TermsAndConditions: FunctionComponent = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" height="90%" p={2}>
      <Box>
        <Box style={{ marginTop: '5vh' }}>
          <FormattedMessage id="terms-and-conditions.title">
            {(text: string) => (
              <Typography
                align="center"
                fontSize="22px"
                fontFamily="Poppins Medium"
                style={{ marginBottom: '2vh' }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        </Box>

        <FormattedMessage id="terms-and-conditions.terms-content">
          {(text: string) => (
            <Box
              style={{
                border: '1px',
                borderStyle: 'solid',
                height: '60vh',
                overflow: 'scroll',
              }}
            >
              <Typography
                align="left"
                fontSize="18px"
                fontFamily="Poppins Regular"
                style={{ margin: '2vw' }}
              >
                {text}
              </Typography>
            </Box>
          )}
        </FormattedMessage>
      </Box>
      <Box>
        <FormattedMessage id="terms-and-conditions.accept">
          {(text: string) => (
            <Button variant="contained" color="primary" style={{ height: '45px', width: '100%' }}>
              {text}
            </Button>
          )}
        </FormattedMessage>
      </Box>
      <Box>
        <FormattedMessage id="terms-and-conditions.cancel">
          {(text: string) => (
            <Button variant="outlined" color="primary" style={{ height: '45px', width: '100%' }}>
              {text}
            </Button>
          )}
        </FormattedMessage>
      </Box>
    </Box>
  );
};
