import { Box, Button, TextField, Typography } from '@mui/material';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { BackArrow } from 'components/BackArrow';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { useForm } from 'react-hook-form';
import { colors, fontSize } from 'theme';

export const ResetPasswordInit: FunctionComponent = () => {
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();
  const [hasError, setHasError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    data => {
      const { patientId } = data;
      try {
        Auth.forgotPassword(patientId)
          .then(res => {
            navigate(paths.RESET_PASSWORD_FORM, { state: { patientId } });
          })
          .catch(err => {
            throw new Error(err);
          });
      } catch (err) {
        setHasError(true);
      }
    },
    [navigate, setHasError],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" height="90%" justifyContent="space-between" flexDirection="column" p={2}>
        <Box justifyContent="center" display="flex" flexDirection="column" marginBottom="5vh">
          <Box style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row' }}>
            <BackArrow />
          </Box>
          <Box style={{ marginBottom: '5vh' }}>
            <Typography
              align="center"
              fontSize="22px"
              fontFamily="Poppins Medium"
              style={{ marginBottom: '2vh' }}
            >
              <T id="reset-password.title" />
            </Typography>
          </Box>

          <Box>
            <Typography align="center" fontFamily="Poppins Light" style={{ marginBottom: '2vh' }}>
              <T id="reset-password.input-id" />
            </Typography>
          </Box>

          <Box style={{ marginBottom: '5vh' }}>
            <TextField
              {...register('patientId', { required: true })}
              placeholder={t({ id: 'reset-password.patient-id' })}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
            />
          </Box>
          {Boolean(errors.patientId) && (
            <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
              <T id="reset-password.error-patientid-required" />
            </Typography>
          )}

          <Box style={{ marginBottom: '5vh' }}>
            <Typography align="center" fontFamily="Poppins light">
              <T id="reset-password.email-will-be-sent" />
            </Typography>
          </Box>

          {hasError && (
            <Box>
              <Typography align="center" color="red" fontFamily="Poppins light">
                <T id="reset-password.something-went-wrong" />
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            align="center"
            style={{
              fontFamily: 'Poppins Regular',
              textDecoration: 'underline',
              marginBottom: '2vh',
            }}
            component={Link}
            to={paths.HOME_MENU}
          >
            <T id="reset-password.back-to-home" />
          </Typography>

          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
          >
            <T id="reset-password.next" />
          </Button>
        </Box>
      </Box>
    </form>
  );
};
