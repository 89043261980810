import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MealTypes } from 'const/mealTypes';
import { RecipeState, Recipe } from 'types/types';

const initialState: RecipeState = {
  currentDate: new Date(Date.now()),
  currentMealType: MealTypes.ALL,
  currentRecipe: 0,
  recipes: [],
};

const RecipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    selectCurrentRecipe: (state, action: PayloadAction<number>) => {
      state.currentRecipe = action.payload;
    },
    updateCurrentDate: (state, action: PayloadAction<Date>) => {
      state.currentDate = action.payload;
    },
    updateCurrentMealType: (state, action: PayloadAction<MealTypes>) => {
      state.currentMealType = action.payload;
    },
    updateRecipeList: (state, action: PayloadAction<Recipe>) => {
      state.recipes.push(action.payload);
    },
  },
});

export const {
  selectCurrentRecipe,
  updateCurrentDate,
  updateCurrentMealType,
  updateRecipeList,
} = RecipeSlice.actions;
export default RecipeSlice.reducer;
