import { calculateTotalMacros, getRecipeQuery, getRecipesForDateQuery } from 'dataLayer/recipes';
import { format, parseJSON } from 'date-fns';
import { some, zipWith } from 'lodash';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { getKetoCalcToken } from 'redux/Login';
import { getCurrentDate } from 'redux/Recipe';
import { getPatientId } from 'redux/User/selectors';
import { Macros, Recipe } from 'types/types';

export const useFetchRecipesForDate = (): {
  recipes: Recipe[];
  currentMacros: Macros;
  isLoading: boolean;
  isError: boolean;
} => {
  const qc = useQueryClient();
  const patientId = useSelector(getPatientId);
  const currentDate = parseJSON(useSelector(getCurrentDate));
  const ketoCalcToken = useSelector(getKetoCalcToken);

  const { data: recipesForDate, isLoading: isRecipesForDateLoading } = useQuery(
    getRecipesForDateQuery(patientId, format(currentDate, 'yyyy-MM-dd')),
  );

  const queryResults = useQueries(
    (recipesForDate ?? []).map(recipeReference => {
      return getRecipeQuery(qc, recipeReference.recipeId, ketoCalcToken);
    }),
  );

  const ketoCalculatorRecipes = queryResults
    .map(response => response.data)
    .filter(response => typeof response !== undefined && response) as Recipe[];

  let recipes: Recipe[] = [];
  if (
    !isRecipesForDateLoading &&
    recipesForDate &&
    ketoCalculatorRecipes.length === recipesForDate.length
  ) {
    recipes = zipWith(
      ketoCalculatorRecipes,
      recipesForDate,
      (ketoCalculatorRecipe, recipeForDate) => {
        return {
          ...ketoCalculatorRecipe,
          uuid: recipeForDate.id,
          mealType: recipeForDate.mealType,
        };
      },
    );
  }

  const currentMacros = calculateTotalMacros(recipes);
  const isLoading = some(queryResults, result => result.isLoading);
  const isError = some(queryResults, result => result.isError);

  return {
    recipes,
    currentMacros,
    isLoading,
    isError,
  };
};
