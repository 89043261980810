import { Box, IconButton } from '@mui/material';
import React, { FunctionComponent } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { colors } from 'theme';

export const BackArrow: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <IconButton onClick={() => navigate(-1)}>
      <ArrowBackIcon style={{ color: colors.darkPurple }} />
    </IconButton>
  );
};
