import { Box, Typography } from '@mui/material';
import { AddMealCard } from 'components/AddMealCard';
import CalendarHeader from 'components/CalendarHeader';
import { DailyMacros } from 'components/DailyMacros';
import Loader from 'components/Loader';
import { SmallMealCard } from 'components/SmallMealCard';
import { MealType, mealTypes } from 'const/mealTypes';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { colors, fontSize } from 'theme';
import { useFetchRecipesForDate } from './hooks';

export const Diet: FunctionComponent = () => {
  const { recipes, isLoading } = useFetchRecipesForDate();

  const renderRecipesByMealType = (mealType: MealType) => {
    const recipesOfMealType = recipes.filter(recipe => recipe?.mealType === mealType.type);
    return (
      recipesOfMealType.length !== 0 &&
      recipesOfMealType.map(recipe => <SmallMealCard key={recipe.uuid} recipe={recipe} />)
    );
  };

  return (
    <Box sx={{ display: 'flex', height: '95%', flexDirection: 'column' }}>
      <CalendarHeader />
      <DailyMacros />
      {mealTypes.map(mealType => (
        <Box key={mealType.id} mb={2}>
          <Typography
            fontFamily="Poppins Regular"
            fontSize={fontSize.medium}
            sx={{ color: colors.darkPurple, mb: 1 }}
          >
            <FormattedMessage id={mealType.id} />
          </Typography>
          {isLoading && recipes.length === 0 ? <Loader /> : renderRecipesByMealType(mealType)}
          <AddMealCard mealTypeId={mealType.id} />
        </Box>
      ))}
    </Box>
  );
};
