/* eslint-disable max-lines */
import { Box, Button, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { BackArrow } from 'components/BackArrow';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { colors, fontSize } from 'theme';

interface LocationState {
  patientId: string;
}

export const ResetPasswordForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage: t } = useIntl();
  const [hasError, setHasError] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const state = location.state as LocationState;

  const onSubmit = useCallback(
    data => {
      const { confirmationCode, newPassword } = data;
      if (!state.patientId) {
        setHasError(true);
        throw new Error('No patient id');
      }
      try {
        Auth.forgotPasswordSubmit(state.patientId, confirmationCode, newPassword)
          .then(data => {
            navigate(paths.RESET_PASSWORD_CONFIRM);
          })
          .catch(err => {
            throw new Error(err);
          });
      } catch (err) {
        setHasError(true);
      }
    },
    [navigate, state.patientId],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" height="90%" justifyContent="space-between" flexDirection="column" p={2}>
        <Box
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '5vh',
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row' }}>
            <BackArrow />
          </Box>
          <Box style={{ marginBottom: '5vh' }}>
            <Typography
              align="center"
              fontSize="22px"
              fontFamily="Poppins Medium"
              style={{ marginBottom: '2vh' }}
            >
              <T id="reset-password.title" />
            </Typography>
          </Box>

          <Box style={{ margin: '1vh 0vh' }}>
            <TextField
              {...register('confirmationCode', { required: true })}
              placeholder={t({ id: 'reset-password.confirmation-code' })}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
            />
          </Box>
          {Boolean(errors.confirmationCode) && errors.confirmationCode.type && (
            <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
              <T id="reset-password.error-confirmation-code-required" />
            </Typography>
          )}

          <Box style={{ margin: '1vh 0' }}>
            <TextField
              {...register('newPassword', {
                required: t({
                  id: 'reset-password.error-new-password-required',
                }),
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
                  message: t({ id: 'reset-password.error-valid-password' }),
                },
              })}
              placeholder={t({ id: 'reset-password.new-password' })}
              fullWidth
              type="password"
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
            />
          </Box>
          {Boolean(errors.newPassword) && errors.newPassword.type && (
            <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
              {errors.newPassword?.message}
            </Typography>
          )}

          <Box style={{ margin: '1vh 0' }}>
            <TextField
              {...register('confirmPassword', {
                validate: {
                  passwordEqual: (value: string) =>
                    value === getValues().newPassword ||
                    t({ id: 'reset-password.error-password-match' }),
                },
              })}
              placeholder={t({ id: 'reset-password.confirm-password' })}
              fullWidth
              type="password"
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
            />
          </Box>
          {Boolean(errors.confirmPassword) && errors.confirmPassword.type && (
            <Typography marginLeft="1em" fontSize="12px" fontFamily="Poppins Regular" color="red">
              {errors.confirmPassword.message}
            </Typography>
          )}

          <T id="reset-password.reminder">
            {(text: string) => (
              <Box>
                {text.split('\n').map((line, i) => {
                  let marginLeft = '0';
                  if (i > 0) {
                    marginLeft = '10px';
                  }
                  return (
                    <Typography
                      key={line}
                      color="GrayText"
                      style={{ fontFamily: 'Poppins Regular', marginLeft }}
                    >
                      {line}
                    </Typography>
                  );
                })}
              </Box>
            )}
          </T>
        </Box>

        {hasError && (
          <Box sx={{ marginBottom: '1vh' }}>
            <Typography align="center" color="red" fontFamily="Poppins light">
              <T id="reset-password.something-went-wrong" />
            </Typography>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            align="center"
            style={{
              fontFamily: 'Poppins Regular',
              textDecoration: 'underline',
              marginBottom: '2vh',
            }}
            component={Link}
            to={paths.HOME_MENU}
          >
            <T id="reset-password.back-to-home" />
          </Typography>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
          >
            <T id="reset-password.next" />
          </Button>
        </Box>
      </Box>
    </form>
  );
};
