import { FetchQueryOptions, useMutation, useQueryClient } from 'react-query';
import { HeightWeight } from 'types/types';

export const getHeightWeightForPatient = async (patientId: string): Promise<HeightWeight[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/heightweight/user/${patientId}`).then(async response => {
    return response.json();
  });
};

export const getHeightWeightForPatientQuery = (
  patientId: string,
): FetchQueryOptions<HeightWeight[]> => ({
  queryKey: ['heightWeight', patientId],
  queryFn: async () => {
    const heightWeightResponse = await getHeightWeightForPatient(patientId);
    return heightWeightResponse;
  },
});

export const getHeightWeightForPatientByDate = async (
  patientId: string,
  startDate: Date,
  endDate: Date,
): Promise<HeightWeight[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/heightweight/by-date`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: patientId, startDate, endDate }),
  }).then(async response => {
    return response.json();
  });
};

export const getHeightWeightForPatientByDateQuery = (
  patientId: string,
  startDate: Date,
  endDate: Date,
): FetchQueryOptions<HeightWeight[]> => ({
  queryKey: ['heightWeightByDate', patientId, startDate, endDate],
  queryFn: async () => {
    const heightWeightResponse = await getHeightWeightForPatientByDate(
      patientId,
      startDate,
      endDate,
    );
    return heightWeightResponse;
  },
});

export const DeleteHeightWeightByIdMutation = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    (heightWeightId: string) => {
      const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
      return fetch(`${backendBaseUrl}/heightweight/${heightWeightId}`, {
        method: 'DELETE',
      });
    },
    {
      onMutate: async (heightWeightId: string) => {
        await queryClient.cancelQueries('heightWeight');
        const previousHeightWeightArray = queryClient.getQueryData<HeightWeight[]>('heightWeight');
        if (previousHeightWeightArray) {
          const newHeightWeight = previousHeightWeightArray.filter(
            heightWeight => heightWeight.id !== heightWeightId,
          );
          queryClient.setQueryData<HeightWeight[]>('heightWeight', { ...newHeightWeight });
        }
        return { previousHeightWeightArray };
      },
      onSettled: () => {
        queryClient.invalidateQueries('heightWeight');
      },
    },
  );
  return deleteMutation;
};
