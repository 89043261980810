import React, { FunctionComponent, useState } from 'react';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FormattedMessage } from 'react-intl';
import { colors, fontSize, theme } from 'theme';
import { SlideMenu } from 'components/SlideMenu';
import { makeStyles } from '@mui/styles';
import { getSpacing } from 'stylesheet';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
const useStyles = makeStyles({
  paper: {
    width: '100%',
  },
});

export const WordMarkHeader: FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  function handleClick() {
    navigate(paths.HOME_MENU);
  }
  return (
    <>
      <Box
        position="sticky"
        height={48}
        display="flex"
        alignContent={'flex-start'}
        mx={2}
        sx={{
          top: '0px',
          backgroundColor: colors.white,
          padding: '10px 0px',
          zIndex: 2,
        }}
      >
        <IconButton
          aria-label="menu"
          sx={{ color: colors.mediumPurple, left: '0px' }}
          onClick={() => setIsOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Box
          display="flex"
          flex={1}
          justifyContent={'center'}
          alignItems={'flex-start'}
          sx={{ pr: getSpacing(7) }}
        >
          <Typography
            className="cursor-pointer"
            sx={{
              alignSelf: 'center',
              color: colors.mediumPurple,
              display: 'inline-block',
              paddingLeft: '90px',
            }}
            fontFamily={theme.typography.fontFamily}
            fontSize={fontSize.medium}
            onClick={handleClick}
          >
            <img
              src="/logo/nutricia_logo.png"
              style={{ width: 100, position: 'absolute', right: '49%', top: '5%' }}
            ></img>
            <FormattedMessage id="header.title" />
          </Typography>
        </Box>
      </Box>
      <Box px={2} py={1}>
        {children}
      </Box>
      <Drawer
        classes={{ paper: classes.paper }}
        anchor={'left'}
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        <SlideMenu onClose={() => setIsOpen(false)} />
      </Drawer>
    </>
  );
};
