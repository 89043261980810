import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loader = (): JSX.Element => (
  <Box my={2} width={1} display={'flex'} justifyContent={'center'}>
    <CircularProgress />
  </Box>
);

export default Loader;
