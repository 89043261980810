export enum UserTypes {
  CAREGIVER = 'careGiver',
  PATIENT = 'patient',
}

export enum DietTypes {
  CLASSIC = 'classic',
  ATKINS = 'atkins',
  CATHETER = 'catheter',
  OTHER = 'other',
  ENTERAL = 'enteral',
  ORAL = 'oral',
}

export type UserState = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  patientId: string;
  cognitoId: string;
  dateOfBirth: string;
  userType: UserTypes;
  roles: string[];
  groups?: string[] | null;
  dietType: DietTypes;
  newDietNotifications: boolean;
  symptomFollowUpNotifications: boolean;
  newsNotifications: boolean;
  profilePicture?: string | null;
};
