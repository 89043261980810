/* eslint-disable max-lines */
module.exports = `<!DOCTYPE html>

<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="Generator" content="Microsoft Word 15 (filtered)" />
    <title>TÉRMINOS DE USO</title>

    <style>
      <!--
       /* Font Definitions */
       @font-face
      	{font-family:Wingdings;
      	panose-1:5 0 0 0 0 0 0 0 0 0;}
      @font-face
      	{font-family:"Cambria Math";
      	panose-1:2 4 5 3 5 4 6 3 2 4;}
      @font-face
      	{font-family:Calibri;
      	panose-1:2 15 5 2 2 2 4 3 2 4;}
      @font-face
      	{font-family:"Lucida Sans Unicode";
      	panose-1:2 11 6 2 3 5 4 2 2 4;}
      @font-face
      	{font-family:Tahoma;
      	panose-1:2 11 6 4 3 5 4 4 2 4;}
       /* Style Definitions */
       p.MsoNormal, li.MsoNormal, div.MsoNormal
      	{margin:0in;
      	font-size:12.0pt;
      	font-family:"Times New Roman",serif;}
      p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
      	{margin-top:0in;
      	margin-right:0in;
      	margin-bottom:6.0pt;
      	margin-left:0in;
      	font-size:12.0pt;
      	font-family:"Times New Roman",serif;}
      a:link, span.MsoHyperlink
      	{color:navy;
      	text-decoration:underline;}
      p.Contenidodelatabla, li.Contenidodelatabla, div.Contenidodelatabla
      	{mso-style-name:"Contenido de la tabla";
      	margin:0in;
      	font-size:12.0pt;
      	font-family:"Times New Roman",serif;}
      p.Default, li.Default, div.Default
      	{mso-style-name:Default;
      	margin:0in;
      	text-autospace:none;
      	font-size:12.0pt;
      	font-family:"Arial",sans-serif;
      	color:black;}
       /* Page Definitions */
       @page WordSection1
      	{size:595.25pt 841.85pt;
      	margin:56.7pt 56.7pt 56.7pt 56.7pt;}
      div.WordSection1
      	{page:WordSection1;}
       /* List Definitions */
       ol
      	{margin-bottom:0in;}
      ul
      	{margin-bottom:0in;}
      -->
    </style>
  </head>

  <body
    lang="EN-US"
    link="navy"
    vlink="#954F72"
    style="word-wrap:break-word;
line-break:strict"
  >
    <div class="WordSection1">
      <p class="MsoNormal" style="text-align:justify">
        <b
          ><u><span lang="ES-TRAD">TÉRMINOS DE USO</span></u></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify"><span lang="ES-TRAD">&nbsp;</span></p>

      <p class="Contenidodelatabla" style="margin-bottom:14.15pt;text-align:justify">
        <span lang="ES-TRAD"
          >Por favor, lea atentamente los presentes <strong>Términos de Uso</strong> antes de
          utilizar el sitio.<br />
          Cualquier utilización del presente sitio web está sujeta a estos Términos de Uso. Los
          Términos de Uso pueden ser corregidos, modificados o reemplazados por otros términos y
          condiciones en cualquier momento sin necesidad de comunicación alguna. La continuidad en
          el uso del sitio después que se haya realizado cualquier modificación de las presentes
          condiciones de uso significará la aceptación de la versión actualizada de los nuevos
          Términos de Uso. Por esta razón, encomendamos revisar estos Términos de Uso cada vez que
          utilice este sitio web.</span
        >
      </p>

      <p class="Contenidodelatabla" style="margin-bottom:14.15pt;text-align:justify">
        <b
          ><span lang="ES-TRAD"
            >1. Servicios<strong><br /> </strong></span></b
        ><span lang="ES-TRAD"
          >El sitio web “www.terapiacetogenica.com.ar” contiene información específica, como así
          también -dependiendo del caso- documentación relacionada para ser descargada. El titular
          de este sitio se reserva el derecho de cesar en la operación del mismo, parcial o
          totalmente.</span
        >
      </p>

      <p class="Contenidodelatabla" style="margin-bottom:14.15pt;text-align:justify">
        <b><span lang="ES-TRAD">2. Registración. Contraseña</span></b
        ><span lang="ES-TRAD"
          ><br />
          El titular se reserva el derecho de solicitar la registración del usuario para páginas que
          previamente fueron de libre acceso y, en tal caso, está facultado, en cualquier momento y
          sin expresión de causa, a denegar al usuario el acceso al área protegida por contraseñas,
          en particular si el usuario:</span
        >
      </p>

      <p class="Contenidodelatabla" style="margin-bottom:14.15pt;text-align:justify">
        <strong><span lang="ES-TRAD">(i)</span></strong
        ><span lang="ES-TRAD">
          Proporciona datos incorrectos con el fin de registrarse,<br />
          <br />
          <strong>(ii)</strong> Incumple estos Términos de Uso,<br />
          <br />
          <strong>(iii)</strong> Incumple cualquier normativa aplicable respecto del acceso o el uso
          del presente sitio web.</span
        >
      </p>

      <p class="Contenidodelatabla" style="margin-bottom:14.15pt;text-align:justify">
        <b
          ><span lang="ES-TRAD"
            >3. Uso de la información y documentación disponible en el sitio</span
          ></b
        ><span lang="ES-TRAD"
          ><br />
          El uso de cualquier información y/o documentación disponible en el sitio está sujeto a
          estos Términos de Uso. Los términos de licencias acordadas por separado tienen prioridad
          sobre estos Términos de Uso.<br />
          <br />
          La Información y la documentación en ningún momento podrán ser distribuidas por el usuario
          a cualquier tercero ni ser alquilados, licenciados o puestos a disposición de terceros de
          cualquier otra manera. A menos que lo contrario sea permitido por normas de orden público,
          el usuario no modificará ni desmontará la documentación disponible en el sitio.<br />
          <br />
          La información y la documentación disponibles en el sitio se encuentran protegidos por las
          leyes y tratados que tutelan los Derechos de Autor y otras convenciones relativas a la
          Propiedad Intelectual. El usuario observará y cumplirá dicha normativa y, en particular,
          no modificará, ocultará ni removerá de la información, de la documentación o sus copias,
          marcas o avisos de copyright.<strong
            ><br />
            <br /> </strong
          ><b>4. Derechos de Autor y Propiedad Industrial</b><br />
          Los materiales provistos en este sitio, incluyendo todo el </span
        ><span lang="ES-TRAD"
          >contenido, información, fotografías, ilustraciones, gráficos, nombres y logos, son de
          propiedad de Advanced Medical Nutrition S.A. y SHS International LTD (en adelante los
          “Titulares”) y están protegidos por Derechos</span
        ><span lang="ES-TRAD">
          de Autor, la legislación marcaria y otros derechos de propiedad industrial. Los materiales
          son únicamente para uso personal y no comercial. Los contenidos del sitio web descriptos
          arriba no pueden ser cambiados, copiados, reproducidos, vendidos, alquilados, licenciados,
          usados, ampliados o utilizados de cualquier forma sin el previo consentimiento escrito del
          titular de los derechos.<br />
          <br />
          Nada de lo expuesto en los presentes Términos de Uso será considerado como la concesión,
          tácita o de otra manera, de ninguna licencia o derecho a usar ninguna patente, marca o
          ningún otro derecho de propiedad industrial de los Titulares<span style="color:black"
            >.
          </span>
          Tampoco el presente concede ninguna licencia o derecho a usar cualquiera de los Derechos
          de Autor u otros derechos relativos a los materiales mencionados arriba.<br />
          <br />
          La denominación y diseños de los productos expuestos en el Sitio  y/o de sus marcas
          asociadas, así como sus ilustraciones y logos son propiedad de los Titulares, en
          consecuencia, cualquier  uso de la denominación y de las marcas requiere autorización
          expresa de los mismos.</span
        >
      </p>

      <p class="MsoNormal" style="margin-bottom:14.15pt;text-align:justify">
        <b><span lang="ES-TRAD">5. Deberes del usuario</span></b
        ><span lang="ES-TRAD"
          ><br />
          Al acceder o utilizar el Sitio el usuario se obliga a:<strong
            ><br />
            <br />
            (i)</strong
          >
          no dañar otras personas o infringir sus derechos individuales;<br />
          <strong
            ><br />
            (ii)</strong
          >&nbsp;no infringir cualquier derecho de propiedad intelectual o de algún otro tipo;</span
        >
      </p>

      <p class="Contenidodelatabla" style="margin-bottom:14.15pt;text-align:justify">
        <strong><span lang="ES-TRAD">(iii) </span></strong
        ><strong
          ><span
            lang="ES-TRAD"
            style="font-weight:
normal"
            >no</span
          ><span lang="ES-TRAD"> </span></strong
        ><span lang="ES-TRAD"
          >introducir cualquier contenido que contenga un virus, los denominados troyanos, o
          cualquier otro programa que puede dañar datos;</span
        >
      </p>

      <p class="Contenidodelatabla" style="text-align:justify">
        <strong><span lang="ES-TRAD">(iv) </span></strong
        ><strong
          ><span
            lang="ES-TRAD"
            style="font-weight:
normal"
            >no
          </span></strong
        ><span lang="ES-TRAD"
          >transmitir, almacenar o descargar cualquier link o contenido al cual el usuario no esté
          facultado, en particular, en casos donde los mencionados links o contenido esté
          incumpliendo con obligaciones de confidencialidad o sean ilícitos;<br />
          <br />
          <strong>(v)</strong> no distribuir publicidad o correos electrónicos no requeridos
          (denominado &quot;spam&quot;) o advertencias imprecisas sobre virus, defectos o
          circunstancias similares;<br />
          <br />
          <strong>(vi)</strong> no violar el orden público en la manera de uso del Sitio.<br />
          <br />
          En cualquier momento, el titular puede denegar el acceso al Sitio cuando los Términos de
          Uso sean violados, en particular, si el usuario está incumpliendo con los deberes aquí
          descriptos.<br />
          <br />
          <b
            >6. Deberes y obligaciones del titular del Sitio (virus/protección de la privacidad de
            los datos)<br /> </b
          >Aunque los Titulares hacen todos los esfuerzos para mantener su Sitio web libre de virus,
          no pueden garantizar ello. Para su propia protección, el usuario deberá tomar los recaudos
          necesarios para asegurar las medidas apropiadas de seguridad y utilizando un scanner de
          virus antes de bajar cualquier información, software o documentación.<br />
          <br />
          Por recolectar, utilizar y procesar datos personales de usuarios, los titulares cumplen
          con las regulaciones aplicables respecto de la protección de la privacidad de los datos y
          cuentan con una política de protección de la privacidad de los datos, disponible en el
          Sitio bajo el nombre de &quot;Política de Privacidad&quot;.<br />
          <br />
          <b>7. Links externos</b><br />
          Este sitio puede contener Links a otros sitios Web u otras fuentes de información de
          Internet. Estos Links son provistos al solo beneficio del usuario y el hecho de facilitar
          el acceso no será entendido como aval, sponsoreo o recomendación del titular.&nbsp; El
          titular no tendrá responsabilidad alguna por cualquier contenido, servicios o productos
          disponibles a través de fuentes de terceros. El titular no controla la información de
          dichas páginas Web. El uso de estos sitios será a riesgo exclusivo del usuario.<br />
          <br />
          <b>8. Exención de responsabilidad</b><br />
          El Sitio y el contenido disponible en el mismo son provistos en el estado en que se
          encuentran y en la forma en que son provistos. Los Usuarios expresamente reconocen que el
          uso del Sitio y/o su contenido son a su solo riesgo y reconocen y aceptan que serán los
          únicos responsables por cualquier daño a su persona, empresa, terceros, a sistema de
          computación o pérdida de datos que deriven de bajar y/o hacer uso de esos contenidos
          y/o&nbsp; datos.<br />
          <br />
          Salvo acuerdo por escrito, los Titulares no asumen responsabilidad y no otorgan ningún
          tipo de garantía sobre la precisión, actualidad, integridad, confiabilidad o utilidad de
          cualquier idea, opinión, declaración, cualquier otro contenido o cualquier producto o
          servicio distribuido o puesto a disposición por sí, por colaboradores,  u otros terceros a
          través del Sitio. Los titulares no otorgan ninguna garantía de que el Sitio o su contenido
          cumplan con vuestros requerimientos, o que el Sitio o su contenido van a ser
          ininterrumpidos, oportunos, seguros, libres de errores o que, existiendo algún defecto,
          será corregido. Los titulares tampoco otorgan garantía alguna respecto del resultado que
          puede obtenerse de usar el Sitio o su contenido, o sobre la precisión, integridad o
          confiabilidad de cualquier información obtenida a través del uso del Sitio.<br />
          <br />
          Los Titulares no asumen responsabilidad alguna por cualquier daño sufrido por un usuario,
          incluyendo, sin limitación, la pérdida de datos por retrasos o falta de entrega de
          contenidos, errores de e-mails, bloqueo de sistemas, apagado de redes o sistemas,
          modificación de archivos o interrupciones de servicios causados por negligencia o cuando
          se deban a errores propios u omisiones del usuario.</span
        >
      </p>

      <p class="Contenidodelatabla" style="text-align:justify">
        <span lang="ES-TRAD">&nbsp;</span>
      </p>

      <p class="Default" style="text-align:justify">
        <span
          lang="ES-AR"
          style='font-family:
"Times New Roman",serif'
          >Los titulares no aseguran garantía de confidencialidad alguna respecto de la información
          transmitida a través del Sitio en el presente o en el futuro.<br />
          <br />
          <b>9. Acuerdos Complementarios, Jurisdicción y Derecho Aplicable</b><br />
          Cualquier acuerdo complementario requiere que se realice en forma escrita. El usuario
          acuerda someterse por cualquier divergencia respecto del uso del sitio y/o sus contenidos
          a los Tribunales Ordinarios de la Ciudad Autónoma de Buenos Aires.<br />
          <br />
          <b>10. Consultas/Contáctenos</b><br />
          Si Ud. tiene alguna consulta sobre estos Términos de Uso del sitio o respecto del manejo
          del mismo, por favor contáctese a
          <a href="mailto:terapiaparaepilepsia@danone.com">terapiaparaepilepsia@danone.com</a>
        </span>
      </p>

      <p class="Contenidodelatabla"><span lang="ES-TRAD" style="color:red">&nbsp;</span></p>

      <p class="Contenidodelatabla"><span lang="ES-TRAD">&nbsp;</span></p>

      <p class="MsoBodyText"><span lang="ES-TRAD">&nbsp;</span></p>

      <p class="MsoBodyText"><span lang="ES-TRAD">&nbsp;</span></p>

      <p class="Contenidodelatabla"><span lang="ES-TRAD">&nbsp;</span></p>
    </div>
  </body>
</html>
`;
