import Clear from '@mui/icons-material/Clear';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import Terms from 'assets/termsOfUse';
import React, { FunctionComponent } from 'react';
import { fontSize } from 'theme';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsModal: FunctionComponent<TermsModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: '75%',
          boxShadow: 24,
          p: 1,
        }}
      >
        <Box width={1} display="flex" justifyContent="flex-end">
          <IconButton sx={{ right: 0 }} onClick={onClose}>
            <Clear />
          </IconButton>
        </Box>
        <Box height="70vh" overflow="scroll" px={2} pb={2}>
          <Typography
            align="justify"
            fontSize={fontSize.XSmall}
            fontFamily="Poppins Regular"
            style={{ margin: '2vw' }}
            // eslint-disable-next-line risxss/catch-potential-xss-react
            dangerouslySetInnerHTML={{ __html: Terms }}
          ></Typography>
        </Box>
      </Box>
    </Modal>
  );
};
