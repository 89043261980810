import { WordMarkHeader } from 'components/WordMarkHeader';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getUserToken } from 'redux/Login';
import { paths } from 'routes';

export const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();
  const userToken = useSelector(getUserToken);

  if (!Boolean(userToken)) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (location.pathname === paths.LOGIN) {
    return <Navigate to={paths.HOME_MENU} state={{ from: location }} replace />;
  }

  return <WordMarkHeader>{children}</WordMarkHeader>;
};
