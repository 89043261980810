import { Box, Button, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { HistoryCard } from 'components/HistoryCard/HistoryCard';
import { getMoodsForPatientQuery } from 'dataLayer/mood';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import { colors } from 'theme';
import { Mood } from 'types/types';
import ReactGA from 'react-ga';

export const MoodHistory: FunctionComponent = () => {
  const userId = useSelector(state => state.user.id);
  const navigate = useNavigate();
  const { data: moodList } = useQuery(getMoodsForPatientQuery(userId));

  const moodPageWrapper = useRef<HTMLDivElement>(null);

  const [appWidth, setAppWidth] = useState(0);

  const getAppWidth = () => {
    if (moodPageWrapper.current) setAppWidth(moodPageWrapper?.current?.offsetWidth);
  };

  useEffect(() => {
    getAppWidth();
    window.addEventListener('resize', getAppWidth);
  }, []);

  const navigateToRecord = useCallback(() => {
    ReactGA.event({
      category: 'ButtonClick',
      action: 'Record Mood From History Page',
    });
    navigate(paths.RECORD_MOOD);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        mb: 6,
      }}
      ref={moodPageWrapper}
    >
      <Box style={{ display: 'flex' }}>
        <Box style={{ flex: 1 }}>
          <BackArrow />
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography
            fontFamily="Poppins Medium"
            fontSize="18px"
            style={{ color: colors.darkPurple }}
          >
            <FormattedMessage id="mood-history.title" />
          </Typography>
        </Box>
        <Box style={{ flex: 1 }}></Box>
      </Box>
      {moodList &&
        moodList.map((mood: Mood) => <HistoryCard key={mood.id} historyType="mood" data={mood} />)}
      <Box
        position="fixed"
        height={48}
        bottom={0}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={navigateToRecord}
          style={{
            color: colors.white,
            backgroundColor: colors.darkPurple,
            width: `${appWidth}px`,
          }}
        >
          <FormattedMessage id="mood-history.add-mood-record" />
        </Button>
      </Box>
    </Box>
  );
};
