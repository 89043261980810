/* eslint-disable max-lines */
import { RequireAuth } from 'components/RequireAuth';
import { AddRecord } from 'pages/AddRecord';
import { CrisisHistory } from 'pages/CrisisHistory';
import { CrisisLogged } from 'pages/CrisisLogged';
import { Data } from 'pages/Data';
import { Diet } from 'pages/Diet';
import { EmailValidation } from 'pages/EmailValidation';
import { HistoryRecord } from 'pages/HistoryRecord';
import { KetonesHistory } from 'pages/KetonesHistory';
import { KetonesLogged } from 'pages/KetonesLogged';
import { Login } from 'pages/Login';
import { ProfileSettings } from 'pages/ProfileSettings';
import { RecipeDetails } from 'pages/RecipeDetails';
import { RecipeSelection } from 'pages/RecipeSelection';
import { RecordCrisis } from 'pages/RecordCrisis';
import { RecordDetails } from 'pages/RecordDetails';
import { RecordKetones } from 'pages/RecordKetones';
import { RecordSleep } from 'pages/RecordSleep';
import { RecordSymptoms } from 'pages/RecordSymptoms';
import { RecordMood } from 'pages/RecordMood';
import { ResetPasswordConfirmation } from 'pages/ResetPasswordConfirmation';
import { ResetPasswordForm } from 'pages/ResetPasswordForm';
import { ResetPasswordInit } from 'pages/ResetPasswordInit';
import { Signup } from 'pages/Signup';
import { SleepHistory } from 'pages/SleepHistory';
import { SleepLogged } from 'pages/SleepLogged';
import { SymptomHistory } from 'pages/SymptomHistory';
import { SymptomsLogged } from 'pages/SymptomLogged';
import { TermsAndConditions } from 'pages/TermsAndConditions';
import { UnregisteredUser } from 'pages/UnregisteredUser';
import { HomeMenu } from 'pages/HomeMenu';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import { RecordHeightWeight } from 'pages/RecordHeightWeight';
import { HeightWeightLogged } from 'pages/HeightWeightLogged';
import { HeightWeightHistory } from 'pages/HeightWeightHistory';
import { MoodLogged } from 'pages/MoodLogged';
import { MoodHistory } from 'pages/MoodHistory';
import { PatientSupportProgram } from 'pages/PatientSupportProgram';
import { DataPdf } from 'pages/DataPdf';

const LOGIN = '/';
const CONFIRM = 'confirm';
const CRISIS = 'crisis';
const DIET = 'diet';
const SYMPTOMS = 'symptoms';
const EMAIL_VALIDATION = 'email-validation';
const HISTORY = 'history';
const KETONES = 'ketones';
const LOGGED = 'logged';
const MENU = 'menu';
const PROFILE_SETTINGS = 'profile-settings';
const RECIPE_DETAILS = 'recipe-details';
const RECIPE_ID = ':recipeId';
const RECORD = 'record';
const RESET_PASSWORD = 'reset-password';
const SIGNUP = 'signup';
const SLEEP = 'sleep';
const MOOD = 'mood';
const TERMS = 'terms';
const UNREGISTERED_USER = 'unregistered-user';
const WELCOME = 'welcome';
const DETAILS = 'details';
const FORM = 'form';
const RECIPE_SELECTION = 'recipe-selection';
const HOME_MENU = 'home-menu';
const DATA = 'data';
const PDF = 'pdf';
const HEIGHTWEIGHT = 'height-weight';
const PATIENT_SUPPORT_PROGRAM = 'patient-support-program';

export const paths = {
  LOGIN,
  DIET: ['', DIET].join('/'),
  EMAIL_VALIDATION: ['', EMAIL_VALIDATION].join('/'),
  HISTORY: ['', HISTORY].join('/'),
  CRISIS_HISTORY: ['', HISTORY, CRISIS].join('/'),
  SLEEP_HISTORY: ['', HISTORY, SLEEP].join('/'),
  SYMPTOM_HISTORY: ['', HISTORY, SYMPTOMS].join('/'),
  KETONES_HISTORY: ['', HISTORY, KETONES].join('/'),
  HEIGHT_WEIGHT_HISTORY: ['', HISTORY, HEIGHTWEIGHT].join('/'),
  MOOD_HISTORY: ['', HISTORY, MOOD].join('/'),
  LOGGED_CRISIS: ['', RECORD, LOGGED, CRISIS].join('/'),
  LOGGED_SLEEP: ['', RECORD, LOGGED, SLEEP].join('/'),
  LOGGED_KETONES: ['', RECORD, LOGGED, KETONES].join('/'),
  LOGGED_SYMPTOMS: ['', RECORD, LOGGED, SYMPTOMS].join('/'),
  LOGGED_HEIGHT_WEIGHT: ['', RECORD, LOGGED, HEIGHTWEIGHT].join('/'),
  LOGGED_MOOD: ['', RECORD, LOGGED, MOOD].join('/'),
  MENU: ['', MENU].join('/'),
  PROFILE_SETTINGS: ['', PROFILE_SETTINGS].join('/'),
  RECIPE: ['', DIET, RECIPE_DETAILS, RECIPE_ID].join('/'),
  RECIPE_SELECTION: ['', DIET, RECIPE_SELECTION].join('/'),
  RECORD: ['', RECORD].join('/'),
  RECORD_CRISIS: ['', RECORD, CRISIS].join('/'),
  RECORD_SLEEP: ['', RECORD, SLEEP].join('/'),
  RECORD_KETONES: ['', RECORD, KETONES].join('/'),
  RECORD_SYMPTOMS: ['', RECORD, SYMPTOMS].join('/'),
  RECORD_HEIGHT_WEIGHT: ['', RECORD, HEIGHTWEIGHT].join('/'),
  RECORD_MOOD: ['', RECORD, MOOD].join('/'),
  RESET_PASSWORD: ['', RESET_PASSWORD].join('/'),
  RESET_PASSWORD_FORM: ['', RESET_PASSWORD, FORM].join('/'),
  RESET_PASSWORD_CONFIRM: ['', RESET_PASSWORD, CONFIRM].join('/'),
  SIGNUP: ['', SIGNUP].join('/'),
  TERMS: ['', TERMS].join('/'),
  UNREGISTERED_USER: ['', UNREGISTERED_USER].join('/'),
  WELCOME: ['', WELCOME].join('/'),
  DETAILS: ['', DETAILS].join('/'),
  HOME_MENU: ['', HOME_MENU].join('/'),
  DATA: ['', DATA].join('/'),
  DATA_PDF: ['', DATA, PDF].join('/'),
  PATIENT_SUPPORT_PROGRAM: ['', PATIENT_SUPPORT_PROGRAM].join('/'),
};

const AppRoutes = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <Routes>
      <Route path="/">
        <Route index element={<Login />} />
        <Route path={RECORD}>
          <Route
            index
            element={
              <RequireAuth>
                <AddRecord />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECORD_CRISIS}
            element={
              <RequireAuth>
                <RecordCrisis />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECORD_SLEEP}
            element={
              <RequireAuth>
                <RecordSleep />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECORD_KETONES}
            element={
              <RequireAuth>
                <RecordKetones />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECORD_SYMPTOMS}
            element={
              <RequireAuth>
                <RecordSymptoms />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECORD_HEIGHT_WEIGHT}
            element={
              <RequireAuth>
                <RecordHeightWeight />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECORD_MOOD}
            element={
              <RequireAuth>
                <RecordMood />
              </RequireAuth>
            }
          />
          <Route
            path={paths.LOGGED_CRISIS}
            element={
              <RequireAuth>
                <CrisisLogged />
              </RequireAuth>
            }
          />
          <Route
            path={paths.LOGGED_SLEEP}
            element={
              <RequireAuth>
                <SleepLogged />
              </RequireAuth>
            }
          />
          <Route
            path={paths.LOGGED_KETONES}
            element={
              <RequireAuth>
                <KetonesLogged />
              </RequireAuth>
            }
          />
          <Route
            path={paths.LOGGED_SYMPTOMS}
            element={
              <RequireAuth>
                <SymptomsLogged />
              </RequireAuth>
            }
          />
          <Route
            path={paths.LOGGED_HEIGHT_WEIGHT}
            element={
              <RequireAuth>
                <HeightWeightLogged />
              </RequireAuth>
            }
          />
          <Route
            path={paths.LOGGED_MOOD}
            element={
              <RequireAuth>
                <MoodLogged />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={DIET}>
          <Route
            index
            element={
              <RequireAuth>
                <Diet />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECIPE_SELECTION}
            element={
              <RequireAuth>
                <RecipeSelection />
              </RequireAuth>
            }
          />
          <Route
            path={paths.RECIPE}
            element={
              <RequireAuth>
                <RecipeDetails />
              </RequireAuth>
            }
          />
        </Route>
        <Route path={EMAIL_VALIDATION}>
          <Route index element={<EmailValidation />} />
        </Route>
        <Route
          path={paths.HOME_MENU}
          element={
            <RequireAuth>
              <HomeMenu />
            </RequireAuth>
          }
        />
        <Route
          path={PROFILE_SETTINGS}
          element={
            <RequireAuth>
              <ProfileSettings />
            </RequireAuth>
          }
        />
        <Route path={HISTORY}>
          <Route
            index
            element={
              <RequireAuth>
                <HistoryRecord />
              </RequireAuth>
            }
          />
          <Route
            path={paths.CRISIS_HISTORY}
            element={
              <RequireAuth>
                <CrisisHistory />
              </RequireAuth>
            }
          />
          <Route
            path={paths.SLEEP_HISTORY}
            element={
              <RequireAuth>
                <SleepHistory />
              </RequireAuth>
            }
          />
          <Route
            path={paths.SYMPTOM_HISTORY}
            element={
              <RequireAuth>
                <SymptomHistory />
              </RequireAuth>
            }
          />
          <Route
            path={paths.KETONES_HISTORY}
            element={
              <RequireAuth>
                <KetonesHistory />
              </RequireAuth>
            }
          />
          <Route
            path={paths.HEIGHT_WEIGHT_HISTORY}
            element={
              <RequireAuth>
                <HeightWeightHistory />
              </RequireAuth>
            }
          />
          <Route
            path={paths.MOOD_HISTORY}
            element={
              <RequireAuth>
                <MoodHistory />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path={paths.DATA}
          element={
            <RequireAuth>
              <Data />
            </RequireAuth>
          }
        />
        <Route
          path={paths.DATA_PDF}
          element={
            <RequireAuth>
              <DataPdf />
            </RequireAuth>
          }
        />
        <Route path={paths.PATIENT_SUPPORT_PROGRAM} element={<PatientSupportProgram />} />
        <Route path={paths.SIGNUP} element={<Signup />} />
        <Route path={TERMS} element={<TermsAndConditions />} />
        <Route path={RESET_PASSWORD}>
          <Route index element={<ResetPasswordInit />} />
          <Route path={paths.RESET_PASSWORD_FORM} element={<ResetPasswordForm />} />
          <Route path={paths.RESET_PASSWORD_CONFIRM} element={<ResetPasswordConfirmation />} />
        </Route>
        <Route path={UNREGISTERED_USER} element={<UnregisteredUser />} />
        <Route
          path={DETAILS}
          element={
            <RequireAuth>
              <RecordDetails />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  </Suspense>
);

export default AppRoutes;
