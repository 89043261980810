import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueryClient } from 'react-query';

export type LoginState = Readonly<{
  token: string | null;
  queryClient: QueryClient;
  ketoCalcToken: string;
}>;
const queryClient = new QueryClient();
const initialState: LoginState = { token: null, queryClient, ketoCalcToken: '' };

const loginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    userLoggedIn: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    userLoggedOut: () => initialState,
    setKetoCalcToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        ketoCalcToken: action.payload,
      };
    },
  },
});

export const { userLoggedIn, userLoggedOut, setKetoCalcToken } = loginSlice.actions;
export default loginSlice.reducer;
