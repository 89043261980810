import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DietTypes, UserTypes } from 'redux/User/types';
import { SignupState } from './types';

interface SignupFormValues {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  patientId: number;
  dateOfBirth: Date;
}

interface UserTypeFormValues {
  userType: UserTypes;
}

interface DietTypeFormValues {
  dietType: DietTypes;
}

interface NotificationFormValues {
  newDietNotifications: boolean;
  symptomFollowUpNotifications: boolean;
  newsNotifications: boolean;
}

const initialState: SignupState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  patientId: 0,
  dateOfBirth: new Date(),
  userType: UserTypes.PATIENT,
  dietType: DietTypes.CLASSIC,
  newDietNotifications: true,
  symptomFollowUpNotifications: true,
  newsNotifications: true,
};

const SignupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    signupFormSubmit: (state, action: PayloadAction<SignupFormValues>) => ({
      ...state,
      ...action.payload,
    }),
    userTypeFormSubmit: (state, action: PayloadAction<UserTypeFormValues>) => ({
      ...state,
      ...action.payload,
    }),
    dietTypeFormSubmit: (state, action: PayloadAction<DietTypeFormValues>) => ({
      ...state,
      ...action.payload,
    }),
    notificationFormSubmit: (state, action: PayloadAction<NotificationFormValues>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {
  signupFormSubmit,
  userTypeFormSubmit,
  dietTypeFormSubmit,
  notificationFormSubmit,
} = SignupSlice.actions;
export default SignupSlice.reducer;
