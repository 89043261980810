import { Box } from '@mui/material';
import Loader from 'components/Loader';
import { getPatientMacroGoalsQuery } from 'dataLayer/user';
import { useFetchRecipesForDate } from 'pages/Diet/hooks';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getKetoCalcToken } from 'redux/Login';
import { NutrientRatio } from '.';

interface DailyMacrosProps {
  additionalFats?: number;
  additionalCarbs?: number;
  additionalProtein?: number;
}

export const DailyMacros: FunctionComponent<DailyMacrosProps> = ({
  additionalFats = 0,
  additionalCarbs = 0,
  additionalProtein = 0,
}) => {
  const ketoCalcToken = useSelector(getKetoCalcToken);
  const pid = sessionStorage.getItem('patientIds') || '0';
  const { data: macroGoals, isLoading } = useQuery(
    getPatientMacroGoalsQuery(
      // TODO: change patientId to actual patientId from user
      parseInt(pid),
      ketoCalcToken,
    ),
  );
  const { currentMacros } = useFetchRecipesForDate();

  if (!isLoading && macroGoals !== undefined) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          m: 2,
        }}
      >
        <NutrientRatio
          current={currentMacros.fats + additionalFats}
          target={macroGoals?.fats}
          abbreviation={'diet.fat-abbr'}
        />
        <NutrientRatio
          current={currentMacros.carbs + additionalCarbs}
          target={macroGoals?.carbs}
          abbreviation={'diet.carb-abbr'}
        />
        <NutrientRatio
          current={currentMacros.protein + additionalProtein}
          target={macroGoals?.protein}
          abbreviation={'diet.protein-abbr'}
        />
      </Box>
    );
  }
  return <Loader />;
};
