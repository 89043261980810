import LoadingButton from '@mui/lab/LoadingButton';
import { Box, FormControl, FormControlLabel, RadioGroup, Switch, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { BackArrow } from 'components/BackArrow';
import dayjs from 'dayjs';
import React, { FunctionComponent, useState, useCallback } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSignupValues } from 'redux/Signup';
import { paths } from 'routes';
import client from 'services/networking/client';

interface IProps {
  goBackOneStep: () => void;
}

export const NotificationSettingsForm: FunctionComponent<IProps> = ({ goBackOneStep }) => {
  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();
  const formData = useSelector(getSignupValues);
  const [state, setState] = useState({
    newDietNotifications: true,
    symptomFollowUpNotifications: true,
    newsNotifications: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = useCallback(async () => {
    const { firstName, lastName, email, patientId, password, dietType, userType } = formData;
    const { newDietNotifications, symptomFollowUpNotifications, newsNotifications } = state;
    const dateOfBirth = dayjs(formData.dateOfBirth).format('YYYY-MM-DD HH:mm:ss.000');
    setIsLoading(true);
    try {
      const cognitoResult = await Auth.signUp({
        username: patientId.toString(),
        password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
          email: email,
          'custom:patient_id': patientId,
        },
      }).catch(error => {
        throw new Error(error);
      });

      await client
        .post('/users', {
          firstName,
          lastName,
          email,
          cognitoId: cognitoResult.userSub,
          patientId: parseInt(patientId.toString()), // inexplicably causes error otherwise
          dateOfBirth,
          dietType,
          userType,
          newDietNotifications,
          symptomFollowUpNotifications,
          newsNotifications,
        })
        .catch(error => {
          throw new Error(error);
        });
      navigate(paths.EMAIL_VALIDATION, { state: { patientId } });
    } catch (error) {
      console.log('Error saving or signing up:', error);
      setIsLoading(false);
    }
  }, [state, formData, navigate]);

  return (
    <Box
      style={{
        display: 'flex',
        height: '90vh',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row' }}>
          <BackArrow />
        </Box>
        <Box style={{ paddingTop: '5vh' }}>
          <Typography
            align="center"
            fontSize="22px"
            fontFamily="Poppins Medium"
            style={{ marginBottom: '2vh' }}
          >
            <T id="profile-settings.title" />
          </Typography>
        </Box>
        <Box style={{ margin: '0 10vw' }}>
          <Typography align="left" fontSize="18px" fontFamily="Poppins Medium">
            <T id="profile-settings.notifications" />
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.newDietNotifications}
                    onChange={handleChange}
                    name="newDietNotifications"
                  />
                }
                label={t({ id: 'profile-settings.new-diet-notifications' })}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.symptomFollowUpNotifications}
                    onChange={handleChange}
                    name="symptomFollowUpNotifications"
                  />
                }
                label={t({ id: 'profile-settings.symptoms-follow-up-notifications' })}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.newsNotifications}
                    onChange={handleChange}
                    name="newsNotifications"
                  />
                }
                label={t({ id: 'profile-settings.news-notifications' })}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Typography
          align="center"
          style={{
            fontFamily: 'Poppins Regular',
            textDecoration: 'underline',
            marginBottom: '2vh',
          }}
          onClick={() => {
            navigate('/');
          }}
        >
          <T id="profile-settings.back-to-home" />
        </Typography>
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          loading={isLoading}
          style={{ height: '45px', width: '100%' }}
        >
          <T id="profile-settings.finish" />
        </LoadingButton>
      </Box>
    </Box>
  );
};
