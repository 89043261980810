/* eslint-disable complexity */
/* eslint-disable max-lines */
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from '@mui/material';

import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { getHeightWeightForPatientByDateQuery } from 'dataLayer/heightweight';
import { useQuery } from 'react-query';
import useSelector from 'redux/useSelector';
import { HeightOverTime } from 'components/DataVisualization/HeightOverTime';
import { jsPDF } from 'jspdf';
import { VictoryContainer } from 'victory';
import { WeightOverTime } from 'components/DataVisualization/WeightOverTime';
import { CrisisOverTime } from 'components/DataVisualization/CrisisOverTime';
import { getCrisesForPatientByDateQuery } from 'dataLayer/crises';
import { CrisisTypes } from 'components/DataVisualization/CrisisTypes';
import { CrisisVsKetonesOverTime } from 'components/DataVisualization/CrisisVsKetonesOverTime';
import { getKetonesForPatientByDateQuery } from 'dataLayer/ketones';
import { MoodOverTime } from 'components/DataVisualization/MoodOverTime/MoodOverTime';
import { getMoodForPatientByDateQuery } from 'dataLayer/mood';
import { FormattedMessage, FormattedMessage as T, useIntl } from 'react-intl';
import { AlignHorizontalCenter } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Crisis, Ketone } from 'types/types';
import { useCrises } from 'const/crisisTypes';
import { useKetones } from 'const/ketoneTypes';
import { fontSize, colors as colorss } from 'theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { paths } from 'routes';
interface LocationState {
  startDate: Date;
  endDate: Date;
}

export const DataPdf: FunctionComponent = () => {
  const userId = useSelector(state => state.user.id);
  //const userId = '830f49ad-4c05-410f-b0a2-b95ef08e52c9';
  const patientId = useSelector(state => state.user.patientId);
  const dn = new Date();
  const todaydate =
    dn.getFullYear() +
    '' +
    dn.getMonth() +
    '' +
    dn.getDate() +
    '' +
    dn.getHours() +
    '' +
    dn.getMinutes();
  const filename = 'KetoApp-' + patientId + '_' + todaydate + '.pdf';
  const currentDate = useMemo(() => new Date(), []);
  const oneMonthAgo = useMemo(
    () => new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate()),
    [currentDate],
  );
  const fullName =
    useSelector(state => state.user.firstName) + ` ` + useSelector(state => state.user.lastName);

  const location = useLocation();

  const { startDate, endDate } = (location.state as LocationState) ?? { oneMonthAgo, currentDate };
  const startd = new Date(startDate);
  const mm = startd.getMonth() + 1;
  const dd = startd.getDate();
  const yy = startd.getFullYear();
  const startDateString = dd + '-' + mm + '-' + yy;
  const endd = new Date(endDate);
  const mm1 = endd.getMonth() + 1;
  const dd1 = endd.getDate();
  const yy1 = endd.getFullYear();
  const endDateString = dd1 + '-' + mm1 + '-' + yy1;

  const intl = useIntl();

  const [heightChartSelected, setHeightChartSelected] = useState(true);
  const [weightChartSelected, setWeightChartSelected] = useState(true);
  const [crisisOverTimeSelected, setCrisisOverTimeSelected] = useState(true);
  const [crisisTypeChartSelected, setCrisisTypeChartSelected] = useState(true);
  const [crisisVsKetonesChartSelected, setCrisisVsKetonesChartSelected] = useState(true);
  const [moodChartSelected, setMoodChartSelected] = useState(true);

  const handleHeightChartChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHeightChartSelected(event.target.checked);
  }, []);

  const handleWeightChartChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setWeightChartSelected(event.target.checked);
  }, []);

  const handleCrisisOverTimeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCrisisOverTimeSelected(event.target.checked);
  }, []);

  const handleCrisisTypeChartChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCrisisTypeChartSelected(event.target.checked);
  }, []);

  const handleCrisisVsKetonesChartChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCrisisVsKetonesChartSelected(event.target.checked);
    },
    [],
  );

  const handleMoodChartChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMoodChartSelected(event.target.checked);
  }, []);

  const { data: heightWeightList } = useQuery(
    getHeightWeightForPatientByDateQuery(userId, startDate, endDate),
  );

  const { data: moodList } = useQuery(getMoodForPatientByDateQuery(userId, startDate, endDate));

  const { data: crisisList } = useQuery(getCrisesForPatientByDateQuery(userId, startDate, endDate));

  const { data: ketonesList } = useQuery(
    getKetonesForPatientByDateQuery(userId, startDate, endDate),
  );

  const getNumberOfDays = useCallback(() => {
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();
    if (diffInMilliseconds < 0) {
      return 0;
    }
    return Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }, [endDate, startDate]);

  const [weightChartRef, setWeightChartRef] = useState<any>(null);
  const [heightChartRef, setHeightChartRef] = useState<any>(null);
  const [crisesOverTimeRef, setCrisesOverTimeRef] = useState<any>(null);
  const [crisisTypesRef, setCrisisTypesRef] = useState<any>(null);
  const [crisesVsKetonesRef, setCrisesVsKetonesRef] = useState<any>(null);
  const [moodChartRef, setMoodChartRef] = useState<any>(null);

  const addBackgroundRect = (node: any) => {
    const backgroundRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    backgroundRect.setAttributeNS('http://www.w3.org/2000/svg', 'fill', 'white');
    backgroundRect.setAttributeNS('http://www.w3.org/2000/svg', 'width', '100%');
    backgroundRect.setAttributeNS('http://www.w3.org/2000/svg', 'height', '100%');
    node.prepend(backgroundRect);
  };

  const ketoColors = {
    noneSelected: '#ffffff',
    negative: '#fcf7d9',
    trace: '#fcf0d6',
    small: '#feddd6',
    moderate: '#f8c2e6',
    large: '#f391da',
    largePlus: '#b85f7d',
  };

  const ketoneTypes = useKetones();

  const addKetoneCard = (doc: jsPDF, x: number, y: number, ketone: Ketone) => {
    doc.rect(x, y, 425, 575);
    // Date:
    doc.text(new Date(ketone.date).toLocaleString(), x + 210, y + 40, { align: 'center' });
    // Ketones level/number:
    if (ketone.ketonesLevel && ketone.ketonesLevel !== 'noneSelected') {
      const ketoneColorKey = ketone.ketonesLevel as keyof typeof ketoColors;
      doc.setFillColor(ketoColors[ketoneColorKey]);
      doc.rect(x + 20, y + 75, 40, 40, 'FD');
      const ketoneLevelLocalized = ketoneTypes.find(level => level.dbName === ketone.ketonesLevel);
      doc.text(ketoneLevelLocalized ? ketoneLevelLocalized.localizedName : '', x + 70, y + 100);
    } else if (ketone.ketonesNumber) {
      doc.text(ketone.ketonesNumber.toString(), x + 20, y + 100);
    }
    const textLines = doc
      .setFont('helvetica')
      .setFontSize(30)
      .splitTextToSize(ketone.comments, 380);
    doc.setFontSize(30);

    doc.text(textLines, x + 20, y + 160);
    doc.setFontSize(40);
  };

  const crisisTypes = useCrises();

  const addCrisisCard = (doc: jsPDF, x: number, y: number, crisis: Crisis) => {
    doc.rect(x, y, 425, 575);
    // Date:
    doc.text(new Date(crisis.date).toLocaleString(), x + 210, y + 40, { align: 'center' });
    // Crisis type:
    const crisisTypeLocalized = crisisTypes.find(type => type.dbName === crisis.type);
    doc.text(crisisTypeLocalized ? crisisTypeLocalized.localizedName : '', x + 20, y + 100);
    // number of crises:
    doc.setFontSize(30);
    doc.text(
      `${intl.formatMessage({ id: 'record-crisis.crisis-number' })}: ${crisis.crisisNumber}`,
      x + 20,
      y + 140,
    );
    // Required medication:
    if (crisis.drugsQuantity) {
      doc.setFontSize(30);
      const textLines_amount = doc
        .setFont('helvetica')
        .setFontSize(30)
        .splitTextToSize(
          `${intl.formatMessage({ id: 'record-crisis.drugs-amount' })}: ${crisis.drugsQuantity}mg`,
          380,
        );
      doc.text(textLines_amount, x + 20, y + 200);
    }
    // Comments:
    const textLines = doc
      .setFont('helvetica')
      .setFontSize(30)
      .splitTextToSize(crisis.comments, 380);
    doc.setFontSize(30);
    doc.text(textLines, x + 20, y + 300);
    doc.setFontSize(40);
  };
  const navigate = useNavigate();
  const navigateHome = useCallback(() => {
    navigate(paths.HOME_MENU);
  }, [navigate]);
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box style={{ flex: 1 }}>
          <IconButton onClick={navigateHome}>
            <ArrowBackIcon style={{ color: colorss.darkPurple }} />
          </IconButton>
        </Box>
        <Box>
          <Typography
            align="center"
            fontFamily="Poppins Regular"
            style={{
              fontFamily: 'Poppins Regular',
              textDecoration: 'underline',
              marginBottom: '2vh',
              cursor: 'pointer',
            }}
            onClick={navigateHome}
          >
            <FormattedMessage id="data.title" />
          </Typography>
        </Box>
        <Box style={{ flex: 1 }}></Box>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={heightChartSelected} onChange={handleHeightChartChange} />}
          label={<T id="data.height.title" />}
        />
        <FormControlLabel
          control={<Checkbox checked={weightChartSelected} onChange={handleWeightChartChange} />}
          label={<T id="data.weight.title" />}
        />
        <FormControlLabel
          control={
            <Checkbox checked={crisisOverTimeSelected} onChange={handleCrisisOverTimeChange} />
          }
          label={<T id="data.crisis.time" />}
        />
        <FormControlLabel
          control={
            <Checkbox checked={crisisTypeChartSelected} onChange={handleCrisisTypeChartChange} />
          }
          label={<T id="data.crisis.types" />}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={crisisVsKetonesChartSelected}
              onChange={handleCrisisVsKetonesChartChange}
            />
          }
          label={<T id="data.crisis-vs-ketones" />}
        />
        <FormControlLabel
          control={<Checkbox checked={moodChartSelected} onChange={handleMoodChartChange} />}
          label={<T id="data.mood.title" />}
        />
      </FormGroup>
      <Box style={{ height: '0' }}>
        {heightWeightList && (
          <VictoryContainer
            containerRef={ref => {
              if (ref) {
                setHeightChartRef(ref);
              }
            }}
          >
            <HeightOverTime heightWeightList={heightWeightList} />
          </VictoryContainer>
        )}
        {heightWeightList && (
          <VictoryContainer
            containerRef={ref => {
              if (ref) {
                setWeightChartRef(ref);
              }
            }}
          >
            <WeightOverTime heightWeightList={heightWeightList} />
          </VictoryContainer>
        )}
        {crisisList && (
          <VictoryContainer
            containerRef={ref => {
              if (ref) {
                setCrisesOverTimeRef(ref);
              }
            }}
          >
            <CrisisOverTime
              crisisList={crisisList}
              numberOfDays={getNumberOfDays()}
              startDate={startDate}
              endDate={endDate}
            />
          </VictoryContainer>
        )}
        {crisisList && (
          <VictoryContainer
            containerRef={ref => {
              if (ref) {
                setCrisisTypesRef(ref);
              }
            }}
          >
            <CrisisTypes crisisList={crisisList} />
          </VictoryContainer>
        )}
        {crisisList && ketonesList && (
          <VictoryContainer
            containerRef={ref => {
              if (ref) {
                setCrisesVsKetonesRef(ref);
              }
            }}
          >
            <CrisisVsKetonesOverTime
              ketonesList={ketonesList}
              crisisList={crisisList}
              numberOfDays={getNumberOfDays()}
              startDate={startDate}
              endDate={endDate}
            />
          </VictoryContainer>
        )}
        {moodList && (
          <VictoryContainer
            containerRef={ref => {
              if (ref) {
                setMoodChartRef(ref);
              }
            }}
          >
            <MoodOverTime moodList={moodList} numberOfDays={getNumberOfDays()} />
          </VictoryContainer>
        )}
      </Box>
      <Box style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ height: '45px', width: '100%' }}
          onClick={async () => {
            const doc = new jsPDF({
              orientation: 'portrait',
              unit: 'px',
              format: [2750, 2000],
            });
            doc.setFontSize(40);
            let verticalOffset = 0;
            const img = new Image();
            img.src = '/logo/nutricia_logo.png';
            doc.addImage(img, 'png', 520, 50, 220, 130);
            // doc.text('Nutricia', 640, verticalOffset + 100, {
            //  align: 'right',
            //});
            const img1 = new Image();
            img1.src = '/logo/Ketoapp_logo.png';
            doc.addImage(img1, 'png', 1300, 50, 150, 130);
            verticalOffset += 150;
            doc.text('Paciente: ' + fullName, 540, verticalOffset + 100, {
              align: 'left',
            });
            verticalOffset += 50;
            doc.text(
              'Rango: ' + startDateString + ' - ' + endDateString,
              540,
              verticalOffset + 100,
              {
                align: 'left',
              },
            );
            verticalOffset += 100;
            if (
              weightChartRef &&
              weightChartSelected &&
              typeof weightChartRef.children[0].children[0].children[0] != 'undefined'
            ) {
              const node = weightChartRef.children[0].children[0].children[0];
              addBackgroundRect(node);
              await doc.addSvgAsImage(node.outerHTML, 550, 100 + verticalOffset, 900, 500);
              doc.text(
                intl.formatMessage({ id: 'data.weight.title' }),
                1000,
                verticalOffset + 650,
                {
                  align: 'center',
                },
              );
              verticalOffset += 600;
              if (verticalOffset > 1300) {
                verticalOffset = 100;
                doc.addPage([2750, 2000], 'p');
              }
            }
            if (
              heightChartRef &&
              heightChartSelected &&
              typeof heightChartRef.children[0].children[0].children[0] != 'undefined'
            ) {
              const node = heightChartRef.children[0].children[0].children[0];
              addBackgroundRect(node);
              await doc.addSvgAsImage(node.outerHTML, 550, 100 + verticalOffset, 900, 500);
              doc.text(
                intl.formatMessage({ id: 'data.height.title' }),
                1000,
                verticalOffset + 650,
                {
                  align: 'center',
                },
              );
              verticalOffset += 600;
              if (verticalOffset > 1300) {
                verticalOffset = 100;
                doc.addPage([2750, 2000], 'p');
              }
            }
            if (
              crisesOverTimeRef &&
              crisisOverTimeSelected &&
              typeof crisesOverTimeRef.children[0].children[0].children[0] != 'undefined'
            ) {
              const node = crisesOverTimeRef.children[0].children[0].children[0];
              addBackgroundRect(node);
              await doc.addSvgAsImage(node.outerHTML, 550, 100 + verticalOffset, 900, 500);
              doc.text(intl.formatMessage({ id: 'data.crisis.time' }), 1000, verticalOffset + 650, {
                align: 'center',
              });
              verticalOffset += 600;
              if (verticalOffset > 1300) {
                verticalOffset = 100;
                doc.addPage([2750, 2000], 'p');
              }
            }
            if (
              crisisTypesRef &&
              crisisTypeChartSelected &&
              typeof crisisTypesRef.children[0].children[0].children[0] != 'undefined'
            ) {
              const node = crisisTypesRef.children[0].children[0].children[0];
              addBackgroundRect(node);
              await doc.addSvgAsImage(node.outerHTML, 550, 100 + verticalOffset, 900, 500);
              doc.text(
                intl.formatMessage({ id: 'data.crisis.types' }),
                1000,
                verticalOffset + 650,
                {
                  align: 'center',
                },
              );
              verticalOffset += 600;
              if (verticalOffset > 1300) {
                verticalOffset = 100;
                doc.addPage([2750, 2000], 'p');
              }
            }
            if (
              crisesVsKetonesRef &&
              crisisVsKetonesChartSelected &&
              typeof crisesVsKetonesRef.children[0].children[0].children[0] != 'undefined'
            ) {
              const node = crisesVsKetonesRef.children[0].children[0].children[0];
              addBackgroundRect(node);
              await doc.addSvgAsImage(node.outerHTML, 550, 100 + verticalOffset, 900, 500);
              doc.text(
                intl.formatMessage({ id: 'data.crisis-vs-ketones' }),
                1000,
                verticalOffset + 650,
                {
                  align: 'center',
                },
              );
              verticalOffset += 600;
              if (verticalOffset > 1300) {
                verticalOffset = 100;
                doc.addPage([2750, 2000], 'p');
              }
            }
            if (
              moodChartRef &&
              moodChartSelected &&
              typeof moodChartRef.children[0].children[0].children[0] != 'undefined'
            ) {
              const node = moodChartRef.children[0].children[0].children[0];
              addBackgroundRect(node);
              await doc.addSvgAsImage(node.outerHTML, 550, 100 + verticalOffset, 900, 500);
              doc.text(intl.formatMessage({ id: 'data.mood.title' }), 1000, verticalOffset + 650, {
                align: 'center',
              });
              verticalOffset += 600;
            }
            doc.addPage([2750, 2000], 'p');
            doc.text(intl.formatMessage({ id: 'data.ketone-history' }), 1000, 70, {
              align: 'center',
            });
            let cardRow = 0;
            let cardColumn = 0;
            ketonesList?.forEach(ketone => {
              addKetoneCard(doc, 100 + 450 * cardColumn, 100 + 625 * cardRow, ketone);
              cardColumn += 1;
              if (cardColumn > 3) {
                cardColumn = 0;
                cardRow += 1;
                if (cardRow > 4) {
                  cardRow = 0;
                  doc.addPage([2750, 2000], 'p');
                }
              }
            });
            doc.addPage([2750, 2000], 'p');
            doc.text(intl.formatMessage({ id: 'data.crisis-history' }), 1000, 70, {
              align: 'center',
            });
            cardRow = 0;
            cardColumn = 0;
            crisisList?.forEach(crisis => {
              addCrisisCard(doc, 100 + 450 * cardColumn, 100 + 625 * cardRow, crisis);
              cardColumn += 1;
              if (cardColumn > 3) {
                cardColumn = 0;
                cardRow += 1;
                if (cardRow > 3) {
                  cardRow = 0;
                  doc.addPage([2750, 2000], 'p');
                }
              }
            });
            doc.save(filename);
          }}
        >
          <T id="data.download-pdf" />
        </Button>
      </Box>
    </Box>
  );
};
