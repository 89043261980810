import { Box } from '@mui/material';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { DietTypeForm } from './components/DietTypeForm';
import { NotificationSettingsForm } from './components/NotificationSettingsForm';
import { SignupForm } from './components/SignupForm';
import { UserTypeForm } from './components/UserTypeForm';

export const Signup: FunctionComponent = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const goBackOneStep = useCallback(() => {
    const backStep = currentStep - 1;
    setCurrentStep(backStep);
  }, [currentStep]);
  const goForwardOneStep = useCallback(() => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  }, [currentStep]);

  return (
    <Box display="flex" flexDirection="column" p={2}>
      {currentStep === 1 && <SignupForm goForwardOneStep={goForwardOneStep} />}
      {currentStep === 2 && (
        <UserTypeForm goBackOneStep={goBackOneStep} goForwardOneStep={goForwardOneStep} />
      )}
      {currentStep === 3 && (
        <DietTypeForm goBackOneStep={goBackOneStep} goForwardOneStep={goForwardOneStep} />
      )}
      {currentStep === 4 && <NotificationSettingsForm goBackOneStep={goBackOneStep} />}
    </Box>
  );
};
