import { Box, Button } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface Props {
  textId: string;
  icon: ReactNode;
  linkPath: string;
  newTab?: boolean;
  onClose: () => void;
}

export const MenuOption: FunctionComponent<Props> = (props: Props) => {
  const { textId, icon, linkPath, newTab, onClose } = props;

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0.5vh 0vw' }}
    >
      <Box style={{ marginRight: '1vw' }}>{icon}</Box>
      {newTab === true ? (
        <a
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button
            style={{
              fontFamily: 'Poppins Regular',
              fontSize: '16px',
              color: 'grey',
            }}
            onClick={onClose}
          >
            <FormattedMessage id={textId} />
          </Button>
        </a>
      ) : (
        <Button
          style={{ fontFamily: 'Poppins Regular', fontSize: '16px', color: 'grey' }}
          component={Link}
          to={linkPath}
          onClick={onClose}
        >
          <FormattedMessage id={textId} />
        </Button>
      )}
    </Box>
  );
};
