import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useSelector from 'redux/useSelector';
import client from 'services/networking/client';
import { Symptom } from 'types/types';
import CancelIcon from '@mui/icons-material/Cancel';
import { colors } from 'theme';

interface IProps {
  symptomData: Symptom;
  closeEdit: () => void;
}

export const EditSymptomForm: FunctionComponent<IProps> = ({ symptomData, closeEdit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      date: symptomData.date,
      comments: symptomData.comments,
      symptomDescription: symptomData.symptomDescription,
    },
  });

  const intl = useIntl();

  const userId = useSelector(state => state.user.id);

  const onSubmit = useCallback(
    async (data: any) => {
      const { symptomDescription, comments, date } = data;
      try {
        await client.patch(`/symptoms/${symptomData.id}`, {
          symptomDescription: symptomDescription,
          comments: comments,
          userId,
          date: date,
        });
        symptomData.comments = comments;
        symptomData.symptomDescription = symptomDescription;
        symptomData.date = date;

        closeEdit();
      } catch (error) {
        console.log('Error saving symptoms: ', error);
      }
    },
    [closeEdit, symptomData, userId],
  );

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <IconButton onClick={closeEdit}>
                  <CancelIcon />
                </IconButton>
              </Box>
              <Box>
                <Typography
                  align="center"
                  fontSize="22px"
                  fontFamily="Poppins Medium"
                  style={{ marginBottom: '2vh' }}
                >
                  <FormattedMessage id="record-symptoms.edit-symptom" />
                </Typography>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('symptomDescription', { required: false })}
                hiddenLabel
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-symptoms.describe-symptoms' })}
              />
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('comments', { required: false })}
                hiddenLabel
                id="outlined-multiline-static"
                multiline
                rows={6}
                fullWidth
                placeholder={intl.formatMessage({ id: 'record-symptoms.comments' })}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
          >
            <FormattedMessage id="record-symptoms.save-symptoms" />
          </Button>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
