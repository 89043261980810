import { FetchQueryOptions, useMutation, useQueryClient } from 'react-query';
import { Sleep } from 'types/types';

export const getSleepsForPatient = async (patientId: string): Promise<Sleep[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/sleep/user/${patientId}`).then(async response => response.json());
};

export const getSleepsForPatientQuery = (patientId: string): FetchQueryOptions<Sleep[]> => ({
  queryKey: ['sleeps', patientId],
  queryFn: async () => await getSleepsForPatient(patientId),
});

export const DeleteSleepByIdMutation = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    (sleepId: string) => {
      const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
      return fetch(`${backendBaseUrl}/sleep/${sleepId}`, {
        method: 'DELETE',
      });
    },
    {
      onMutate: async (sleepId: string) => {
        await queryClient.cancelQueries('sleeps');
        const previousSleeps = queryClient.getQueryData<Sleep[]>('sleeps');
        if (previousSleeps) {
          const newSleeps = previousSleeps.filter(sleep => sleep.id !== sleepId);
          queryClient.setQueryData<Sleep[]>('sleeps', { ...newSleeps });
        }
        return { previousSleeps };
      },
      onSettled: () => {
        queryClient.invalidateQueries('sleeps');
      },
    },
  );
  return deleteMutation;
};
