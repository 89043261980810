import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { convertUserToState, getUserDataByCognitoId } from 'dataLayer/user';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserToken, setKetoCalcToken, userLoggedIn } from 'redux/Login';
import { userConnect } from 'redux/User';
import { paths } from 'routes';
import client from 'services/networking/client';
import { colors, fontSize } from 'theme';

export const Login: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector(getUserToken);
  const location = useLocation();
  const { formatMessage: t } = useIntl();

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
  const [loginError, setLoginError] = useState(false);

  // useEffect(() => {
  //   if (userToken !== null && location !== null) {
  //     navigate(paths.HOME_MENU);
  //   }
  // }, [location, navigate, userToken]);

  const onLogin = useCallback(
    async e => {
      try {
        setLoginError(false);
        setLoginButtonDisabled(true);
        const user = await Auth.signIn(username, password);
        const userToken = user.signInUserSession.accessToken.jwtToken;
        const databaseUser = await getUserDataByCognitoId(user.attributes.sub);
        const ketoCalcTokenResponse = await client.get('/auth/keto-calc-token');
        const kcPatientEndpoint = `/auth/getPatentId/${username}/${ketoCalcTokenResponse.token}`;
        const patientId = await client.get(kcPatientEndpoint);
        try {
          sessionStorage.setItem('patientIds', patientId[0].id);
        } catch (e) {
          sessionStorage.setItem('patientIds', '0');
        }
        await dispatch(userConnect(convertUserToState(user, databaseUser)));
        await dispatch(userLoggedIn(userToken));
        await dispatch(setKetoCalcToken(ketoCalcTokenResponse.token));
        navigate(paths.HOME_MENU);
      } catch (error) {
        setLoginError(true);
        setLoginButtonDisabled(false);
        console.log('error signing in', error);
      }
    },
    [dispatch, navigate, password, username],
  );

  const handleForgotPassword = useCallback(() => {
    navigate(paths.RESET_PASSWORD);
  }, [navigate]);

  const usernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '90%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        m: 2,
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box style={{ paddingBottom: '5vh' }}>
          <Box style={{ paddingTop: '5vh' }}>
            <Typography
              align="center"
              fontSize={fontSize.large}
              fontFamily="Poppins Medium"
              style={{ marginBottom: '2vh', color: colors.darkPurple }}
            >
              <T id="login.login" />
            </Typography>
          </Box>

          <Typography align="center" fontSize="18px" fontFamily="Poppins Regular">
            <T id="login.enter-credentials" />
          </Typography>
        </Box>
        <Box style={{ paddingBottom: '25vh' }}>
          <Box style={{ margin: '1vh 0vh' }}>
            <TextField
              placeholder={t({ id: 'login.username' })}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              onChange={usernameChange}
            />
          </Box>
          <Box style={{ margin: '1vh 0vh' }}>
            <TextField
              placeholder={t({ id: 'login.password' })}
              fullWidth
              type="password"
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              onChange={passwordChange}
            />
          </Box>
          {loginError && (
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography color="GrayText">
                <T id="login.invalid-credentials" />
              </Typography>
              <ErrorOutlineIcon color="disabled" fontSize="small" style={{ marginLeft: '5px' }} />
            </Box>
          )}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ fontFamily: 'Poppins Regular', textDecoration: 'underline' }}
              component={Link}
              to={paths.SIGNUP}
            >
              <T id="login.sign-up" />
            </Button>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                fontFamily: 'Poppins Regular',
                fontSize: fontSize.XSmall,
                color: colors.mediumGrey,
              }}
              onClick={handleForgotPassword}
            >
              <T id="login.forgot-password" />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography
          align="center"
          style={{
            fontFamily: 'Poppins Regular',
            textDecoration: 'underline',
            marginBottom: '2vh',
          }}
          onClick={() => {
            return;
          }}
        ></Typography>

        <Button
          disabled={loginButtonDisabled}
          variant="contained"
          style={{
            height: '45px',
            width: '100%',
            color: colors.white,
            backgroundColor: colors.darkPurple,
          }}
          onClick={onLogin}
        >
          <T id="login.login-button" />
        </Button>
      </Box>
    </Box>
  );
};
