/* eslint-disable max-lines */
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, TextField, Typography, RadioGroup, SvgIcon, Radio } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import client from 'services/networking/client';
import { fontSize, colors } from 'theme';
import { ReactComponent as Mood1 } from '../../assets/mood-assets/mood-1.svg';
import { ReactComponent as Mood2 } from '../../assets/mood-assets/mood-2.svg';
import { ReactComponent as Mood3 } from '../../assets/mood-assets/mood-3.svg';
import { ReactComponent as Mood4 } from '../../assets/mood-assets/mood-4.svg';
import { ReactComponent as Mood5 } from '../../assets/mood-assets/mood-5.svg';
import ReactGA from 'react-ga';

export interface MoodData {
  mood: number;
  date: Date;
  comments: string;
}
export const moodIcons = [Mood1, Mood2, Mood3, Mood4, Mood5];

export const RecordMood: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: { mood: 0, date: new Date(Date.now()), comments: '' },
  });

  const { formatMessage: t } = useIntl();

  const navigate = useNavigate();
  const intl = useIntl();

  const userId = useSelector(state => state.user.id);

  const onSubmitMood = useCallback(
    index => {
      setValue('mood', index + 1, { shouldValidate: true, shouldDirty: true });
    },
    [setValue],
  );

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: MoodData) => {
      setButtonDisabled(true);
      ReactGA.event({
        category: 'ButtonClick',
        action: 'Record Mood',
      });
      try {
        await client.post('/mood', {
          mood: data.mood,
          comments: data.comments,
          userId,
          date: data.date.toJSON(),
        });
        navigate(paths.LOGGED_MOOD);
      } catch (error) {
        console.log('Error saving mood: ', error);
        setButtonDisabled(false);
      }
    },
    [navigate, userId],
  );

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ margin: 1 }}>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <BackArrow />
              </Box>
              <Box>
                <Typography
                  align="center"
                  fontSize={fontSize.large}
                  fontFamily="Poppins Medium"
                  style={{ marginBottom: '2vh', color: colors.darkPurple }}
                >
                  <FormattedMessage id="record-mood.title" />
                </Typography>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '1vh 2px',
                }}
              >
                <RadioGroup
                  aria-label="Record mood buttons"
                  defaultValue=""
                  name="mood"
                  row
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {moodIcons.map((moodIcon, index) => (
                    <Radio
                      key={`mood-${index + 1}`} // eslint-disable-line react/no-array-index-key
                      value={index + 1}
                      required={true}
                      onClick={() => onSubmitMood(index)}
                      icon={
                        <SvgIcon
                          component={moodIcon}
                          title={`mood-${index}`}
                          viewBox="0 0 477.14 885"
                          aria-label={`mood-${index + 1}`}
                          style={{
                            height: '100%',
                          }}
                        />
                      }
                      checkedIcon={
                        <SvgIcon
                          component={moodIcon}
                          title={`mood-${index}`}
                          viewBox="0 0 477.14 885"
                          aria-label={`mood-${index + 1}`}
                          style={{
                            height: '100%',
                            filter: `drop-shadow(2px 0 2px ${colors.lightGrey}) drop-shadow(-2px 0 2px ${colors.lightGrey}) drop-shadow(0 2px 2px ${colors.lightGrey}) drop-shadow(0 -2px 2px ${colors.lightGrey}) `,
                          }}
                        />
                      }
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('comments', { required: false })}
                hiddenLabel
                id="outlined-multiline-static"
                multiline
                rows={6}
                fullWidth
                placeholder={t({ id: 'record-mood.add-comments' })}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              style={{
                height: '45px',
                width: '100%',
                color: colors.white,
                backgroundColor: colors.darkPurple,
              }}
              type="submit"
              disabled={buttonDisabled}
            >
              <FormattedMessage id="record-mood.save-mood" />
            </Button>
          </Box>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
