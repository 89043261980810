import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { colors } from 'theme';

interface IProps {
  onEdit: () => void;
  onDelete: () => void;
}

export const CardOptions = forwardRef((props: IProps, ref) => (
  <Box ref={ref} sx={{ width: 96, p: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
    <Button
      variant="text"
      startIcon={<EditIcon style={{ color: colors.darkPurple }} />}
      fullWidth
      sx={{ justifyContent: 'flex-start', px: 1 }}
      onClick={props.onEdit}
    >
      <Typography fontFamily={'Poppins regular'} style={{ color: colors.darkPurple }}>
        <FormattedMessage id={'history-record.card.edit'} />
      </Typography>
    </Button>
    <Button
      variant="text"
      startIcon={<DeleteIcon style={{ color: colors.darkPurple }} />}
      fullWidth
      sx={{ justifyContent: 'flex-start', px: 1 }}
      onClick={props.onDelete}
    >
      <Typography fontFamily={'Poppins regular'} style={{ color: colors.darkPurple }}>
        <FormattedMessage id={'history-record.card.delete'} />
      </Typography>
    </Button>
  </Box>
));

CardOptions.displayName = 'CardOptions';
