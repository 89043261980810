import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { Box, Button, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage as T } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { paths } from 'routes';

interface LocationState {
  patientId: string;
}

export const EmailValidation: FunctionComponent = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [isResent, setIsResent] = useState(false);

  const resendConfirmationCode = useCallback(async () => {
    try {
      if (!state.patientId) {
        throw new Error('No patient id');
      }
      await Auth.resendSignUp(state.patientId);
      setIsResent(true);
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }, [state.patientId]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" p={2} height="90%">
      <Box>
        <Box style={{ marginTop: '5vh' }}>
          <Typography
            align="center"
            fontSize="22px"
            fontFamily="Poppins Medium"
            style={{ marginBottom: '2vh' }}
          >
            <T id="email-confirmation.validation" />
          </Typography>
        </Box>

        <Typography align="center" fontSize="18px" fontFamily="Poppins Regular">
          <T id="email-confirmation.message" />
        </Typography>
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <MarkEmailReadOutlinedIcon sx={{ fontSize: '50px' }} />
      </Box>

      <Box>
        <Typography align="center" fontSize="14px" fontFamily="Poppins Regular">
          <T id="email-confirmation.resend-message" />
        </Typography>
        <Typography
          sx={{ textDecoration: 'underline' }}
          align="center"
          fontSize="14px"
          fontFamily="Poppins Regular"
          onClick={resendConfirmationCode}
        >
          <T id="email-confirmation.resend" />
        </Typography>
        {isResent && (
          <Typography align="center" fontSize="14px" fontFamily="Poppins Regular">
            <T id="email-confirmation.email-resent" />
          </Typography>
        )}
      </Box>

      <Button
        variant="contained"
        color="primary"
        style={{ height: '45px', width: '100%' }}
        component={Link}
        to={paths.HOME_MENU}
      >
        <T id="email-confirmation.ok" />
      </Button>
    </Box>
  );
};
