import { FetchQueryOptions, useMutation, useQueryClient } from 'react-query';
import client from 'services/networking/client';
import { Crisis } from 'types/types';

const getCrisesForPatient = async (
  patientId: string,
  limit: number,
  offset: number,
): Promise<{ results: Crisis[]; count: number }> => {
  return client
    .get(`/crisis/user/${patientId}`, {
      limit,
      offset,
    })
    .then(async response => {
      return response;
    });
};

export const getCrisesForPatientQuery = (
  patientId: string,
  limit: number,
  offset: number,
): FetchQueryOptions<{ results: Crisis[]; count: number }> => ({
  queryKey: ['crises', patientId, limit, offset],
  queryFn: async () => {
    const crisisResponse = await getCrisesForPatient(patientId, limit, offset);
    return crisisResponse;
  },
});

export const getCrisesForPatientByDate = async (
  patientId: string,
  startDate: Date,
  endDate: Date,
): Promise<Crisis[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/crisis/by-date`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: patientId, startDate, endDate }),
  }).then(async response => {
    return response.json();
  });
};

export const getCrisesForPatientByDateQuery = (
  patientId: string,
  startDate: Date,
  endDate: Date,
): FetchQueryOptions<Crisis[]> => ({
  queryKey: ['crisesByDate', patientId, startDate, endDate],
  queryFn: async () => {
    const crisisResponse = await getCrisesForPatientByDate(patientId, startDate, endDate);
    return crisisResponse;
  },
});

export const DeleteCrisisByIdMutation = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    (crisisId: string) => {
      const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
      return fetch(`${backendBaseUrl}/crisis/${crisisId}`, {
        method: 'DELETE',
      });
    },
    {
      onMutate: async (crisisId: string) => {
        await queryClient.cancelQueries('crises');
        const previousCrises = queryClient.getQueryData<Crisis[]>('crises');
        if (previousCrises) {
          const newCrises = previousCrises.filter(crisis => crisis.id !== crisisId);
          queryClient.setQueryData<Crisis[]>('crises', { ...newCrises });
        }
        return { previousCrises };
      },
      onSettled: () => {
        queryClient.invalidateQueries('crises');
      },
    },
  );
  return deleteMutation;
};
