import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import CalendarHeader from 'components/CalendarHeader';
import { DailyMacros } from 'components/DailyMacros';
import Loader from 'components/Loader';
import { SmallMealCard } from 'components/SmallMealCard';
import { debug } from 'console';
import { MealTypes, mealTypes } from 'const/mealTypes';
import { getRecipesForPatientQuery } from 'dataLayer/recipes';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getKetoCalcToken } from 'redux/Login';
import { colors, fontSize } from 'theme';

interface LocationState {
  mealTypeId: string;
}

export const RecipeSelection: FunctionComponent = () => {
  const location = useLocation();
  const ketoCalcToken = useSelector(getKetoCalcToken);

  const { mealTypeId } = (location.state as LocationState) ?? MealTypes.ALL;

  const [mealTypeSelection, setMealTypeSelection] = useState(
    mealTypeId ? mealTypes.find(type => type.id === mealTypeId)?.value : MealTypes.ALL,
  );

  const qc = useQueryClient();
  const pid = sessionStorage.getItem('patientIds') || '0';
  const { data: recipesForPatient, isLoading } = useQuery(
    // TODO: change patientId to actual patientId from user
    getRecipesForPatientQuery(qc, parseInt(pid), ketoCalcToken),
  );

  const handleMealTypeSelectionChange = (event: React.MouseEvent<HTMLElement>, newMeal: string) => {
    setMealTypeSelection(newMeal ?? MealTypes.ALL);
  };

  const renderCards = useCallback(() => {
    console.log(mealTypeSelection);
    if (!isLoading && recipesForPatient) {
      return recipesForPatient
        .filter(
          recipe =>
            mealTypeSelection === MealTypes.ALL ||
            recipe.tipo_comida.some(u => u.slug === mealTypeSelection),
        )
        .map(recipe => <SmallMealCard key={recipe.recipeId} recipe={recipe} />);
    }
    return <Loader />;
  }, [isLoading, mealTypeSelection, recipesForPatient]);

  return (
    <Box sx={{ display: 'flex', height: '90vh', flexDirection: 'column' }}>
      <CalendarHeader />
      <DailyMacros />
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <ToggleButtonGroup
          color="primary"
          value={mealTypeSelection}
          exclusive
          onChange={handleMealTypeSelectionChange}
          sx={{
            overflowX: 'scroll',
          }}
        >
          {mealTypes.map(mealType => (
            <ToggleButton key={mealType.id} value={mealType.value}>
              <Typography fontSize={fontSize.small} style={{ color: colors.darkPurple }}>
                <FormattedMessage id={mealType.id} />
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <ToggleButton
          value={MealTypes.ALL}
          selected={mealTypeSelection === MealTypes.ALL}
          onChange={() => setMealTypeSelection(MealTypes.ALL)}
        >
          <Typography fontSize={fontSize.small} style={{ color: colors.darkPurple }}>
            <FormattedMessage id={'recipe-selection.all'} />
          </Typography>
        </ToggleButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {renderCards()}
      </Box>
    </Box>
  );
};
