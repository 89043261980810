import { colors } from 'theme';

import React, { FunctionComponent, useCallback } from 'react';
import { VictoryChart, VictoryBar, VictoryLine, VictoryAxis } from 'victory';
import { Crisis, Ketone } from 'types/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';

interface IProps {
  crisisList: Crisis[];
  ketonesList: Ketone[];
  numberOfDays: number;
  startDate: Date;
  endDate: Date;
}
export const CrisisVsKetonesOverTime: FunctionComponent<IProps> = ({
  crisisList,
  ketonesList,
  numberOfDays,
  startDate,
  endDate,
}) => {
  const convertCrisisDataForGraph = useCallback((data: Crisis[]) => {
    const summedValues: { [date: string]: number } = {};
    data.forEach(datum => {
      const d = new Date(datum.date);
      const mm = d.getMonth() + 1;
      const dd = d.getDate();
      const yy = d.getFullYear();
      const dateForDatum = mm + '/' + dd + '/' + yy;
      if (summedValues[dateForDatum] === undefined) {
        summedValues[dateForDatum] = datum.crisisNumber;
      } else {
        summedValues[dateForDatum] += datum.crisisNumber;
      }
    });
    const simplifiedData = [];
    for (const key in summedValues) {
      simplifiedData.push({ date: new Date(key), crisisNumber: summedValues[key] });
    }
    return simplifiedData;
  }, []);

  const getCrisisMaxima = useCallback((data: { date: Date; crisisNumber: number }[]) => {
    return Math.max(
      ...data.map(crisis => {
        return crisis.crisisNumber;
      }),
    );
  }, []);
  <image x={20}></image>;

  const intl = useIntl();

  const ketonesValueMap = [
    { label: 'negative', value: 0 },
    { label: 'trace', value: 1 },
    { label: 'small', value: 2 },
    { label: 'moderate', value: 3 },
    { label: 'large', value: 4 },
    { label: 'largePlus', value: 5 },
  ];

  const convertKetonesForGraph = useCallback((data: Ketone[]) => {
    return data
      .filter(ketone => ketone.ketonesLevel !== 'noneSelected')
      .map(ketone => {
        return {
          date: new Date(ketone.date),
          ketonesLevel: ketonesValueMap.find(value => value.label === ketone.ketonesLevel)?.value,
        };
      });
  }, []);

  return (
    <Box>
      {(ketonesList.length > 0 || crisisList.length > 0) && (
        <Box>
          <Typography>
            <FormattedMessage id="data.crisis-vs-ketones" />
          </Typography>
          <VictoryChart domainPadding={{ x: 50 }}>
            <VictoryBar
              data={convertCrisisDataForGraph(crisisList)}
              x="date"
              y={datum =>
                Math.ceil(
                  (datum.crisisNumber / getCrisisMaxima(convertCrisisDataForGraph(crisisList))) * 5,
                )
              }
              key="crisisNumber"
              style={{ data: { fill: colors.mediumPurple } }}
              barRatio={0.2}
            />
            <VictoryLine data={convertKetonesForGraph(ketonesList)} x="date" y="ketonesLevel" />
            <VictoryAxis
              label="Date"
              tickFormat={(x, i) =>
                !(i % Math.round(numberOfDays / 10))
                  ? new Date(x).toLocaleDateString('es-AR').slice(0, -5)
                  : ''
              }
              // TODO: Fix rendering of days for this graph
              tickCount={numberOfDays}
              domain={[startDate, endDate]}
              style={{
                tickLabels: { fontSize: '8' },
                ticks: { stroke: 'grey', size: 5 },
                axisLabel: { padding: 60 },
              }}
              width={20}
            />
            <VictoryAxis
              dependentAxis
              label={intl.formatMessage({ id: 'data.ketones.level' })}
              key="ketonesLevel"
              offsetX={400}
              style={{
                ticks: { padding: -60 },
                tickLabels: { padding: 10, fontSize: '8', textAnchor: 'end', textAlign: 'left' },
                axisLabel: { padding: 5 },
              }}
              tickValues={[0, 1, 2, 3, 4, 5]}
              domain={[0, 4]}
              tickFormat={t =>
                intl.formatMessage({
                  id: 'ketone-levels.' + ketonesValueMap[t].label,
                })
              }
            />
            <VictoryAxis
              dependentAxis
              label={intl.formatMessage({ id: 'data.crisis.number' })}
              key="crisisNumber"
              style={{
                axisLabel: { padding: 35 },
                grid: { stroke: colors.mediumGrey, strokeDasharray: '2' },
                tickLabels: { fontSize: '8' },
              }}
              tickValues={[0, 1, 2, 3, 4, 5]}
              tickFormat={t =>
                t == 0
                  ? 0
                  : Math.ceil(t * (getCrisisMaxima(convertCrisisDataForGraph(crisisList)) / 5))
              }
            />
          </VictoryChart>
        </Box>
      )}
    </Box>
  );
};
