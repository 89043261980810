/* eslint-disable max-lines */
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { localeMap } from 'const/calendar';
import { useCrises } from 'const/crisisTypes';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import client from 'services/networking/client';
import { fontSize, colors } from 'theme';
import ReactGA from 'react-ga';

export const RecordHeightWeight: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      date: new Date(Date.now()),
      height: null,
      weight: null,
    },
  });

  const userId = useSelector(state => state.user.id);

  const navigate = useNavigate();
  const intl = useIntl();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: any) => {
      setButtonDisabled(true);
      const { date, height, weight } = data;
      ReactGA.event({
        category: 'ButtonClick',
        action: 'Record Height/Weight',
      });
      try {
        await client
          .post('/heightweight', {
            userId,
            date: date.toJSON(),
            height,
            weight,
          })
          .catch(error => console.log('Error saving height/weight record: ', error));
        navigate(paths.LOGGED_HEIGHT_WEIGHT);
      } catch (error) {
        console.log('Error saving height/weight record: ', error);
        setButtonDisabled(false);
      }
    },
    [navigate, userId],
  );

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',

          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <BackArrow />
              </Box>
              <Box>
                <Typography
                  align="center"
                  fontSize={fontSize.large}
                  fontFamily="Poppins Medium"
                  style={{ marginBottom: '2vh', color: colors.darkPurple }}
                >
                  <FormattedMessage id="record-height-weight.title" />
                </Typography>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>

            <Typography>
              <FormattedMessage id="record-height-weight.height" />
              {' ('}
              <FormattedMessage id="record-height-weight.height-unit" />
              {')'}
            </Typography>
            <Box>
              <Box style={{ margin: '1vh 0' }}>
                <TextField
                  {...register('height', {
                    required: true,

                    pattern: {
                      value: /^(0|[1-9][0-9]*)$/,
                      message: intl.formatMessage({
                        id: 'record-height-weight.error-number-height',
                      }),
                    },
                  })}
                  id="standard-number"
                  hiddenLabel
                  fullWidth
                  helperText={
                    errors.height
                      ? intl.formatMessage({ id: 'record-height-weight.error-number-height' })
                      : ''
                  }
                />
              </Box>
            </Box>
            <Typography>
              <FormattedMessage id="record-height-weight.weight" />
              {' ('}
              <FormattedMessage id="record-height-weight.weight-unit" />
              {')'}
            </Typography>
            <Box>
              <Box style={{ margin: '1vh 0' }}>
                <TextField
                  {...register('weight', {
                    required: true,
                    pattern: {
                      value: /^(0|[1-9][0-9]*)$/,
                      message: intl.formatMessage({
                        id: 'record-height-weight.error-number-weight',
                      }),
                    },
                  })}
                  id="standard-number"
                  hiddenLabel
                  fullWidth
                  helperText={
                    errors.weight
                      ? intl.formatMessage({ id: 'record-height-weight.error-number-weight' })
                      : ''
                  }
                />
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
            disabled={buttonDisabled}
          >
            <FormattedMessage id="record-height-weight.save-height-weight" />
          </Button>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
