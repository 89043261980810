import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { BackArrow } from 'components/BackArrow';
import useSelector from 'redux/useSelector';
import { useDispatch } from 'react-redux';
import { userChangedSettings } from 'redux/User';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { colors, fontSize } from 'theme';

export const ProfileSettings: FunctionComponent = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [savedSuccessMessage, setSavedSuccessMessage] = React.useState(false);

  const [state, setState] = React.useState({
    newDietNotifications: user.newDietNotifications,
    symptomFollowUpNotifications: user.symptomFollowUpNotifications,
    newsNotifications: user.newsNotifications,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const saveSettings = useCallback(async () => {
    setSavedSuccessMessage(false);
    const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
    await fetch(`${backendBaseUrl}/users/${user.id}`, {
      method: 'PATCH',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(state),
    });
    dispatch(userChangedSettings(state));
    setSavedSuccessMessage(true);
  }, [dispatch, state, user.id]);

  const navigate = useNavigate();

  const navigateHome = useCallback(() => {
    navigate(paths.HOME_MENU);
  }, [navigate]);

  return (
    <Box display="flex" height="95%" flexDirection="column">
      <Typography
        fontSize={fontSize.large}
        fontFamily="Poppins Medium"
        style={{ alignSelf: 'center', color: colors.darkPurple }}
      >
        <FormattedMessage id="profile-settings.title" />
      </Typography>
      <Box mx={2} mt={1}>
        <Typography
          align="left"
          fontSize={fontSize.medium}
          fontFamily="Poppins Medium"
          style={{ color: colors.darkPurple }}
        >
          <FormattedMessage id="profile-settings.notifications" />
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup>
            <FormattedMessage id="profile-settings.new-diet-notifications">
              {(text: string) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.newDietNotifications}
                      onChange={handleChange}
                      name="newDietNotifications"
                    />
                  }
                  label={text}
                  style={{ color: colors.darkPurple }}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="profile-settings.symptoms-follow-up-notifications">
              {(text: string) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.symptomFollowUpNotifications}
                      onChange={handleChange}
                      name="symptomFollowUpNotifications"
                    />
                  }
                  label={text}
                  style={{ color: colors.darkPurple }}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="profile-settings.news-notifications">
              {(text: string) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.newsNotifications}
                      onChange={handleChange}
                      name="newsNotifications"
                    />
                  }
                  label={text}
                  style={{ color: colors.darkPurple }}
                />
              )}
            </FormattedMessage>
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <Typography
          align="center"
          style={{
            fontFamily: 'Poppins Regular',
            textDecoration: 'underline',
            marginBottom: '2vh',
          }}
          onClick={navigateHome}
        >
          <FormattedMessage id="profile-settings.back-to-home" />
        </Typography>

        <FormattedMessage id="profile-settings.save">
          {(text: string) => (
            <Button
              variant="contained"
              color="primary"
              style={{
                height: '45px',
                width: '100%',
                color: colors.white,
                backgroundColor: colors.darkPurple,
              }}
              onClick={saveSettings}
            >
              {text}
            </Button>
          )}
        </FormattedMessage>
        {savedSuccessMessage && (
          <FormattedMessage id="profile-settings.saved-successfully">
            {(text: string) => (
              <Typography
                fontSize={fontSize.medium}
                fontFamily="Poppins Medium"
                style={{ alignSelf: 'center', color: colors.darkPurple }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>
        )}
      </Box>
    </Box>
  );
};
