import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userTypeFormSubmit } from 'redux/Signup';
import { UserTypes } from 'redux/User';

interface IProps {
  goBackOneStep: () => void;
  goForwardOneStep: () => void;
}

export const UserTypeForm: FunctionComponent<IProps> = ({ goBackOneStep, goForwardOneStep }) => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async data => {
      await dispatch(userTypeFormSubmit(data));
      goForwardOneStep();
    },
    [dispatch, goForwardOneStep],
  );
  const navigate = useNavigate();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '90vh',
        }}
      >
        <Box>
          <Box
            style={{ display: 'flex', justifyContent: 'start', flexDirection: 'row' }}
            onClick={goBackOneStep}
          >
            <BackArrow />
          </Box>
          <Box style={{ paddingTop: '5vh' }}>
            <FormattedMessage id="confirm-patient.title">
              {(text: string) => (
                <Typography
                  align="center"
                  fontSize="22px"
                  fontFamily="Poppins Medium"
                  style={{ marginBottom: '2vh' }}
                >
                  {text}
                </Typography>
              )}
            </FormattedMessage>
          </Box>
          <Box style={{ margin: '0 10vw' }}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="patient" defaultValue={UserTypes.PATIENT}>
                <FormattedMessage id="confirm-patient.patient">
                  {(text: string) => (
                    <FormControlLabel
                      value={UserTypes.PATIENT}
                      control={<Radio />}
                      label={text}
                      {...register('userType')}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="confirm-patient.caregiver">
                  {(text: string) => (
                    <FormControlLabel
                      value={UserTypes.CAREGIVER}
                      control={<Radio />}
                      label={text}
                      {...register('userType')}
                    />
                  )}
                </FormattedMessage>
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <FormattedMessage id="confirm-patient.back-to-home">
            {(text: string) => (
              <Typography
                align="center"
                style={{
                  fontFamily: 'Poppins Regular',
                  textDecoration: 'underline',
                  marginBottom: '2vh',
                }}
                onClick={() => {
                  navigate('/');
                }}
              >
                {text}
              </Typography>
            )}
          </FormattedMessage>

          <FormattedMessage id="confirm-patient.next">
            {(text: string) => (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ height: '45px', width: '100%' }}
              >
                {text}
              </Button>
            )}
          </FormattedMessage>
        </Box>
      </Box>
    </form>
  );
};
