/* eslint-disable max-lines */
/* eslint-disable complexity */
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  Popper,
  SvgIcon,
  Typography,
} from '@mui/material';
import { ketoColors, KetonesIcon } from 'components/KetonesIcon';
import { DeleteCrisisByIdMutation } from 'dataLayer/crises';
import { DeleteHeightWeightByIdMutation } from 'dataLayer/heightweight';
import { DeleteKetoneByIdMutation } from 'dataLayer/ketones';
import { DeleteMoodByIdMutation } from 'dataLayer/mood';
import { DeleteSleepByIdMutation } from 'dataLayer/sleep';
import { DeleteSymptomByIdMutation } from 'dataLayer/symptoms';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { colors } from 'theme';
import { Crisis, HeightWeight, Ketone, Mood, Sleep, Symptom } from 'types/types';
import { CardOptions } from '.';
import { moodIcons } from 'pages/RecordMood';
import { useCrises } from 'const/crisisTypes';
import { useKetones } from 'const/ketoneTypes';

interface IProps {
  historyType: 'crisis' | 'sleep' | 'symptom' | 'ketone' | 'heightWeight' | 'mood';
  data: Partial<Crisis & Sleep & Symptom & Ketone & HeightWeight & Mood>;
}

export const HistoryCard: FunctionComponent<IProps> = ({ data, historyType }) => {
  const navigate = useNavigate();

  const {
    date,
    comments,
    type,
    quality,
    symptomDescription,
    crisisNumber,
    drugsQuantity,
    hasRescueMedication,
    ketonesLevel,
    ketonesNumber,
    weight,
    height,
    mood,
  } = data;
  const dateString = date ? new Date(date).toLocaleDateString('es-AR') : null;

  const anchorRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleCardClick = useCallback(async () => {
    navigate(paths.DETAILS, { state: { data, historyType, editing: false } });
  }, [data, historyType, navigate]);

  const editFromMenu = useCallback(async () => {
    navigate(paths.DETAILS, { state: { data, historyType, editing: true } });
  }, [data, historyType, navigate]);

  const deleteSleepMutation = DeleteSleepByIdMutation();

  const deleteCrisisMutation = DeleteCrisisByIdMutation();

  const deleteSymptomMutation = DeleteSymptomByIdMutation();

  const deleteKetoneMutation = DeleteKetoneByIdMutation();

  const deleteHeightWeightMutation = DeleteHeightWeightByIdMutation();

  const deleteMoodMutation = DeleteMoodByIdMutation();

  const onDelete = useCallback(() => {
    if (historyType === 'sleep') {
      data.id != null && deleteSleepMutation.mutate(data.id);
    }
    if (historyType === 'crisis') {
      data.id != null && deleteCrisisMutation.mutate(data.id);
    }
    if (historyType === 'symptom') {
      data.id != null && deleteSymptomMutation.mutate(data.id);
    }
    if (historyType === 'ketone') {
      data.id != null && deleteKetoneMutation.mutate(data.id);
    }
    if (historyType === 'heightWeight') {
      data.id != null && deleteHeightWeightMutation.mutate(data.id);
    }
    if (historyType === 'mood') {
      data.id != null && deleteMoodMutation.mutate(data.id);
    }
  }, [
    data.id,
    deleteCrisisMutation,
    deleteHeightWeightMutation,
    deleteKetoneMutation,
    deleteMoodMutation,
    deleteSleepMutation,
    deleteSymptomMutation,
    historyType,
  ]);

  const crisisTypes = useCrises();
  const ketoneTypes = useKetones();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      width="100%"
      bgcolor={historyType === 'crisis' && type === 'status' ? colors.lightRed : colors.lightGrey}
      borderRadius={1}
      boxSizing="border-box"
      p={2}
      mb={1}
    >
      <Box
        display="flex"
        flexDirection="column"
        onClick={handleCardClick}
        style={{ width: '100%' }}
      >
        {dateString != null && (
          <Typography style={{ color: colors.darkPurple }} fontFamily={'Poppins regular'}>
            {dateString}
          </Typography>
        )}
        {historyType === 'crisis' && type && (
          <Typography
            style={{ textTransform: 'capitalize', color: colors.darkPurple }}
            fontFamily={'Poppins regular'}
          >
            {crisisTypes.find(crisisType => crisisType.dbName === type)?.localizedName ?? ''}
          </Typography>
        )}
        {historyType === 'crisis' && crisisNumber !== undefined && (
          <Typography
            style={{ textTransform: 'capitalize', color: colors.darkPurple }}
            fontFamily={'Poppins regular'}
          >
            <FormattedMessage id="record-crisis.crisis-number" />
            {`: ${crisisNumber}`}
          </Typography>
        )}
        {historyType === 'crisis' && drugsQuantity !== undefined && drugsQuantity !== null && (
          <Typography
            style={{ textTransform: 'capitalize', color: colors.darkPurple }}
            fontFamily={'Poppins regular'}
          >
            <FormattedMessage id="record-crisis.drugs-amount" />
            {`: ${drugsQuantity}`}
          </Typography>
        )}
        {historyType === 'crisis' && hasRescueMedication !== null && (
          <FormControlLabel
            control={<Checkbox checked={hasRescueMedication} />}
            label={
              <Typography style={{ fontFamily: 'Poppins Regular' }}>
                <FormattedMessage id="record-crisis.has-rescue-medication" />
              </Typography>
            }
            style={{ fontFamily: 'Poppins Light' }}
          />
        )}
        {historyType === 'sleep' && quality && (
          <Typography
            style={{ textTransform: 'capitalize', color: colors.darkPurple }}
            fontFamily={'Poppins regular'}
          >
            {quality}
          </Typography>
        )}
        {historyType === 'symptom' && symptomDescription && (
          <Typography
            style={{ textTransform: 'capitalize', color: colors.darkPurple }}
            fontFamily={'Poppins regular'}
          >
            {symptomDescription}
          </Typography>
        )}
        {historyType === 'ketone' && ketonesLevel && ketonesLevel !== 'noneSelected' && (
          <Box style={{ display: 'flex' }}>
            <KetonesIcon colorLevel={ketonesLevel as keyof typeof ketoColors} radio={false} />
            <Typography style={{ padding: '0 10px' }}>
              {ketoneTypes.find(ketoneType => ketoneType.dbName === ketonesLevel)?.localizedName ??
                ''}
            </Typography>
          </Box>
        )}
        {historyType === 'ketone' && ketonesNumber && <Typography>{data.ketonesNumber}</Typography>}
        {historyType === 'heightWeight' && height && (
          <Typography>
            <FormattedMessage id="details.height-weight.height" />: {data.height}{' '}
            <FormattedMessage id="record-height-weight.height-unit" />
          </Typography>
        )}
        {historyType === 'heightWeight' && weight && (
          <Typography>
            <FormattedMessage id="details.height-weight.weight" />: {data.weight}{' '}
            <FormattedMessage id="record-height-weight.weight-unit" />
          </Typography>
        )}
        {historyType === 'mood' && mood && (
          <Box>
            <SvgIcon
              component={moodIcons[mood - 1]}
              viewBox="0 0 477.14 885"
              style={{
                height: '100%',
              }}
            />
          </Box>
        )}
        {comments != null && (
          <Typography noWrap fontFamily={'Poppins regular'}>
            {comments}
          </Typography>
        )}
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
        <IconButton ref={anchorRef} sx={{ padding: '0' }} onClick={handleClick}>
          <MoreVertIcon style={{ color: colors.darkPurple }} />
        </IconButton>
        <Popper
          id={'card-options'}
          open={isOpen}
          anchorEl={anchorRef.current}
          placement={'left-start'}
        >
          <ClickAwayListener onClickAway={handleClick}>
            <CardOptions onDelete={onDelete} onEdit={editFromMenu} />
          </ClickAwayListener>
        </Popper>
      </Box>
    </Box>
  );
};
