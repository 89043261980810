import React, { FunctionComponent } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { colors, fontSize } from 'theme';

export const PatientSupportProgram: FunctionComponent = () => {
  const { formatMessage: t } = useIntl();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        mx: 2,
        my: 2,
      }}
    >
      <Box style={{ flex: 1, color: colors.darkPurple }}>
        <BackArrow />
      </Box>
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <Typography sx={{ fontSize: fontSize.small, flexWrap: 'nowrap', color: colors.darkGrey }}>
          <T id="patient-support-program.powered-by" />
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mt: 1,
          margin: '0 auto',
          width: '100%',
        }}
      >
        <Box
          component="img"
          src="/logo/logo-nutricia.svg"
          sx={{
            minWidth: '100px',
            width: '100vw',
            objectFit: 'contain',
            overflow: 'display',
            display: 'flex',
            alignSelf: 'center',
            resize: 'horizontal',
            mb: 2,
          }}
        ></Box>
      </Box>
      <Box>
        <Typography
          display="inline"
          sx={{
            fontSize: fontSize.small,

            color: colors.darkGrey,
            fontFamily: 'Poppins Bold',
          }}
        >
          {` ${t({ id: 'patient-support-program.title' })}:`}
        </Typography>

        <Typography sx={{ fontSize: fontSize.small, mt: 1, color: colors.darkGrey }}>
          {`${t({ id: 'patient-support-program.call-by-phone' })} `}
          <a
            href={`tel:+54${t({ id: 'patient-support-program.nutricia-phone-number' })
              .split('-')
              .join('')}`}
            style={{ color: colors.darkPurple }}
          >
            <T id="patient-support-program.nutricia-phone-number" />
          </a>
        </Typography>
        <Typography sx={{ fontSize: fontSize.small, my: 1, color: colors.darkGrey }}>
          {`${t({ id: 'patient-support-program.message-by-whatsapp' })} `}
          <a
            href={`https://api.whatsapp.com/send?phone=${t({
              id: 'patient-support-program.whatsapp-phone-number',
            }).replace(/[^0-9a-z-A-Z ]/g, '')}`}
            style={{ color: colors.darkPurple }}
          >
            <T id="patient-support-program.whatsapp-phone-number" />
          </a>
        </Typography>
        <Typography display="inline" sx={{ fontSize: fontSize.small, color: colors.darkGrey }}>
          <T id="patient-support-program.ketoapp-support-instructions" />
        </Typography>
        <Typography
          display="inline"
          sx={{
            fontSize: fontSize.small,
            mt: 1,
            color: colors.darkGrey,
            fontFamily: 'Poppins Bold',
          }}
        >
          {` ${t({ id: 'patient-support-program.ketoapp-title' })}:`}
        </Typography>

        <Typography sx={{ fontSize: fontSize.small, mb: 1 }}>
          <a
            href={`mailto: ${t({ id: 'patient-support-program.nutricia-email' })}`}
            style={{ color: colors.darkPurple }}
          >
            <T id="patient-support-program.nutricia-email" />
          </a>
        </Typography>
        <Typography
          sx={{
            fontSize: fontSize.small,
            mb: 1,
            fontFamily: 'Poppins Bold',
            color: colors.darkGrey,
          }}
        >
          <T id="patient-support-program.legend" />
        </Typography>
      </Box>
    </Box>
  );
};
