import { Box, Button, Pagination, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { HistoryCard } from 'components/HistoryCard/HistoryCard';
import { getCrisesForPatientQuery } from 'dataLayer/crises';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import { colors } from 'theme';
import { Crisis } from 'types/types';

const PAGE_LIMIT = 5;

export const CrisisHistory: FunctionComponent = () => {
  const userId = useSelector(state => state.user.id);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { data: crisisResponse } = useQuery(
    getCrisesForPatientQuery(userId, PAGE_LIMIT, (page - 1) * PAGE_LIMIT),
  );

  const crisisPageWrapper = useRef<HTMLDivElement>(null);

  const [appWidth, setAppWidth] = useState(0);

  const getAppWidth = () => {
    if (crisisPageWrapper.current) setAppWidth(crisisPageWrapper.current.offsetWidth);
  };

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);

  useEffect(() => {
    getAppWidth();
    window.addEventListener('resize', getAppWidth);
  }, []);

  const navigateToRecord = useCallback(() => {
    ReactGA.event({
      category: 'ButtonClick',
      action: 'Record Crisis From History Page',
    });
    navigate(paths.RECORD_CRISIS);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        mb: 6,
      }}
      ref={crisisPageWrapper}
    >
      <Box style={{ display: 'flex' }}>
        <Box style={{ flex: 1 }}>
          <BackArrow />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mr: 2 }}>
          <Typography
            fontFamily="Poppins Medium"
            fontSize="18px"
            style={{ color: colors.darkPurple }}
          >
            <FormattedMessage id="crisis-history.title" />
          </Typography>
        </Box>

        <Box style={{ flex: 1 }}></Box>
      </Box>
      {crisisResponse && (
        <>
          {crisisResponse.results.map((crisis: Crisis) => (
            <HistoryCard key={crisis.id} historyType="crisis" data={crisis} />
          ))}
          <Pagination
            count={Math.ceil(crisisResponse.count / 5)}
            page={page}
            onChange={handlePageChange}
            sx={{ alignSelf: 'center' }}
          />
        </>
      )}

      <Box
        position="fixed"
        height={48}
        bottom="0px"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={navigateToRecord}
          style={{
            color: colors.white,
            backgroundColor: colors.darkPurple,
            width: `${appWidth}px`,
          }}
        >
          <FormattedMessage id="crisis-history.add-crisis-record" />
        </Button>
      </Box>
    </Box>
  );
};
