import { useIntl } from 'react-intl';
export function useKetones() {
  const intl = useIntl();
  return [
    {
      dbName: 'negative',
      localizedName: intl.formatMessage({ id: 'ketone-levels.negative' }),
    },
    {
      dbName: 'trace',
      localizedName: intl.formatMessage({ id: 'ketone-levels.trace' }),
    },

    {
      dbName: 'small',
      localizedName: intl.formatMessage({ id: 'ketone-levels.small' }),
    },

    {
      dbName: 'moderate',
      localizedName: intl.formatMessage({ id: 'ketone-levels.moderate' }),
    },

    {
      dbName: 'large',
      localizedName: intl.formatMessage({ id: 'ketone-levels.large' }),
    },

    {
      dbName: 'largePlus',
      localizedName: intl.formatMessage({ id: 'ketone-levels.largePlus' }),
    },
  ];
}
