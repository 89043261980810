import { Box, Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { FormattedMessage as T } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { colors } from 'theme';

export const KetonesLogged: FunctionComponent = () => (
  <Box
    style={{
      display: 'flex',
      height: '90%',
      justifyContent: 'space-between',
      flexDirection: 'column',
    }}
  >
    <Box style={{ flex: 1 }}>
      <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '5vh' }}>
        <Typography
          align="center"
          fontSize="22px"
          fontFamily="Poppins Medium"
          style={{ marginBottom: '2vh' }}
        >
          <T id="ketones-logged.title" />
        </Typography>
      </Box>
    </Box>
    <Box style={{ flex: 1 }}>
      <Button
        variant="contained"
        style={{
          height: '45px',
          width: '100%',
          margin: '1vh 0',
          backgroundColor: colors.darkPurple,
        }}
        component={Link}
        to={paths.RECORD_KETONES}
      >
        <T id="ketones-logged.another" />
      </Button>
      <Button
        variant="contained"
        style={{
          height: '45px',
          width: '100%',
          margin: '1vh 0',
          backgroundColor: colors.darkPurple,
        }}
        component={Link}
        to={paths.RECORD}
      >
        <T id="record-logged.different" />
      </Button>
      <Button
        variant="contained"
        style={{
          height: '45px',
          width: '100%',
          margin: '1vh 0',
          backgroundColor: colors.darkPurple,
        }}
        component={Link}
        to={paths.KETONES_HISTORY}
      >
        <T id="ketones-logged.history" />
      </Button>
      <Button
        variant="contained"
        style={{
          height: '45px',
          width: '100%',
          margin: '1vh 0',
          backgroundColor: colors.darkPurple,
        }}
        component={Link}
        to={paths.HOME_MENU}
      >
        <T id="ketones-logged.menu" />
      </Button>
    </Box>
    <Box style={{ flex: 1 }}></Box>
  </Box>
);
