import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { KetoCalculatorRecipeReference } from 'types/types';

export const useDeleteRecipeForDateByIdMutation = (): UseMutationResult<
  void,
  unknown,
  { id: string }
> => {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, { id: string }>(
    async ({ id }) => {
      const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
      await fetch(`${backendBaseUrl}/recipes`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id,
        }),
      });
    },
    {
      onMutate: ({ id }) => {
        queryClient.cancelQueries('recipesForDate');
        const previousRecipes = queryClient.getQueryData<KetoCalculatorRecipeReference[]>(
          'recipesForDate',
        );
        if (previousRecipes) {
          const newRecipes = previousRecipes.filter(recipe => recipe.id !== id);
          queryClient.setQueryData<KetoCalculatorRecipeReference[]>('recipesForDate', {
            ...newRecipes,
          });
        }
        return { previousRecipes };
      },
      onSettled: () => {
        queryClient.invalidateQueries('recipesForDate');
      },
    },
  );
};
