import { FetchQueryOptions, useMutation, useQueryClient } from 'react-query';
import { Mood } from 'types/types';

export const getMoodsForPatient = async (patientId: string): Promise<Mood[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/mood/user/${patientId}`).then(async response => response.json());
};

export const getMoodsForPatientQuery = (patientId: string): FetchQueryOptions<Mood[]> => ({
  queryKey: ['moods', patientId],
  queryFn: async () => await getMoodsForPatient(patientId),
});

export const getMoodForPatientByDate = async (
  patientId: string,
  startDate: Date,
  endDate: Date,
): Promise<Mood[]> => {
  const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${backendBaseUrl}/mood/by-date`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: patientId, startDate, endDate }),
  }).then(async response => {
    return response.json();
  });
};

export const getMoodForPatientByDateQuery = (
  patientId: string,
  startDate: Date,
  endDate: Date,
): FetchQueryOptions<Mood[]> => ({
  queryKey: ['moodByDate', patientId, startDate, endDate],
  queryFn: async () => {
    const moodResponse = await getMoodForPatientByDate(patientId, startDate, endDate);
    return moodResponse;
  },
});

export const DeleteMoodByIdMutation = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    (moodsId: string) => {
      const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
      return fetch(`${backendBaseUrl}/mood/${moodsId}`, {
        method: 'DELETE',
      });
    },
    {
      onMutate: async (moodsId: string) => {
        await queryClient.cancelQueries('moods');
        const previousMoods = queryClient.getQueryData<Mood[]>('moods');
        if (previousMoods) {
          const newMoods = previousMoods.filter(mood => mood.id !== moodsId);
          queryClient.setQueryData<Mood[]>('moods', { ...newMoods });
        }
        return { previousMoods };
      },
      onSettled: () => {
        queryClient.invalidateQueries('moods');
      },
    },
  );
  return deleteMutation;
};
