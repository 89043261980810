import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, TextField, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { localeMap } from 'const/calendar';
import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useSelector from 'redux/useSelector';
import client from 'services/networking/client';
import { colors, fontSize } from 'theme';
import { HeightWeight } from 'types/types';

interface IProps {
  heightWeightData: HeightWeight;
  closeEdit: () => void;
}

export const EditHeightWeightForm: FunctionComponent<IProps> = ({
  heightWeightData,
  closeEdit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      date: heightWeightData.date,
      height: heightWeightData.height,
      weight: heightWeightData.weight,
    },
  });

  const userId = useSelector(state => state.user.id);

  const intl = useIntl();

  const onSubmit = useCallback(
    async (data: any) => {
      const { date, height, weight } = data;
      try {
        await client
          .patch(`/heightweight/${heightWeightData.id}`, {
            userId,
            date,
            height,
            weight,
          })
          .catch(error => console.log('Error saving height/weight record: ', error));
        heightWeightData.height = height;
        heightWeightData.weight = weight;
        heightWeightData.date = date;
        closeEdit();
      } catch (error) {
        console.log('Error saving height/weight record: ', error);
      }
    },
    [closeEdit, heightWeightData, userId],
  );

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',

          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box style={{ flex: 1 }}>
              <BackArrow />
            </Box>
            <Box>
              <Typography
                align="center"
                fontSize={fontSize.large}
                fontFamily="Poppins Medium"
                style={{ marginBottom: '2vh', color: colors.darkPurple }}
              >
                <FormattedMessage id="record-height-weight.title" />
              </Typography>
            </Box>
            <Box style={{ flex: 1 }} />
          </Box>
          <Box
            style={{
              margin: '1vh 0',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <MobileDatePicker
              label={<FormattedMessage id="data.date" />}
              inputFormat="dd/MMM/yyyy"
              value={getValues().date}
              onChange={newdate => {
                newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
              }}
              renderInput={params => <TextField {...params} />}
            />
          </Box>

          <Typography>
            <FormattedMessage id="record-height-weight.height" />
            {` (${intl.formatMessage({ id: 'record-height-weight.height-unit' })})`}
          </Typography>
          <Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('height', {
                  required: true,
                  pattern: {
                    value: /^(0|[1-9][0-9]*)$/,
                    message: intl.formatMessage({ id: 'record-height-weight.error-number-height' }),
                  },
                })}
                id="standard-number"
                hiddenLabel
                fullWidth
              />
            </Box>
          </Box>
          <Typography>
            <FormattedMessage id="record-height-weight.weight" />
            {` (${intl.formatMessage({ id: 'record-height-weight.weight-unit' })})`}
          </Typography>
          <Box>
            <Box style={{ margin: '1vh 0' }}>
              <TextField
                {...register('weight', {
                  required: true,
                  pattern: {
                    value: /^(0|[1-9][0-9]*)$/,
                    message: intl.formatMessage({ id: 'record-height-weight.error-number-weight' }),
                  },
                })}
                id="standard-number"
                hiddenLabel
                fullWidth
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
          >
            <FormattedMessage id="record-height-weight.save-height-weight" />
          </Button>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
