/* eslint-disable complexity */
/* eslint-disable max-lines */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  SvgIcon,
  Typography,
} from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { EditCrisisForm } from 'components/EditCrisisForm';
import { EditHeightWeightForm } from 'components/EditHeightWeightForm';
import { EditKetoneForm } from 'components/EditKetoneForm';
import { EditMoodForm } from 'components/EditMoodForm';
import { EditSleepForm } from 'components/EditSleepForm/EditSleepForm';
import { ketoColors, KetonesIcon } from 'components/KetonesIcon';
import { DeleteCrisisByIdMutation } from 'dataLayer/crises';
import { DeleteHeightWeightByIdMutation } from 'dataLayer/heightweight';
import { DeleteKetoneByIdMutation } from 'dataLayer/ketones';
import { DeleteMoodByIdMutation } from 'dataLayer/mood';
import { DeleteSleepByIdMutation } from 'dataLayer/sleep';
import { DeleteSymptomByIdMutation } from 'dataLayer/symptoms';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { EditSymptomForm } from 'EditSymptomForm';
import { moodIcons } from 'pages/RecordMood';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors, fontSize } from 'theme';
import { Crisis, HeightWeight, Ketone, Mood, Sleep, Symptom } from 'types/types';

enum RecordType {
  CRISIS = 'crisis',
  SLEEP = 'sleep',
  SYMPTOM = 'symptom',
  KETONE = 'ketone',
  HEIGHTWEIGHT = 'heightWeight',
  MOOD = 'mood',
}

enum DetailsTitle {
  CRISIS = 'details.crisis.title',
  SLEEP = 'details.sleep.title',
  SYMPTOM = 'details.symptoms.title',
  KETONE = 'details.ketone.title',
  HEIGHTWEIGHT = 'details.height-weight.title',
  MOOD = 'details.mood.title',
}

interface LocationState {
  historyType: string;
  data: Partial<Crisis & Sleep & Symptom & Ketone & HeightWeight & Mood>;
  editing: boolean;
}

export const RecordDetails: FunctionComponent = () => {
  const location = useLocation();

  const { data, historyType: type, editing } = location.state as LocationState;

  const intl = useIntl();

  const [isEditing, setIsEditing] = useState(editing);

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  const localeProvider = locale === 'en' ? enLocale : esLocale;

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const deleteSleepMutation = DeleteSleepByIdMutation();

  const deleteCrisisMutation = DeleteCrisisByIdMutation();

  const deleteSymptomMutation = DeleteSymptomByIdMutation();

  const deleteKetoneMutation = DeleteKetoneByIdMutation();

  const deleteHeightWeightMutation = DeleteHeightWeightByIdMutation();

  const deleteMoodMutation = DeleteMoodByIdMutation();

  const navigate = useNavigate();

  const onDelete = useCallback(() => {
    if (type === 'sleep') {
      data.id != null && deleteSleepMutation.mutate(data.id);
    }
    if (type === 'crisis') {
      data.id != null && deleteCrisisMutation.mutate(data.id);
    }
    if (type === 'symptom') {
      data.id != null && deleteSymptomMutation.mutate(data.id);
    }
    if (type === 'ketone') {
      data.id != null && deleteKetoneMutation.mutate(data.id);
    }
    if (type === 'heightWeight') {
      data.id != null && deleteHeightWeightMutation.mutate(data.id);
    }
    if (type === 'mood') {
      data.id != null && deleteMoodMutation.mutate(data.id);
    }
    navigate(-1);
  }, [
    data.id,
    deleteCrisisMutation,
    deleteHeightWeightMutation,
    deleteKetoneMutation,
    deleteMoodMutation,
    deleteSleepMutation,
    deleteSymptomMutation,
    navigate,
    type,
  ]);

  const infoStyle = {
    backgroundColor: '#e8e8e8',
    padding: '10px',
    borderRadius: '5px',
    display: 'table',
    margin: '5px',
  };

  const stopEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const title = () => {
    if (type === RecordType.CRISIS) {
      return DetailsTitle.CRISIS;
    } else if (type === RecordType.SLEEP) {
      return DetailsTitle.SLEEP;
    } else if (type === RecordType.SYMPTOM) {
      return DetailsTitle.SYMPTOM;
    } else if (type === RecordType.KETONE) {
      return DetailsTitle.KETONE;
    } else if (type === RecordType.HEIGHTWEIGHT) {
      return DetailsTitle.HEIGHTWEIGHT;
    } else if (type === RecordType.MOOD) {
      return DetailsTitle.MOOD;
    }
  };

  const editForm = useCallback(
    (recordType: string) => {
      if (recordType === RecordType.SLEEP) {
        return <EditSleepForm sleepData={data as Sleep} closeEdit={stopEdit} />;
      }
      if (recordType === RecordType.CRISIS) {
        return <EditCrisisForm crisisData={data as Crisis} closeEdit={stopEdit} />;
      }
      if (recordType === RecordType.SYMPTOM) {
        return <EditSymptomForm symptomData={data as Symptom} closeEdit={stopEdit} />;
      }
      if (recordType === RecordType.KETONE) {
        return <EditKetoneForm ketoneData={data as Ketone} closeEdit={stopEdit} />;
      }
      if (recordType === RecordType.HEIGHTWEIGHT) {
        return (
          <EditHeightWeightForm heightWeightData={data as HeightWeight} closeEdit={stopEdit} />
        );
      }
      if (recordType === RecordType.MOOD) {
        return <EditMoodForm moodData={data as Mood} closeEdit={stopEdit} />;
      }
    },
    [data, stopEdit],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        height: '90%',
        flexDirection: 'column',
        mx: 2,
      }}
    >
      {isEditing ? (
        editForm(type)
      ) : (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Box style={{ display: 'flex' }}>
            <Box style={{ flex: '1' }}>
              <BackArrow />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mb: 3 }}>
              <Typography
                fontFamily={'Poppins Medium'}
                fontSize={fontSize.large}
                style={{ color: colors.darkPurple }}
              >
                <FormattedMessage id={title()} />
              </Typography>
            </Box>
            <Box style={{ flex: '1' }}></Box>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Box
              style={{
                backgroundColor: '#e8e8e8',
                padding: '10px',
                borderRadius: '5px',
                display: 'table',
                margin: '5px',
                flex: 1,
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ color: colors.darkPurple }}>
                  {data.date && new Date(data.date).getDate()}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                backgroundColor: '#e8e8e8',
                padding: '10px',
                borderRadius: '5px',
                display: 'table',
                margin: '5px',
                flex: 1,
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ color: colors.darkPurple }}>
                  {data.date && localeProvider.localize?.month(new Date(data.date).getMonth())}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                backgroundColor: '#e8e8e8',
                padding: '10px',
                borderRadius: '5px',
                display: 'table',
                margin: '5px',
                flex: 1,
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ color: colors.darkPurple }}>
                  {data.date && new Date(data.date).getFullYear()}
                </Typography>
              </Box>
            </Box>
          </Box>
          {type === RecordType.CRISIS && (
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Box style={infoStyle}>
                <Typography style={{ textTransform: 'capitalize', color: colors.darkPurple }}>
                  {data.type}
                </Typography>
              </Box>
              <Box style={infoStyle}>
                <Typography style={{ textTransform: 'capitalize', color: colors.darkPurple }}>
                  <FormattedMessage id="record-crisis.crisis-number" />
                  {': '}
                  {data.crisisNumber}
                </Typography>
              </Box>
              <Box style={infoStyle}>
                <Typography
                  style={{
                    textTransform: 'capitalize',
                    color: colors.darkPurple,
                    overflowWrap: 'anywhere',
                  }}
                >
                  <FormattedMessage id="record-crisis.drugs-amount" />
                  {`: ${data.drugsQuantity}`}
                </Typography>
              </Box>
              <Box style={infoStyle}>
                <FormControlLabel
                  control={<Checkbox checked={data.hasRescueMedication} />}
                  label={
                    <Typography style={{ fontFamily: 'Poppins Regular' }}>
                      <FormattedMessage id="record-crisis.has-rescue-medication" />
                    </Typography>
                  }
                  style={{ fontFamily: 'Poppins Light' }}
                />
              </Box>
            </Box>
          )}
          {type === RecordType.SLEEP && (
            <Box sx={infoStyle}>
              <Typography style={{ textTransform: 'capitalize', color: colors.darkPurple }}>
                <FormattedMessage id="details.sleep.quality" />
                {data.quality}
              </Typography>
            </Box>
          )}
          {type === RecordType.SYMPTOM && (
            <Box sx={infoStyle}>
              <Typography style={{ textTransform: 'capitalize', color: colors.darkPurple }}>
                {data.symptomDescription}
              </Typography>
            </Box>
          )}

          {type === RecordType.KETONE && data.ketonesLevel && data.ketonesLevel !== 'noneSelected' && (
            <Box sx={infoStyle}>
              <Box style={{ display: 'flex' }}>
                <KetonesIcon
                  colorLevel={data.ketonesLevel as keyof typeof ketoColors}
                  radio={false}
                />
                <Typography
                  style={{
                    padding: '0 2vw',
                    textTransform: 'capitalize',
                    color: colors.darkPurple,
                  }}
                >
                  {intl.formatMessage({
                    id: 'ketone-levels.' + data.ketonesLevel,
                  })}
                </Typography>
              </Box>
            </Box>
          )}
          {type === RecordType.KETONE && data.ketonesNumber && (
            <Box sx={infoStyle}>
              <Typography style={{ color: colors.darkPurple }}>{data.ketonesNumber}</Typography>
            </Box>
          )}

          {type === RecordType.HEIGHTWEIGHT && data.height && (
            <Box sx={infoStyle}>
              <Typography style={{ color: colors.darkPurple }}>
                <FormattedMessage id="details.height-weight.height" />: {data.height}{' '}
                <FormattedMessage id="record-height-weight.height-unit" />
              </Typography>
            </Box>
          )}

          {type === RecordType.HEIGHTWEIGHT && data.weight && (
            <Box sx={infoStyle}>
              <Typography style={{ color: colors.darkPurple }}>
                <FormattedMessage id="details.height-weight.weight" />: {data.weight}{' '}
                <FormattedMessage id="record-height-weight.weight-unit" />
              </Typography>
            </Box>
          )}

          {type === RecordType.MOOD && data.mood && (
            <Box sx={infoStyle}>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <SvgIcon
                  component={moodIcons[data.mood - 1]}
                  viewBox="0 0 477.14 885"
                  style={{
                    height: '100%',
                  }}
                />
              </Box>
            </Box>
          )}

          <Box
            style={{
              backgroundColor: '#e8e8e8',
              padding: '10px',
              borderRadius: '5px',
              display: 'table',
              margin: '5px',
              minHeight: '10vh',
            }}
          >
            <Box sx={[infoStyle, { p: '10px', bgcolor: '#e8e8e8', height: '10vh' }]}>
              <Typography style={{ color: colors.darkPurple }}>{data.comments}</Typography>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                variant="text"
                startIcon={<EditIcon style={{ color: colors.darkPurple }} />}
                sx={{ justifyContent: 'flex-start', px: 1 }}
                onClick={onEdit}
              >
                <Typography
                  fontFamily={'Poppins light'}
                  fontSize={fontSize.small}
                  style={{ color: colors.darkPurple }}
                >
                  <FormattedMessage id={'details.edit'} />
                </Typography>
              </Button>
              <Button
                variant="text"
                startIcon={<DeleteIcon style={{ color: colors.darkPurple }} />}
                sx={{ justifyContent: 'flex-start', px: 1 }}
                onClick={onDelete}
              >
                <Typography
                  fontFamily={'Poppins light'}
                  fontSize={fontSize.small}
                  style={{ color: colors.darkPurple }}
                >
                  <FormattedMessage id={'details.delete'} />
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
