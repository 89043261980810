import React, { FunctionComponent, useCallback } from 'react';
import { HeightWeight } from 'types/types';
import { colors } from 'theme';
import { VictoryChart, VictoryLine, VictoryAxis, Rect } from 'victory';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface IProps {
  heightWeightList: HeightWeight[];
}

export const HeightOverTime: FunctionComponent<IProps> = ({ heightWeightList }) => {
  const getHeightMaxima = useCallback((data: HeightWeight[]) => {
    return Math.max(...data.map(heightweight => heightweight.height));
  }, []);

  const sortedHWList = heightWeightList.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  return (
    <Box className="graph-height-width">
      {sortedHWList.length > 0 && (
        <Box>
          <Typography>
            <FormattedMessage id="data.height.title" />
          </Typography>
          <VictoryChart
            domainPadding={7}
            backgroundComponent={<Rect style={{ fill: 'red' }} />}
            height={341}
            width={574}
          >
            <VictoryLine
              data={sortedHWList}
              key="height"
              x="date"
              y={datum => datum.height / getHeightMaxima(sortedHWList)}
              style={{ labels: { padding: 40 } }}
            />
            <VictoryAxis
              key="height"
              dependentAxis
              domain={[0, 1]}
              style={{
                axisLabel: { padding: 0 },
                grid: { stroke: colors.mediumGrey, strokeDasharray: '2' },
                tickLabels: { fontSize: 12 },
              }}
              tickValues={[0.25, 0.5, 0.75, 1]}
              tickFormat={t => `${Math.round(t * getHeightMaxima(sortedHWList))}cm`}
            />
            <VictoryAxis
              style={{
                tickLabels: { fontSize: 10 },
                ticks: { stroke: 'grey', size: 5 },
                axisLabel: { padding: 60 },
                parent: { overflow: 'visible' },
              }}
              tickFormat={(x, i) => new Date(x).toLocaleDateString('es-AR').slice(0, -5)}
            />
          </VictoryChart>
        </Box>
      )}
    </Box>
  );
};
