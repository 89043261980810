import React, { FunctionComponent, useCallback } from 'react';
import { HeightWeight } from 'types/types';
import { colors } from 'theme';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface IProps {
  heightWeightList: HeightWeight[];
}

export const WeightOverTime: FunctionComponent<IProps> = ({ heightWeightList }) => {
  console.log(JSON.stringify(heightWeightList));
  const getWeightMaxima = useCallback((data: HeightWeight[]) => {
    return Math.max(...data.map(heightweight => heightweight.weight));
  }, []);

  return (
    <Box>
      {heightWeightList.length > 0 && (
        <Box className="graph-height-width">
          <Typography>
            <FormattedMessage id="data.weight.title" />
          </Typography>
          <VictoryChart domainPadding={10} height={341} width={574}>
            <VictoryLine
              data={heightWeightList}
              key="weight"
              x="date"
              y={datum => datum.weight / getWeightMaxima(heightWeightList)}
              style={{ labels: { padding: 40 } }}
            />
            <VictoryAxis
              key="weight"
              dependentAxis
              style={{
                axisLabel: { padding: 30 },
                grid: { stroke: colors.mediumGrey, strokeDasharray: '2' },
                tickLabels: { fontSize: 12 },
              }}
              domain={[0, 1]}
              tickValues={[0.25, 0.5, 0.75, 1]}
              tickFormat={t => `${t * getWeightMaxima(heightWeightList)}kg`}
            />
            <VictoryAxis
              style={{
                tickLabels: { fontSize: 10 },
                ticks: { stroke: 'grey', size: 5 },
                axisLabel: { padding: 60 },
                parent: { overflow: 'visible' },
              }}
              tickFormat={(x, i) => new Date(x).toLocaleDateString('es-AR').slice(0, -5)}
            />
          </VictoryChart>
        </Box>
      )}
    </Box>
  );
};
