import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, Slider, TextField, Typography } from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { useSleep } from 'const/sleepTypes';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import client from 'services/networking/client';
import { fontSize, colors } from 'theme';
import ReactGA from 'react-ga';

export const RecordSleep: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: { sleepQuality: 'normal', date: new Date(Date.now()), comments: '' },
  });

  const navigate = useNavigate();
  const intl = useIntl();

  const userId = useSelector(state => state.user.id);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: any) => {
      setButtonDisabled(true);
      ReactGA.event({
        category: 'ButtonClick',
        action: 'Record Sleep',
      });

      try {
        await client.post('/sleep', {
          quality: data.sleepQuality,
          comments: data.comments,
          userId,
          date: data.date.toJSON(),
        });
        navigate(paths.LOGGED_SLEEP);
      } catch (error) {
        console.log('Error saving crisis: ', error);
        setButtonDisabled(false);
      }
    },
    [navigate, userId],
  );

  const sleepMap = useSleep();

  const sliderMarks = [
    { value: 0, label: sleepMap[0].localizedName },
    { value: 1, label: sleepMap[1].localizedName },
    { value: 2, label: sleepMap[2].localizedName },
  ];

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = intl.formatMessage({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ margin: 1 }}>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box style={{ flex: 1 }}>
                <BackArrow />
              </Box>
              <Box>
                <FormattedMessage id="record-sleep.title">
                  {(text: string) => (
                    <Typography
                      align="center"
                      fontSize={fontSize.large}
                      fontFamily="Poppins Medium"
                      style={{ marginBottom: '2vh', color: colors.darkPurple }}
                    >
                      {text}
                    </Typography>
                  )}
                </FormattedMessage>
              </Box>
              <Box style={{ flex: 1 }}></Box>
            </Box>
            <Box
              style={{
                margin: '1vh 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <MobileDatePicker
                label={<FormattedMessage id="data.date" />}
                inputFormat="dd/MMM/yyyy"
                value={getValues().date}
                onChange={newdate => {
                  newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
                }}
                renderInput={params => <TextField {...params} />}
              />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '100%', mx: 2 }}>
                <Slider
                  min={0}
                  max={2}
                  sx={{ color: colors.darkPurple }}
                  marks={sliderMarks}
                  defaultValue={1}
                  onChange={(e, selectedValue) => {
                    typeof selectedValue === 'number' &&
                      setValue('sleepQuality', sleepMap[selectedValue].dbName, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                  }}
                ></Slider>
              </Box>
            </Box>
            <FormattedMessage id="record-sleep.add-comments">
              {(placeholder: string) => (
                <Box style={{ margin: '1vh 0' }}>
                  <TextField
                    {...register('comments', { required: false })}
                    hiddenLabel
                    id="outlined-multiline-static"
                    multiline
                    rows={6}
                    fullWidth
                    placeholder={placeholder}
                  />
                </Box>
              )}
            </FormattedMessage>

            <Button
              variant="contained"
              color="primary"
              style={{
                height: '45px',
                width: '100%',
                color: colors.white,
                backgroundColor: colors.darkPurple,
              }}
              type="submit"
              disabled={buttonDisabled}
            >
              <FormattedMessage id="record-sleep.save-sleep" />
            </Button>
          </Box>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
