/* eslint-disable max-lines */
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { BackArrow } from 'components/BackArrow';
import { ketoColors, KetonesIcon } from 'components/KetonesIcon';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useSelector from 'redux/useSelector';
import { paths } from 'routes';
import client from 'services/networking/client';
import { fontSize, colors } from 'theme';
import ReactGA from 'react-ga';
import { useKetones } from 'const/ketoneTypes';

export const RecordKetones: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      seizureType: 'atonic',
      date: new Date(Date.now()),
      comments: '',
      ketonesLevel: 'noneSelected',
      ketonesNumber: '',
    },
  });

  const userId = useSelector(state => state.user.id);

  const navigate = useNavigate();
  const { formatMessage: t } = useIntl();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: any) => {
      setButtonDisabled(true);
      ReactGA.event({
        category: 'ButtonClick',
        action: 'Record Ketones',
      });
      try {
        await client.post('/ketones', {
          ketonesLevel: data.ketonesLevel,
          comments: data.comments,
          userId,
          ketonesNumber: data.ketonesNumber === '' ? null : data.ketonesNumber,
          date: data.date.toJSON(),
        });
        navigate(paths.LOGGED_KETONES);
      } catch (error) {
        console.log('Error saving ketones: ', error);
        setButtonDisabled(false);
      }
    },
    [navigate, userId],
  );

  const ketoneTypes = useKetones();

  const localeMap = [
    { localeName: 'en', localeProvider: enLocale },
    { localeName: 'es', localeProvider: esLocale },
  ];

  const locale = t({ id: 'locale.abbreviation' });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap.find(element => element.localeName === locale)?.localeProvider}
    >
      <Box
        style={{
          display: 'flex',
          height: '90%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box style={{ flex: 1 }}>
              <BackArrow />
            </Box>
            <Box>
              <Typography
                align="center"
                fontSize={fontSize.large}
                fontFamily="Poppins Medium"
                style={{ marginBottom: '2vh', color: colors.darkPurple }}
              >
                <FormattedMessage id="record-ketones.title" />
              </Typography>
            </Box>
            <Box style={{ flex: 1 }}></Box>
          </Box>
          <Box
            style={{
              margin: '1vh 0',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <MobileDatePicker
              label={<FormattedMessage id="data.date" />}
              inputFormat="dd/MMM/yyyy"
              value={getValues().date}
              onChange={newdate => {
                newdate && setValue('date', newdate, { shouldValidate: true, shouldDirty: true });
              }}
              renderInput={params => <TextField {...params} />}
            />
          </Box>
          <Box style={{ margin: '1vh 0' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t({ id: 'record-ketones.ketones-level' })}</FormLabel>
              <RadioGroup
                aria-label="ketone-level"
                name="row-radio-buttons-group"
                onChange={newValue => {
                  setValue('ketonesLevel', newValue.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
              >
                {ketoneTypes.map(ketoneType => (
                  <FormControlLabel
                    key={ketoneType.dbName}
                    value={ketoneType.dbName}
                    control={
                      <KetonesIcon
                        colorLevel={ketoneType.dbName as keyof typeof ketoColors}
                        radio={true}
                      />
                    }
                    label={ketoneType.localizedName}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Typography
            align="left"
            fontSize={fontSize.medium}
            fontFamily="Poppins Medium"
            style={{ marginBottom: '2vh', color: colors.darkPurple }}
          >
            <FormattedMessage id="record-ketones.or" />
          </Typography>
          <Typography
            align="left"
            fontSize={fontSize.medium}
            fontFamily="Poppins Regular"
            style={{ marginBottom: '2vh', color: colors.mediumGrey }}
          >
            <FormattedMessage id="record-ketones.cetonemia" />
          </Typography>
          <Box style={{ margin: '1vh 0' }}>
            <TextField
              {...register('ketonesNumber', {
                required: true,
                pattern: {
                  value: /^(0|[1-9][0-9]*)$/,
                  message: t({ id: 'record-ketones.error-number' }),
                },
              })}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              id="standard-number"
              hiddenLabel
              fullWidth
              placeholder={t({ id: 'record-ketones.alternate-input' })}
            />
          </Box>
          {Boolean(errors.ketonesNumber) && (
            <Typography
              marginLeft="1em"
              fontSize={fontSize.small}
              fontFamily="Poppins Regular"
              color="red"
            >
              {errors.ketonesNumber?.message}
            </Typography>
          )}
          <Box style={{ margin: '1vh 0' }}>
            <TextField
              {...register('comments', { required: true })}
              hiddenLabel
              id="outlined-multiline-static"
              multiline
              rows={6}
              fullWidth
              placeholder={t({ id: 'record-ketones.comments' })}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              height: '45px',
              width: '100%',
              color: colors.white,
              backgroundColor: colors.darkPurple,
            }}
            type="submit"
            disabled={buttonDisabled}
          >
            <FormattedMessage id="record-ketones.save-ketones" />
          </Button>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
